const menu  = {
  index: 'Home',
  report: 'Statistical Analysis',
  statisticsDailyList: 'Daily status summary',
  statisticsGameSettle: 'Game surplus settle',
  statisticsMerchantSettle: 'Channel reconciliation',
  agentRewardStatistics: 'Reward Statistics',
  statisticsRemainList: 'Retention report',
  gameEcosystemMonitoring: 'Game Eco Monitor',
  vip: 'player management',
  vipList: 'All players',
  vipBet: 'Game Log',
  accountChange: 'Gold Coin Log',
  flowList: 'Coding details',
  verc: 'SMS record',
  paymentManage: 'Payment Management',
  paymentChannelManage: 'payment channel',
  paymenthistory: 'Order Management',
  spendManage: 'Spend Management',
  spendChannelManage: 'Expenditure Channel',
  paymentReview: 'Withdrawal review',
  pendingList: 'pending list',
  withdrawalManage: 'Withdrawal record',
  bankManage: 'Card number manage',
  activity: 'activity management',
  popupImg: 'Popup image manage',
  advertising: 'Activity diagram manage',
  suspension: 'suspended chart manage',
  weeklyActivityData: 'activity data',
  agent: 'agent management',
  agentRelation: 'agency relationship',
  invitationReport: 'Agent Daily',
  agentRewardCount: 'Agent reward statistics',
  unbindingDetails: 'Unbinding details',
  bloggerDataMonitoring: 'Blogger Data Monitoring',
  agentSummary: 'Agent Summary',
  site: 'game configuration',
  activityConfig: 'activity configuration',
  systemConfig: 'system configuration',
  vipConfig: 'Vip configuration',
  gameConfig: 'Control config',
  pgConfigList: 'PGGAME configuration',
  buyInAmountConfig: 'Coding amount config',
  venueManage: 'Venue Configuration',
  channel: 'channel management',
  channelList: 'channel list',
  quoteManage: 'Quota management',
  salesmanReportBusiness: 'Salesman Reconciliation',
  permission: 'Account Management',
  systemAccount: 'User list',
  roleManage: 'role list',
  whitelist: 'IP whitelist',
  backendLogs: 'Backend log',
  service: 'customer service system',
  upDownRecord: 'GM up down points',
  upDownAudit: 'Up and down audit',
  upDownRecordChannel: 'Channel up points',
  content: 'System Announcement',
  linkConfig: 'Official channel config',
  serviceConfig: 'Customer service config',
  pushMessage: 'Scrolling message',
  unbindControl: 'Unbind control list',
  org: 'Organization',
  orgList: 'Organization management',
  changeBindRecord: 'Change binding record'
}
export default menu