const payment  = {
  payment1: '支付通道',
  payment2: '补单',
  payment3: '请输入密码验证',
  payment4: '密码验证',
  payment5: '确定',
  payment6: '取消',
  payment7: '密码不能为空',
  paymentCol1: '通道名称',
  paymentCol2: '通道编码',
  paymentCol3: '最小金额',
  paymentCol4: '最大金额',
  paymentCol5: '快捷金额',
  paymentCol6: '前台展示名称',
  paymentCol7: '状态',
  paymentCol8: '汇率',
  order1: '总订单',
  order2: '已支付订单',
  order3: '订单总额',
  order4: '已支付总额',
  order5: '成功率',
  order6: '玩家总数',
  order7: '支付人数',
  order8: '玩家ID',
  order9: '邀请人ID',
  order10: '订单号',
  order11: '订单状态',
  order12: '订单金额',
  order13: '申请时间',
  order14: '游戏日志',
  orderD1: '通道名称',
  orderD2: '排序',
  orderD3: '最大金额',
  orderD4: '最小金额',
  orderD5: '快捷金额',
  orderD6: '前台展示名称',
  orderD7: '汇率',
  orderD8: '状态',
  orderD9: 'key',
  orderD10: 'app_key',
  orderD11: 'api地址',
  orderD12: '回调地址(notify_url)',
  orderD13: '商户号(mchid)',
  orderD14: '机构号(app_id)',
  orderD15: '通道编码(pay_code)',
  orderD16: '国家编码(country_code)',
  orderD17: '币种编码(currency_code)',
  orderD18: '交易类型(type)',
  orderCol1: '订单状态',
  orderCol2: '订单号',
  orderCol3: '订单时间',
  orderCol4: '支付时间',
  orderCol5: '玩家ID',
  orderCol6: '充值金额',
  orderCol7: '到账金额',
  orderCol8: '通道名称',
  paymenthistoryEdit1: '标题',
  paymenthistoryEdit2: '内容',
  paymenthistoryEdit3: '是否启用',
  paymenthistoryEdit4: '请输入标题',
  paymenthistoryEdit5: '请输入内容',
}
export default payment