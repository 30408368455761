const activity  = {
  popupImg1: '活动状态',
  popupImg2: '跳转类型',
  popupImg3: '弹窗图管理',
  popupImgCon1: '标题',
  popupImgCon2: '手机图片',
  popupImgCon3: 'PC图片',
  popupImgCon4: '公告类型',
  popupImgCon5: '长度',
  popupImgCon6: '宽度',
  popupImgCon7: '跳转类型',
  popupImgCon8: '跳转地址',
  popupImgCon9: '排序',
  popupImgCon10: '状态',
  ad1: '状态',
  ad2: '活动图管理',
  ad3: '支持.webp，大小不超过1.5M',
  adCol1: '标题',
  adCol2: '手机图',
  adCol3: '链接类型',
  adCol4: '跳转链接',
  adCol5: '排序',
  adCol6: '状态',
  suspension1: '悬浮图管理',
  suspensionCol1: '标题',
  suspensionCol2: '手机图片',
  suspensionCol3: 'pc图片',
  suspensionCol4: '跳转链接',
  suspensionCol5: '状态',
  suspensionCol6: '排序',
  suspensionCol7: '显示方式',
  advertisingEdit1: '标题',
  advertisingEdit2: '链接类型',
  advertisingEdit3: '跳转链接',
  advertisingEdit4: '状态',
  advertisingEdit5: '排序',
  advertisingEdit6: 'h5图片',
  advertisingEdit7: '请输入标题',
  advertisingEdit8: '长度在 1 到 100 个字符',
  advertisingEdit9: '请选择链接类型',
  advertisingEdit10: '跳转链接不能为空',
  advertisingEdit11: '周亏损',
  advertisingEdit12: '邀请奖励',
  advertisingEdit13: '代理',
  advertisingEdit14: '累计存款',
  popupImgEdit1: '公告类型',
  popupImgEdit2: '标题',
  popupImgEdit3: '跳转类型',
  popupImgEdit4: '跳转地址',
  popupImgEdit5: '状态',
  popupImgEdit6: '排序',
  popupImgEdit7: '手机图片',
  popupImgEdit8: 'PC图片',
  popupImgEdit9: '长度',
  popupImgEdit10: '宽度',
  popupImgEdit11: '文字',
  popupImgEdit12: '值介于0-10000之间',
  popupImgEdit13: '请选择公告类型',
  popupImgEdit14: '请输入标题',
  popupImgEdit15: '长度在 1 到 100 个字符',
  popupImgEdit16: '请选择跳转类型',
  popupImgEdit17: '请输入文字',
  suspensionEdit1: '标题',
  suspensionEdit2: '跳转类型',
  suspensionEdit3: '跳转链接',
  suspensionEdit4: '排序',
  suspensionEdit5: '状态',
  suspensionEdit6: '显示方式',
  suspensionEdit7: '手机图片',
  suspensionEdit8: 'PC图片',
  suspensionEdit9: '长度',
  suspensionEdit10: '宽度',
  suspensionEdit11: '值介于0-10000之间',
  suspensionEdit12: '请选择公告类型',
  suspensionEdit13: '请输入标题',
  suspensionEdit14: '长度在 1 到 100 个字符',
  suspensionEdit15: '请选择跳转类型',
  suspensionEdit16: '请输入跳转链接',
  suspensionEdit17: '请选择显示方式',
  wActivityData1: '首充邀请梯度',
  wActivityData2: '总充值',
  wActivityData3: '总奖金',
  wActivityData4: '上级id',
  wActivityData5: '下级id',
  wActivityData6: '充值分成',
  wActivityData7: '分成总额',
  wActivityData8: '层级',
  wActivityData9: '周返佣',
  wActivityData10: '玩家ID',
  wActivityData11: '周存款',
  wActivityData12: '周投注',
  wActivityData13: '周亏损',
  wActivityData14: '日投注',
  wActivityData15: '流水返利',
  wActivityData16: '贡献玩家ID',
  
  wActivityDataCol1: '上级id',
  wActivityDataCol2: '下级id',
  wActivityDataCol3: '首冲金额',
  wActivityDataCol4: '奖金',
  wActivityDataCol5: '时间',

  wActivityData1Col1: '上级id',
  wActivityData1Col2: '下级id',
  wActivityData1Col3: '充值',
  wActivityData1Col4: '层级/比例',
  wActivityData1Col5: '分成金额',
  wActivityData1Col6: '时间',

  wActivityData2Col1: '玩家ID',
  wActivityData2Col2: '本周下级充值',
  wActivityData2Col3: '上周下级充值',
  wActivityData2Col4: '上周返佣',
  wActivityData2Col5: '累计返佣',
  wActivityData2Col6: '累计领取',

  wActivityData3Col1: '玩家ID',
  wActivityData3Col2: '本周充值',
  wActivityData3Col3: '上周充值',
  wActivityData3Col4: '上周返佣',
  wActivityData3Col5: '累计返佣',
  wActivityData3Col6: '累计领取',

  wActivityData4Col1: '玩家ID',
  wActivityData4Col2: '本周投注',
  wActivityData4Col3: '上周投注',
  wActivityData4Col4: '上周返佣',
  wActivityData4Col5: '累计返佣',
  wActivityData4Col6: '累计领取',

  wActivityData5Col1: '玩家ID',
  wActivityData5Col2: '本周充值/提现',
  wActivityData5Col3: '上周充值/提现',
  wActivityData5Col4: '上周返佣',
  wActivityData5Col5: '累计返佣',
  wActivityData5Col6: '累计领取',

  wActivityData6Col1: '玩家ID',
  wActivityData6Col2: '今日投注',
  wActivityData6Col3: '昨日投注',
  wActivityData6Col4: '昨日返佣',
  wActivityData6Col5: '累计返佣',
  wActivityData6Col6: '累计领取',

  wActivityData7Col1: '日期',
  wActivityData7Col2: '贡献玩家',
  wActivityData7Col3: '上级id',
  wActivityData7Col4: '等级',
  wActivityData7Col5: '贡献返利',
  wActivityData7Col6: '返利比例(千分)',
  wActivityData7Col7: '昨日投注',
}
export default activity