const common  = {
  date: 'data',
  time: 'tempo',
  startDate: 'Data de início',
  endDate: 'data final',
  search: 'consulta',
  serverTime: 'horário do servidor',
  syncData: 'Dados de sincronização',
  language: 'Selecionar idioma',
  more: 'mais',
  closeOther: 'Fechar outro',
  closeAll: 'Fechar tudo',
  english: 'Inglês',
  chinese: 'chinês',
  portuguese: 'português',
  personalInfo: 'Informações pessoais',
  resetPass: 'Alterar senha',
  logout: 'Sair',
  confirmLogout: 'Tem certeza que deseja sair? ',
  today: 'hoje',
  yestoday: 'ontem',
  beforeYestoday: 'anteontem',
  lastSeven: 'últimos 7 dias',
  lastFourteen: 'últimos 14 dias',
  lastThirty: 'últimos 30 dias',
  lastMonth: 'último mês',
  currentMonth: 'este mês',
  userType: 'tipo de usuário',
  select: 'Por favor selecione',
  channelId: 'ID do canal',
  month: 'mês',
  exportAll: 'Exportar tudo',
  room: 'quarto',
  add: 'Novo',
  edit: 'modificar',
  delete: 'excluir',
  action: 'ação',
  number: 'número de série',
  to: 'para',
  reset: 'redefinir',
  export: 'exportar',
  userId: 'ID do usuário',
  start: 'começar',
  end: 'parar',
  changeBind: 'alterar vinculação',
  unbing: 'desvinculação',
  done: 'feito',
  unDone: 'Inacabado',
  pInput: 'Por favor, insira corretamente',
  confirmDelete: 'Tem certeza que deseja excluir? ',
  confirm: 'OK',
  cancel: 'cancelar',
  tip: 'dica',
  all: 'todos',
  upAndDown: 'pontos para cima e para baixo',
  oneKeyOpen: 'Uma chave para abrir',
  oneKeyClose: 'Uma chave para fechar',
  stop: 'desativar',
  regTime: 'Hora de registro',
  save: 'salvar',
  detail: 'Detalhes',
  selectTime: 'Selecionar hora'
}
export default common