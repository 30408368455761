const site  = {
  acConfig1: '活动配置',
  acConfigCol1: '活动名称',
  acConfigCol2: '开关',
  sysConfig1: '系统配置',
  sysConfigCol1: '功能描述',
  vipConfig1: 'Vip配置',
  vipConfigCol1: 'Vip等级',
  vipConfigCol2: '升级流水',
  vipConfigCol3: '升级奖励',
  vipConfigCol4: '周奖励',
  vipConfigCol5: '月奖励',
  pgConfig1: '真PG游戏ID',
  pgConfig2: 'PGGAME配置',
  pgConfig3: '假pg游戏ID',
  pgConfig4: '假pp游戏ID',
  pgConfigCol1: '游戏名称',
  pgConfigCol2: '开关状态',
  biaConfig1: '打码量配置',
  biaConfigCol1: '配置名称',
  biaConfigCol2: '流水倍数',
  biaConfigCol3: '类型',
  acConfigDetail1: '有效邀请条件',
  acConfigDetail2: '打码',
  acConfigDetail3: '封顶奖励金额',
  acConfigDetail4: '充值类型',
  acConfigDetail5: '首充',
  acConfigDetail6: '累充',
  acConfigDetail7: '首充',
  acConfigDetail8: '复充',
  acConfigDetail9: '人数邀请梯度(宝箱奖励)',
  acConfigDetail10: '首充邀请梯度',
  acConfigDetail11: '累计充值',
  acConfigDetail12: '日投注',
  acConfigDetail13: '周投注',
  acConfigDetail14: '周亏损',
  acConfigDetail15: '周返佣',
  acConfigDetail16: '周存款',
  acConfigDetail17: '代理下级流水返利',
  acConfigDetail18: '下级充值分成',
  acConfigDetailCol1: '排序',
  acConfigDetailCol2: '充值类型',
  acConfigDetailCol3: '起始金额',
  acConfigDetailCol4: '赠送比例(%)',
  acConfigDetailCol5: '封顶赠送',
  acConfigDetailCol6: '邀请人数',
  acConfigDetailCol7: '奖励',
  acConfigDetailCol8: '首充金额',
  acConfigDetailCol9: '奖励',
  acConfigDetailCol10: '累计金额',
  acConfigDetailCol11: '赠送金额',
  acConfigDetailCol12: '投注',
  acConfigDetailCol13: '返还比例(千分比)',
  acConfigDetailCol14: '充值',
  acConfigDetailCol15: '返还比例(千分比)',
  acConfigDetailCol16: '级别',
  acConfigDetailCol17: '返还比例(千分比)',
  acConfigDetailCol18: '层级',
  acConfigDetailCol19: '比例',
  activityConfigEdit1: '请输入序号',
  activityConfigEdit2: '请输入金额',
  activityConfigEdit3: '请输入比例(%)',
  activityConfigEdit4: '请输入封顶赠送',
  activityConfigEdit5: '请输入邀请人数',
  activityConfigEdit6: '请输入奖励',
  activityConfigEdit7: '请输入首充金额',
  activityConfigEdit8: '请输入投注',
  activityConfigEdit9: '请输入充值',
  buyInAmountConfigEdit1: '配置名称',
  buyInAmountConfigEdit2: '流水倍数',
  vipConfigEdit1: 'VIP等级',
  vipConfigEdit2: '升级流水',
  vipConfigEdit3: '升级奖励',
  vipConfigEdit4: '周奖励',
  vipConfigEdit5: '月奖励',
  vipConfigEdit6: '请输入VIP等级',
  vipConfigEdit7: '请输入升级流水',
  vipConfigEdit8: '请输入升级奖励',
  vipConfigEdit9: '请输入周奖励',
  vipConfigEdit10: '请输入月奖励',
  venueManageTagEdit1: '游戏类型',
  venueManageTagEdit2: '游戏品牌',
  venueManageTagEdit3: '标签名称',
  venueManageTagEdit4: '状态',
  venueManageTagEdit5: '请选择角色',
  venueManageTagEdit6: '请选择游戏品牌',
  venueManageTagEdit7: '请输入标签名称',
  venueManageTagEdit8: '长度在 1 到 100 个字符',
  gameConfigEdit1: '游戏名称',
  gameConfigEdit2: '开关状态',
  gameConfigEdit3: '游戏配置',
  gameConfigEdit4: '金额',
  gameConfigEdit5: '投注金额',
  gameConfigEdit6: '有效比例',
  gameConfigEdit7: '盈利金额',
  gameConfigEdit8: '加码(%)',
  gameConfigEdit9: '游戏名称',
  gameConfigEdit10: '开关状态',
  gameConfigEdit11: '三方游戏列表',
  gameConfigEdit12: '邀请人数',
  gameConfigEdit13: '掉绑比例',
  gameConfigEdit14: '累计封顶人数',
  gameConfigEdit15: '盈利金额',
  gameConfigEdit16: '抽税比例(%)',
  gameConfigEdit17: '功能描述',
  gameConfigEdit18: '请输入CfgType',
  gameConfigEdit19: '请输入CfgValue',
  venueManageEdit1: '游戏名称',
  venueManageEdit2: '游戏类型',
  venueManageEdit3: '游戏代码',
  venueManageEdit4: '游戏英文名称',
  venueManageEdit5: '游戏中文名称',
  venueManageEdit6: '游戏巴西名称',
  venueManageEdit7: '游戏图片',
  venueManageEdit8: '热门',
  venueManageEdit9: '排序',
  venueManageEdit10: '最新',
  venueManageEdit11: '推荐',
  venueManageEdit12: '维护状态',
  venueManageEdit13: '正常',
  venueManageEdit14: '维护',
  venueManageEdit15: '是',
  venueManageEdit16: '否',
  venueManageEdit17: '支持.webp，大小不超过1.5M',
  venueManage1: '游戏列表',
  venueManage2: '游戏代码',
  venueManage3: '游戏名称',
  venueManage4: '游戏品牌',
  venueManage5: '线路',
  venueManage6: '游戏类型',
  venueManage7: '属性类型',
  venueManage8: '状态',
  venueManage9: '维护状态',
  venueManage10: '设置热门',
  venueManage11: '取消热门',
  venueManage12: '设置最新',
  venueManage13: '取消最新',
  venueManage14: '设置推荐',
  venueManage15: '取消推荐',
  venueManage16: '排序',
  venueManage17: '场馆列表',
  venueManage18: '全部',
  venueManage19: '游戏标签',
  venueManage20: '标签名称',
  venueManage21: '游戏类型',
  venueManage22: '状态',
  venueManage23: '热门',
  venueManage24: '最新',
  venueManage25: '推荐',
  venueManage26: '至少选中一条',
  venueManage27: '禁用',
  venueManage28: '下线',
  venueManage29: '正常',
  venueManage1Col1: '游戏代码',
  venueManage1Col2: '游戏英文名称',
  venueManage1Col3: '游戏中文名称',
  venueManage1Col4: '游戏巴西名称',
  venueManage1Col5: '游戏品牌',
  venueManage1Col6: '游戏类型',
  venueManage1Col7: '热门',
  venueManage1Col8: '最新',
  venueManage1Col9: '推荐',
  venueManage1Col10: '状态',
  venueManage1Col11: '维护状态',
  venueManage2Col1: '序号',
  venueManage2Col2: '场馆ID',
  venueManage2Col3: '场馆名称',
  venueManage2Col4: '游戏类型',
  venueManage2Col5: '最后更新时间',
  venueManage2Col6: '维护状态',
  venueManage2Col7: '状态',
  venueManage3Col1: '标签名称',
  venueManage3Col2: '状态',
  venueManage3Col3: '游戏类型',
  venueManage3Col4: '游戏品牌',
  venueManage3Col5: '创建时间',
  gameConfig1: '假pg调控',
  gameConfig2: '渠道',
  gameConfig3: '初始RTP',
  gameConfig4: '调控金额倍数',
  gameConfig5: '调控RTP',
  gameConfig6: '进入假pg概率',
  gameConfig7: '一键同步官方配置',
  gameConfig8: '游戏配置',
  gameConfig9: '假pp调控',
  gameConfig10: '进入假pp概率',
  gameConfig11: '掉绑设置',
  gameConfig12: '白名单',
  gameConfig13: '黑名单',
  gameConfig14: '渠道',
  gameConfig15: '抽税设置',
  gameConfig16: '玩家余额',
  gameConfig17: '盈利余额',
  gameConfig18: '盈利余额是否小数',
  gameConfig19: '三方游戏列表',
  gameConfig20: '抽税开关',
  gameConfig21: '中奖加码',
  gameConfig22: '中奖加码开关',
  gameConfig23: '打码有效比例',
  gameConfig24: '累计打码',
  gameConfig25: '打码有效比例开关',
  gameConfigCol1: '金额',
  gameConfig2Col1: '邀请人数',
  gameConfig2Col2: '掉绑比例(%)',
  gameConfig2Col3: '累计封顶人数',
  gameConfig3Col1: '盈利金额',
  gameConfig3Col2: '抽税比例(%)',
  gameConfig4Col1: '盈利金额',
  gameConfig4Col2: '加码(%)',
  gameConfig5Col1: '投注金额',
  gameConfig5Col2: '有效比例(%)',
}
export default site