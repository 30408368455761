const home  = {
  home1: 'Número pessoas cadastradas',
  home2: 'Número pessoas recarregando',
  home3: 'Número pessoas sacando dinheiro',
  home4: 'proporção pessoas que retiram depósitos',
  home5: 'Recarga total',
  home6: 'Recarga receita',
  home7: 'Valor do bônus',
  home8: 'Moedas ouro restantes',
  home9: 'Total retiradas',
  home10: 'Retirada Jogador',
  home11: 'Retirada sem depósito',
  home12: 'Proporção do valor retirada e depósito',
  home13: 'Fluxo total',
  home14: 'Lucros e perdas',
  home15: 'Imposto total',
  home16: 'Número jogos',
  home17: 'rácio retirada/depósito',
  home18: 'Casa',
  col1: 'Data',
  col2: 'Recarga receita',
  col3: 'Lucros e perdas do sistema',
  col4: 'Fluxo negócios hoje',
  col5: 'Taxa retorno RTP',
  col6: 'Número pessoas cadastradas',
  col7: 'Número pessoas ativas',
  col8: 'Número primeiros depósitos',
  col9: 'Número pessoas recarregando',
  col10: 'Número pessoas sacando dinheiro',
  col11: 'Quantidade recarga',
  col12: 'Valor do bônus',
  col13: 'Valor da retirada',
  col14: 'Valor não recarregado',
  col15: 'Taxa retirada jogadores',
  col16: 'Número jogos'
}
export default home