import {
  orgPost,
  serviceTimestampGet,
  orgPageGet,
  orgDelete,
  dictGet,
  enumGet,
  serviceTimeGet,
  getSiteName
} from '@/api/manageApi'
const manage = {
  namespaced: true,
  state: {
  },
  getters: {},
  mutations: {
  },
  actions: {
    OrgPost ({ state, commit, dispatch }, params) {
      return new Promise((resolve, reject) => {
        orgPost(params).then(res => {
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    },
    ServiceTimestampGet ({ state, commit, dispatch }, params) {
      return new Promise((resolve, reject) => {
        serviceTimestampGet(params).then(res => {
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    },
    OrgPageGet ({ state, commit, dispatch }, params) {
      return new Promise((resolve, reject) => {
        orgPageGet(params).then(res => {
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    },
    OrgDelete ({ state, commit, dispatch }, params) {
      return new Promise((resolve, reject) => {
        orgDelete(params).then(res => {
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    },
    DictGet ({ state, commit, dispatch }, params) {
      return new Promise((resolve, reject) => {
        dictGet(params).then(res => {
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    },
    EnumGet ({ state, commit, dispatch }, params) {
      return new Promise((resolve, reject) => {
        enumGet(params).then(res => {
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    },
    ServiceTimeGet ({ state, commit, dispatch }, params) {
      return new Promise((resolve, reject) => {
        serviceTimeGet(params).then(res => {
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    },
    GetSiteName ({ state, commit, dispatch }, params) {
      return new Promise((resolve, reject) => {
        getSiteName(params).then(res => {
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    }
  }
}
export default manage
