const menu  = {
  index: 'Casa',
  report: 'análise estatística',
  statsDailyList: 'Resumo das estatísticas diárias',
  statsGameSettle: 'Liquidação lucros e perdas jogos',
  statsMerchantSettle: 'Tabela reconciliação canal',
  agentRewardStatistics: 'Resumo despesas recompensa',
  statsRemainList: 'Relatório Retido',
  gameEcosystemMonitoring: 'Monitoramento do ecossistema jogos',
  vip: 'gerenciamento jogadores',
  vipList: 'Todos os jogadores',
  vipBet: 'Registro do jogo',
  accountChange: 'Registro moedas ouro',
  flowList: 'Detalhes da codificação',
  vert: 'registro SMS',
  paymentManage: 'Gerenciamento pagamentos',
  paymentChannelManage: 'canal pagamento',
  paymenthistory: 'Gerenciamento pedidos',
  spendManage: 'Gerenciamento gastos',
  spendChannelManage: 'Canal gastos',
  paymentReview: 'Revisão retirada',
  pendingList: 'lista pendente',
  withdrawalManage: 'Registro retirada',
  bankManage: 'Gerenciamento número cartão',
  activity: 'gestão atividades',
  popupImg: 'Gerenciamento imagens janelas pop-up',
  advertising: 'Gerenciamento diagramas atividades',
  suspension: 'gerenciamento gráfico suspenso',
  weeklyActivityData: 'dados atividade',
  agent: 'gerenciamento agente',
  agentRelation: 'relacionamento agência',
  invitationReport: 'Agente Diário',
  agentRewardCount: 'Estatísticas recompensa do agente',
  unbindingDetails: 'Detalhes desvinculação',
  bloggerDataMonitoring: 'Monitoramento dados do Blogger',
  agentSummary: 'Resumo do Agente',
  site: 'configuração do jogo',
  activityConfig: 'configuração atividade',
  systemConfig: 'configuração do sistema',
  vipConfig: 'Configuração VIP',
  gameConfig: 'Configuração controle',
  pgConfigList: 'Configuração PGGAME',
  buyInAmountConfig: 'Configuração do valor da codificação',
  venueManage: 'Configuração do local',
  channel: 'gerenciamento canal',
  channelList: 'lista canais',
  quoteManage: 'Gerenciamento cotas',
  salesmanReportBusiness: 'Formulário reconciliação do vendedor',
  permission: 'Gerenciamento conta',
  systemAccount: 'Lista usuários',
  roleManage: 'lista funções',
  whitelist: 'lista permissões IP',
  backendLogs: 'Log back-end',
  service: 'sistema atendimento ao cliente',
  upDownRecord: 'Pontos para cima e para baixo do GM',
  upDownAudit: 'Auditoria ascendente e descendente',
  upDownRecordChannel: 'Pontos aumento canal',
  content: 'Anúncio do Sistema',
  linkConfig: 'Configuração oficial do canal',
  serviceConfig: 'Configuração do atendimento ao cliente',
  pushMessage: 'Mensagem rolagem',
  unbindControl: 'Desvincular lista controle',
  org: 'organização',
  orgList: 'Gerenciamento Organização',
  changeBindRecord: 'Alterar registro ligação'
}
export default menu