const home  = {
  home1: 'Registered people Number',
  home2: 'People recharging Number',
  home3: 'People withdrawing cash Number',
  home4: 'withdrawing deposits Ratio',
  home5: 'Total recharge',
  home6: 'Recharge revenue',
  home7: 'Amount of bonus',
  home8: 'Remaining gold coins',
  home9: 'Total withdrawals',
  home10: 'Player Withdrawal',
  home11: 'Withdrawal without deposit',
  home12: 'Withdrawal deposit amount ratio',
  home13: 'Total flow',
  home14: 'Profit and loss',
  home15: 'Total tax',
  home16: 'Games Number',
  home17: 'deposit ratio',
  home18: 'Home',
  col1: 'Date',
  col2: 'Recharge revenue',
  col3: 'System profit and loss',
  col4: "Today's running water",
  col5: 'RTP rate of return',
  col6: 'Registered people Number',
  col7: 'Active people Number',
  col8: 'First deposits Number',
  col9: 'People recharging Number',
  col10: 'people withdrawing money Number',
  col11: 'Recharge amount',
  col12: 'Amount of bonus',
  col13: 'Withdrawal amount',
  col14: 'Unrecharged amount',
  col15: 'Player withdrawal ratio',
  col16: 'Games Number'
}
export default home