const payment  = {
  payment1: 'Payment channel',
  payment2: 'Make up order',
  payment3: 'Please enter password verification',
  payment4: 'Password verification',
  payment5: 'Confirm',
  payment6: 'Cancel',
  payment7: 'Password cannot be empty',
  paymentCol1: 'Channel name',
  paymentCol2: 'Channel code',
  paymentCol3: 'Minimum amount',
  paymentCol4: 'Maximum amount',
  paymentCol5: 'Quick amount',
  paymentCol6: 'Front display name',
  paymentCol7: 'Status',
  paymentCol8: 'Exchange rate',
  order1: 'Total orders',
  order2: 'Paid orders',
  order3: 'Total order amount',
  order4: 'Total paid amount',
  order5: 'Success rate',
  order6: 'Total number of players',
  order7: 'Number of payers',
  order8: 'Player ID',
  order9: 'Inviter ID',
  order10: 'Order number',
  order11: 'Order status',
  order12: 'Order amount',
  order13: 'Application time',
  order14: 'Game log',
  orderD1: 'Channel name',
  orderD2: 'Sort',
  orderD3: 'Maximum amount',
  orderD4: 'Minimum amount',
  orderD5: 'Quick amount',
  orderD6: 'Front display name',
  orderD7: 'Exchange rate',
  orderD8: 'Status',
  orderD9: 'key',
  orderD10: 'app_key',
  orderD11: 'api address',
  orderD12: 'Callback address (notify_url)',
  orderD13: 'Merchant number (mchid)',
  orderD14: 'Institution number (app_id)',
  orderD15: 'Channel code (pay_code)',
  orderD16: 'Country code (country_code)',
  orderD17: 'Currency code (currency_code)',
  orderD18: 'Transaction type (type)',
  orderCol1: 'Order status',
  orderCol2: 'Order number',
  orderCol3: 'Order time',
  orderCol4: 'Payment time',
  orderCol5: 'Player ID',
  orderCol6: 'Recharge amount',
  orderCol7: 'Amount received',
  orderCol8: 'Channel name',
  paymenthistoryEdit1: 'Title',
  paymenthistoryEdit2: 'Content',
  paymenthistoryEdit3: 'Is it enabled',
  paymenthistoryEdit4: 'Please enter the title',
  paymenthistoryEdit5: 'Please enter the content',
}
export default payment