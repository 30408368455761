const agent  = {
  aRelation1: 'Recarga total',
  aRelation2: 'Retirada total',
  aRelation3: 'Lucros e perdas totais',
  aRelation4: 'ID do destinatário',
  aRelation5: 'ID do convidado',
  aRelation6: 'Se recarregar',
  aRelation7: 'Tempo registro',
  aRelation8: 'Tempo da primeira recarga',
  aRelationcol1: 'ID do destinatário',
  aRelationcol2: 'ID do convidado',
  aRelationcol3: 'Recarga total',
  aRelationcol4: 'Total retiradas',
  aRelationcol5: 'Comissão contribuição',
  aRelationcol6: 'Valor do primeiro depósito',
  aRelationcol7: 'Codificação total',
  aRelationcol8: 'Tempo da primeira recarga',
  aRelationcol9: 'Lucros e perdas totais',
  aRelationcol10: 'Registrar IP',
  aRelationcol11: 'Tempo registro',
  changeBind: 'alterar vinculação',
  unbing: 'desvinculação',
  ivReport1: 'Recompensa por convite',
  ivReport2: 'Faturamento total',
  ivReport3: 'ID do jogador',
  ivReport4: 'Agente Diário',
  ivReportCol1: 'Data',
  ivReportCol2: 'ID do jogador',
  ivReportCol3: 'Recompensa por convite',
  ivReportCol4: 'Recarga pessoal',
  ivReportCol5: 'Retirada Pessoal',
  ivReportCol6: 'Declaração pessoal',
  ivReportCol7: 'Adicionar subordinado',
  ivReportCol8: 'Adicionar número efetivo pessoas',
  ivReportCol9: 'Novo número depositantes pela primeira vez',
  ivReportCol10: 'Adicionar nova recarga',
  ivReportCol11: 'Adicionar novo fluxo',
  rewordCount1: 'Recompensa por convite',
  rewordCount2: 'Número usuários efetivos em todas as plataformas',
  rewordCount3: 'Recompensa média por convite',
  rewordCount4: 'Agente Diário',
  rewordCol1: 'Data',
  rewordCol2: 'Recompensa por convite',
  rewordCol3: 'Número usuários efetivos em todas as plataformas',
  rewordCol4: 'Recompensa média por convite',
  unbDetail1: 'ID do jogador',
  unbDetail2: 'ID do convidado',
  unbDetail3: 'ID do canal',
  unbDetail4: 'ID do vendedor',
  unbDetail5: 'Hora registro',
  unbDetail6: 'Desagrupando detalhes',
  unbDetailCol1: 'ID do jogador',
  unbDetailCol2: 'ID do convidado',
  unbDetailCol3: 'Canal',
  unbDetailCol4: 'Vendedor',
  unbDetailCol5: 'Hora registro',
  unbDetailCol6: 'Recarga total',
  unbDetailCol7: 'Retirada total',
  blogData1: 'ID do Blogger',
  blogData2: 'ID do canal',
  blogData3: 'Monitoramento dados do Blogger',
  blogDataCol1: 'Hora criação',
  blogDataCol2: 'Canal',
  blogDataCol3: 'Vendedor',
  blogDataCol4: 'ID do Blogger',
  blogDataCol5: 'alias',
  blogDataCol6: 'Pontuação mais alta',
  blogDataCol7: 'Retirada total',
  blogDataCol8: 'Número pessoas primeiro nível',
  blogDataCol9: 'Número recargas primeiro nível',
  blogDataCol10: 'Recarga total primeiro nível',
  blogDataCol11: 'Retirada total nível um',
  blogDataCol12: 'Taxa contribuição',
  blogDataCol13: 'Lucro',
  agentsum1: 'id do agente',
  agentsum2: 'Resumo do agente',
  agentsum3: 'pontuação alta',
  agentsum4: 'proibir',
  agentsumCol1: 'AgentId',
  agentsumCol2: 'Número pessoas primeiro nível ontem',
  agentsumCol3: 'Recarga nível 1 ontem',
  agentsumCol4: 'Número da equipe',
  agentsumCol5: 'Número recarga da equipe',
  agentsumCol6: 'Valor recarga da equipe',
  agentsumCol7: 'Valor total da retirada',
  agentsumCol8: 'Número pessoas primeiro nível',
  agentsumCol9: 'Número recarregadores primeiro nível',
  agentsumCol10: 'Valor recarga nível 1',
  agentsumCol11: 'Número pessoas segundo nível',
  agentsumCol12: 'Número pessoas com recarga secundária',
  agentsumCol13: 'Valor recarga nível 2',
  agentsumCol14: 'Número pessoas terceiro nível',
  agentsumCol15: 'Número pessoas recarregando no nível três',
  agentsumCol16: 'Valor recarga nível 3',
  changeBindRecord1: '',
  changeBindRecord2: '',
  changeBindRecordCol1: 'id do jogador',
  changeBindRecordCol2: 'Alterar o canal ligação',
  changeBindRecordCol3: 'Mudar o ex-vendedor',
  changeBindRecordCol4: 'Alterar o superior anterior',
  changeBindRecordCol5: 'Alterar o canal ligação',
  changeBindRecordCol6: 'Alterar o vendedor vinculado',
  changeBindRecordCol7: 'Supervisor após alterar a ligação',
  changeBindRecordCol8: 'Operador',
  changeBindRecordCol9: 'hora',
}
export default agent