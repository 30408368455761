const report  = {
  stDailyList: 'Daily statistics summary',
  stDailyListCol1: 'Date',
  stDailyListCol2: 'Recharge revenue',
  stDailyListCol3: 'System profit and loss',
  stDailyListCol4: 'Daily List',
  stDailyListCol5: 'RTP rate of return',
  stDailyListCol6: 'Number of registered people',
  stDailyListCol7: 'Number of active people',
  stDailyListCol8: 'Number of first deposits',
  stDailyListCol9: 'Number of people recharging',
  stDailyListCol10: 'Number of people withdrawing money',
  stDailyListCol11: 'Recharge amount',
  stDailyListCol12: 'Amount of bonus',
  stDailyListCol13: 'Withdrawal amount',
  stDailyListCol14: 'Unrecharged amount',
  stDailyListCol15: 'Player Withdrawal',
  stDailyListCol16: 'Player withdrawal ratio',
  stDailyListCol17: 'Number of games',
  stGameSettle1: 'Total water',
  stGameSettle2: 'Total profit and loss',
  stGameSettle3: 'RTP rate of return',
  stGameSettle4: 'Total number of games',
  stGameSettle5: 'Tax',
  stGameSettle6: 'Game profit and loss settlement',
  stGameSettleCol1: 'Date',
  stGameSettleCol2: 'Room ID',
  stGameSettleCol3: 'Room name',
  stGameSettleCol4: 'flow',
  stGameSettleCol5: 'System profit and loss',
  stGameSettleCol6: 'Game reporting rate',
  stGameSettleCol7: 'Tax',
  stGameSettleCol8: 'Number of games',
  stMerchantSettle1: 'Channel reconciliation table',
  stMerchantSettleCol1: 'Channel ID',
  stMerchantSettleCol2: 'Channel name',
  stMerchantSettleCol3: 'Total recharge',
  stMerchantSettleCol4: 'Recharge fee',
  stMerchantSettleCol5: 'Withdrawal fee',
  stMerchantSettleCol6: 'API fee',
  stMerchantSettleCol7: 'Total withdrawal',
  stMerchantSettleCol8: 'Withdrawal without deposit',
  stMerchantSettleCol9: 'Total profit',
  agentRS1: 'Reward expenditure summary',
  agentRSCol1: 'Date',
  agentRSCol2: 'Agent invitation reward',
  agentRSCol3: 'Recharge gradient reward',
  agentRSCol4: 'Weekly betting return',
  agentRSCol5: 'Daily betting return',
  agentRSCol6: 'Agent Weekly Rebate',
  agentRSCol7: 'Weekly deposit return',
  agentRSCol8: 'Weekly loss return',
  agentRSCol9: 'APP download gift',
  stRemainList1: 'Big market',
  stRemainList2: 'Channel',
  stRemainListCol1: 'number of people',
  stRemainListCol2: 'Retain the next day',
  stRemainListCol3: '2-day retention',
  stRemainListCol4: '3-day retention',
  stRemainListCol5: '5-day retention',
  stRemainListCol6: '7-day retention',
  stRemainListCol7: '15-day retention',
  stRemainListCol8: '30-day retention',
  gameEMCol1: 'time',
  gameEMCol2: 'Game RTP(%)',
  gameEMCol3: 'Liu Shui',
  gameEMCol4: 'Number of games',
  gameEMCol5: 'Total recharge',
  gameEMCol6: 'Total withdrawals',
  gameEMCol7: 'Total profit',
  gameEMCol8: 'System Profit',
  gameEMCol9: 'Game tax',
  gameEMCol10: 'Game profit and loss',
  gameEM1: "Recharge Player",
  gameEM2: "Unrecharged player",
  gameEM3: "New recharge players every day",
  staGameSettleDetail1: 'Date',
  staGameSettleDetail2: 'Room ID',
  staGameSettleDetail3: 'Room name',
  staGameSettleDetail4: 'Flow',
  staGameSettleDetail5: 'System profit and loss',
  staGameSettleDetail6: 'Game return rate',
  staGameSettleDetail7: 'Tax',
  staGameSettleDetail8: 'Number of games'
}
export default report