const org  = {
  url: '客户端地址',
  name: '机构名称',
  createTime: '创建时间',
  username: '用户名',
  password: '登录密码',
  confirmPassword: '确认密码',
  org1: '请输入新密码',
  org2: '密码至少包含3种字符以上',
  org3: '请再次输入密码',
  org4: '两次输入密码不一致!',
  org5: '请输入登录密码',
  org6: '请输入确认密码',
  org7: '请输入地址',
  org8: '请输入机构名称',
  org9: '请输入用户名'
}
export default org