const service  = {
  upDownRecord1: '玩家ID',
  upDownRecord2: '类型',
  upDownRecord3: '申请时间',
  upDownRecord4: '状态',
  upDownRecord5: '归属业务员ID',
  upDownRecord6: '一键审核通过',
  upDownRecord7: '审核',
  upDownRecordCol1: '玩家ID',
  upDownRecordCol2: '申请金额',
  upDownRecordCol3: '打码倍数',
  upDownRecordCol4: '类型',
  upDownRecordCol5: '状态',
  upDownRecordCol6: '申请时间',
  upDownRecordCol7: '申请备注',
  upDownRecordCol8: '审核备注',
  upDownRecordCol9: '审核人',
  upDownRecordCol10: '审核时间',
  udrcCol1: '玩家ID',
  udrcCol2: '归属渠道',
  udrcCol3: '归属业务员',
  udrcCol4: '归属业务员ID',
  udrcCol5: '申请金额',
  udrcCol6: '打码倍数',
  udrcCol7: '类型',
  udrcCol8: '状态',
  udrcCol9: '申请时间',
  udrcCol10: '申请备注',
  udrcCol11: '审核备注',
  udrcCol12: '申请人',
  udrcCol13: '审核人',
  udrcCol14: '审核时间',
  ubControl1: '掉绑控制名单',
  ubControlCol1: '玩家id',
  ubControlCol2: '归属渠道',
  ubControlCol3: '归属业务员',
  ubControlCol4: '玩家账号',
  pushMsg1: '类型',
  pushMsg2: '滚屏消息',
  pushMsgCol1: 'ID',
  pushMsgCol2: '类型',
  pushMsgCol3: '内容',
  pushMsgCol4: '最后操作时间',
  serviceConfig1: '客服配置',
  serviceConfigCol1: 'ID',
  serviceConfigCol2: '头像',
  serviceConfigCol3: '客服名称',
  serviceConfigCol4: '标题',
  serviceConfigCol5: '客服类型',
  serviceConfigCol6: '打开方式',
  serviceConfigCol7: '客服链接',
  serviceConfigCol8: '状态',
  linkConfig1: '官方频道配置',
  linkConfigCol1: '频道名称',
  linkConfigCol2: '频道链接',
  content1: '创建时间',
  content2: '状态',
  content3: '操作人',
  content4: '系统公告',
  content5: '确定要停用吗',
  contentCol1: '标题',
  contentCol2: '内容',
  contentCol3: '创建时间',
  contentCol4: '操作人',
  serviceD1: '标题',
  serviceD2: '内容',
  serviceD3: '是否启用',
  serviceD4: '请输入标题',
  serviceD5: '请输入内容',
  serviceD6: '频道名称',
  serviceD7: '频道链接',
  serviceD8: '请输入频道名称',
  serviceD9: '请输入频道链接',
  serviceD10: '类型',
  serviceD11: '手机号',
  serviceD12: '金额',
  serviceD13: '内容',
  serviceD14: '请选择类型',
  serviceD15: '请输入手机号',
  serviceD16: '请输入内容',
  serviceD17: '请输入金额',
  serviceConfigEdit1: '客服名称',
  serviceConfigEdit2: '标题',
  serviceConfigEdit3: '客服链接',
  serviceConfigEdit4: '客服类型',
  serviceConfigEdit5: '打开方式',
  serviceConfigEdit6: '头像',
  serviceConfigEdit7: '支持.webp，大小不超过1.5M',
  serviceConfigEdit8: '请输入客服名称',
  serviceConfigEdit9: '请输入标题',
  serviceConfigEdit10: '请输入客服链接',
  serviceConfigEdit11: '请选择客服类型',
  serviceConfigEdit12: '请选择打开方式',
  testManage1: '生成测试号',
  testManageCol1: '账号',
  testManageCol2: '密码',
  testManageCol3: '余额',
  testManageCol4: '注册时间',
  unbindControlEdit1: '玩家id',
  unbindControlEdit2: '请输入玩家id',
  upDownAuditEdit1: '审核订单',
  upDownAuditEdit2: '玩家账号',
  upDownAuditEdit3: '申请金额',
  upDownAuditEdit4: '申请人',
  upDownAuditEdit5: '申请备注',
  upDownAuditEdit6: '备注',
  upDownAuditEdit7: '通过',
  upDownAuditEdit8: '拒绝',
  upDownAuditEdit9: '请输入备注',
  upDownAuditEdit10: '上下分审核',
  upDownRecordEdit1: '玩家id',
  upDownRecordEdit2: '类型',
  upDownRecordEdit3: '金额',
  upDownRecordEdit4: '打码倍数',
  upDownRecordEdit5: '上/下分理由',
  upDownRecordEdit6: '请输入玩家id',
  upDownRecordEdit7: '请选择类型',
  upDownRecordEdit8: '请输入金额',
  upDownRecordEdit9: '请输入打码倍数'
}
export default service