const report  = {
  stDailyList: 'Resumo das estatísticas diárias',
  stDailyListCol1: 'Data',
  stDailyListCol2: 'Recarga de receita',
  stDailyListCol3: 'Lucros e perdas do sistema',
  stDailyListCol4: 'Lista Diária',
  stDailyListCol5: 'Taxa de retorno RTP',
  stDailyListCol6: 'Número de pessoas cadastradas',
  stDailyListCol7: 'Número de pessoas ativas',
  stDailyListCol8: 'Número de primeiros depósitos',
  stDailyListCol9: 'Número de pessoas recarregando',
  stDailyListCol10: 'Número de pessoas sacando dinheiro',
  stDailyListCol11: 'Valor de recarga',
  stDailyListCol12: 'Valor do bônus',
  stDailyListCol13: 'Valor da retirada',
  stDailyListCol14: 'Valor não recarregado',
  stDailyListCol15: 'Retirada de Jogador',
  stDailyListCol16: 'Taxa de retirada de jogadores',
  stDailyListCol17: 'Número de jogos',
  stGameSettle1: 'Água total',
  stGameSettle2: 'Lucros e perdas totais',
  stGameSettle3: 'Taxa de retorno RTP',
  stGameSettle4: 'Número total de jogos',
  stGameSettle5: 'Imposto',
  stGameSettle6: 'Liquidação de lucros e perdas do jogo',
  stGameSettleCol1: 'Data',
  stGameSettleCol2: 'ID da sala',
  stGameSettleCol3: 'Nome da sala',
  stGameSettleCol4: 'fluxo',
  stGameSettleCol5: 'Lucros e perdas do sistema',
  stGameSettleCol6: 'Taxa de relatórios de jogos',
  stGameSettleCol7: 'Imposto',
  stGameSettleCol8: 'Número de jogos',
  stMerchantSettle1: 'Tabela de reconciliação de canal',
  stMerchantSettleCol1: 'ID do canal',
  stMerchantSettleCol2: 'Nome do canal',
  stMerchantSettleCol3: 'Recarga total',
  stMerchantSettleCol4: 'Taxa de recarga',
  stMerchantSettleCol5: 'Taxa de retirada',
  stMerchantSettleCol6: 'taxa de API',
  stMerchantSettleCol7: 'Retirada total',
  stMerchantSettleCol8: 'Retirada sem depósito',
  stMerchantSettleCol9: 'Lucro total',
  agenteRS1: 'Resumo de despesas de recompensa',
  agenteRSCol1: 'Data',
  agentRSCol2: 'Recompensa por convite do agente',
  agentRSCol3: 'Recompensa gradiente de recarga',
  agentRSCol4: 'Retorno de apostas semanal',
  agentRSCol5: 'Retorno diário das apostas',
  agentRSCol6: 'Desconto Semanal do Agente',
  agentRSCol7: 'Retorno de depósito semanal',
  agentRSCol8: 'Retorno de perda semanal',
  agenteRSCol9: 'Presente de download de APP',
  stRemainList1: 'Grande mercado',
  stRemainList2: 'Canal',
  stRemainListCol1: 'número de pessoas',
  stRemainListCol2: 'Reter no dia seguinte',
  stRemainListCol3: 'retenção de 2 dias',
  stRemainListCol4: 'retenção de 3 dias',
  stRemainListCol5: 'retenção de 5 dias',
  stRemainListCol6: 'retenção de 7 dias',
  stRemainListCol7: 'retenção de 15 dias',
  stRemainListCol8: 'retenção de 30 dias',
  gameEMCol1: 'tempo',
  gameEMCol2: 'RTP do jogo (%)',
  gameEMCol3: 'Liu Shui',
  gameEMCol4: 'Número de jogos',
  gameEMCol5: 'Recarga total',
  gameEMCol6: 'Total de retiradas',
  gameEMCol7: 'Lucro total',
  gameEMCol8: 'Lucro do Sistema',
  gameEMCol9: 'Imposto sobre jogos',
  gameEMCol10: 'Lucros e perdas do jogo',
  gameEM1: "Recarregar Jogador",
  gameEM2: "Jogador não recarregado",
  gameEM3: "Novos jogadores de recarga todos os dias",
  staGameSettleDetail1: 'Data',
  staGameSettleDetail2: 'ID da sala',
  staGameSettleDetail3: 'Nome da sala',
  staGameSettleDetail4: 'água corrente',
  staGameSettleDetail5: 'Lucros e perdas do sistema',
  staGameSettleDetail6: 'Taxa de relatório do jogo',
  staGameSettleDetail7: 'Imposto',
  staGameSettleDetail8: 'Número de jogos'
}
export default report