const getters = {
    token: state => state.user.token,
    role: state => state.user.role,
    userInfo: state => state.user.userInfo,
    appInfo: state => state.user.appInfo,
    permissionList: state => state.user.permission,
    menu: state => state.user.menu,
    tenantId: state => state.user.tenantId,
    isCollapse: state => state.common.isCollapse,
    tagList: state => state.common.tagList,
    tagCurrent: state => state.common.tag,
    isFullScreen: state => state.common.isFullScreen,
    childMenus: state => state.common.childMenus,
    activeMenu: state => state.common.activeMenu,
    message: state => state.common.message
}
export default getters
