import axios from 'axios'
import store from '@/store'
import router from '../router'
import { Message } from 'element-ui'
axios.defaults.timeout = 60000
axios.defaults.withCredentials = false // 跨域请求，允许保存cookie

// 添加请求拦截器
axios.interceptors.request.use(function (config) {
  // console.log(config)
	if (store.getters.token && sessionStorage.getItem('token')) {
    if (config.params && config.params.queryToken) {
      config.headers['Authorization'] = 'Bearer' + ' ' + config.params.queryToken
    } else {
      config.headers['Authorization'] = 'Bearer' + ' ' + store.getters.token
      // config.baseURL = location.protocol + '//' + location.host + '/api'
      // config.headers['Content-Type'] = config.headers['Content-Type'] ? config.headers['Content-Type'] : 'application/json; charset=utf-8'
    }
	}
	return config
}, function (error) {
	console.log('error' + error)
	return Promise.reject(error)
})
// 添加响应拦截器
axios.interceptors.response.use(function (response) {
  // console.log(response)
  if (response.data.code == 302) {
    // Message({message: '认证异常,请重新登录', type: 'error'})
    store.commit('common/SET_MESSAGE', '认证异常,请重新登录')
    store.commit('user/SET_TOKEN', '')
    store.commit('common/CLEAR_TAG')
    router.push('/login')
  }
	return response
}, function (error) {
  console.log(error, error.indexOf('timeout'))
  if(!error.response){
    Message({message: '服务器请求超时，请重新登录', type: 'error'})
    return Promise.reject(error.response)
  }
	if (error.response.status == 401) {
    Message({message: '服务器请求超时，请重新登录', type: 'error'})
    store.commit('user/SET_TOKEN', '')
    store.commit('common/CLEAR_TAG')
    router.push('/login')
  }
  if (error.response.status == 404) {
    error.response.data.msg = '接口异常，请联系管理员'
    Message({message: '接口异常，请联系管理员', type: 'error'})
    return Promise.reject(error.response)
  } else {
    return Promise.reject(error.response)
  }
  console.log(error.response)
})
export default axios
