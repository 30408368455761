const report  = {
  stDailyList: '日况统计概要',
  stDailyListCol1: '日期',
  stDailyListCol2: '充值营收',
  stDailyListCol3: '系统盈亏',
  stDailyListCol4: '当日流水',
  stDailyListCol5: 'RTP回报率',
  stDailyListCol6: '注册人数',
  stDailyListCol7: '活跃人数',
  stDailyListCol8: '首充人数',
  stDailyListCol9: '充值人数',
  stDailyListCol10: '提现人数',
  stDailyListCol11: '充值金额',
  stDailyListCol12: '上分金额',
  stDailyListCol13: '提现金额',
  stDailyListCol14: '未充金额',
  stDailyListCol15: '玩家提款',
  stDailyListCol16: '玩家提冲比',
  stDailyListCol17: '游戏局数',
  stGameSettle1: '总流水',
  stGameSettle2: '总盈亏',
  stGameSettle3: 'RTP回报率',
  stGameSettle4: '总局数',
  stGameSettle5: '税收',
  stGameSettle6: '游戏盈亏结算',
  stGameSettleCol1: '日期',
  stGameSettleCol2: '房间ID',
  stGameSettleCol3: '房间名称',
  stGameSettleCol4: '流水',
  stGameSettleCol5: '系统盈亏',
  stGameSettleCol6: '游戏汇报率',
  stGameSettleCol7: '税收',
  stGameSettleCol8: '游戏局数',
  stMerchantSettle1: '渠道对账表',
  stMerchantSettleCol1: '渠道ID',
  stMerchantSettleCol2: '渠道名称',
  stMerchantSettleCol3: '总充值',
  stMerchantSettleCol4: '充值手续费',
  stMerchantSettleCol5: '提现手续费',
  stMerchantSettleCol6: 'API费用',
  stMerchantSettleCol7: '总提现',
  stMerchantSettleCol8: '未充提款',
  stMerchantSettleCol9: '总利润',
  agentRS1: '奖励支出汇总',
  agentRSCol1: '日期',
  agentRSCol2: '代理邀请奖励',
  agentRSCol3: '充值梯度奖励',
  agentRSCol4: '周投注返还',
  agentRSCol5: '日投注返还',
  agentRSCol6: '代理周返佣',
  agentRSCol7: '周存款返还',
  agentRSCol8: '周亏损返还',
  agentRSCol9: 'APP下载赠送',
  stRemainList1: '大盘',
  stRemainList2: '渠道',
  stRemainListCol1: '人数',
  stRemainListCol2: '次日留存',
  stRemainListCol3: '2日留存',
  stRemainListCol4: '3日留存',
  stRemainListCol5: '5日留存',
  stRemainListCol6: '7日留存',
  stRemainListCol7: '15日留存',
  stRemainListCol8: '30日留存',
  gameEMCol1: '时间',
  gameEMCol2: '游戏RTP(%)',
  gameEMCol3: '流水',
  gameEMCol4: '游戏局数',
  gameEMCol5: '总充值',
  gameEMCol6: '总提现',
  gameEMCol7: '总盈利',
  gameEMCol8: '系统盈利',
  gameEMCol9: '游戏税收',
  gameEMCol10: '游戏盈亏',
  gameEM1: "充值玩家",
  gameEM2: "未充值玩家",
  gameEM3: "每日新增充值玩家",
  staGameSettleDetail1: '日期',
  staGameSettleDetail2: '房间ID',
  staGameSettleDetail3: '房间名称',
  staGameSettleDetail4: '流水',
  staGameSettleDetail5: '系统盈亏',
  staGameSettleDetail6: '游戏汇报率',
  staGameSettleDetail7: '税收',
  staGameSettleDetail8: '游戏局数'
}
export default report