<template>
  <el-menu-item :index="item.href" :path="item.href" @click="goUrl(item.href)" v-if="item.children.length==0" v-show="item.show" :class="{'activeMenu': item.href == $route.path}">
    <i :class="item.icons"></i>
    <span slot="title">{{item.label}}</span>
  </el-menu-item>
  <el-submenu :index="item.label" v-else>
    <template slot="title" v-show="item.show">
      <i :class="item.icons"></i>
      <span>{{item.label}}</span>
    </template>
    <template v-for="(itemChild, indexChild) in item.children">
      <menu-row :key="indexChild" :item="itemChild"></menu-row>
    </template>
  </el-submenu>
</template>
<script>
  export default {
    name: 'menuRow',
    props: {
      item: {
        type: Object,
        required: true,
        default () {
          return {}
        }
      },
      index: {
        type: Number,
        default: 0
      }
    },
    data () {
      return {

      }
    },
    computed: {
    },
    methods: {
      goUrl (path) {
        this.$router.push(path, ()=>{}, ()=>{})
      }
    }
  }
</script>

<style lang="scss" scoped>
.activeMenu{
  background-color: #1890ff!important;
  color: #fff !important;
}
.el-menu-item i {
  color: #fff !important;
  margin-right: 8px;
}
.el-submenu__title i {
    color: #909399;
    margin-right: 8px;
}
.el-menu-item.is-active {
  color: #fff !important;
}
</style>
