const common  = {
  date: 'Date',
  time: 'Time',
  startDate: 'Start Date',
  endDate: 'End Date',
  search: 'Search',
  serverTime: 'Server Time',
  syncData: 'Synchronize Data',
  language: 'Select Language',
  more: 'More',
  closeOther: 'Close Other',
  closeAll: 'Close All',
  english: 'English',
  chinese: 'Chinese',
  portuguese: 'Portuguese',
  personalInfo: 'Personal Information',
  resetPass: 'Change Password',
  logout: 'Log Out',
  confirmLogout: 'Are you sure you want to log out? ',
  today: 'Today',
  yestoday: 'Yesterday',
  beforeYestoday: 'The day before yesterday',
  lastSeven: 'Last 7 days',
  lastFourteen: 'Last 14 days',
  lastThirty: 'Last 30 days',
  lastMonth: 'Last month',
  currentMonth: 'This month',
  userType: 'User type',
  select: 'Please select',
  channelId: 'Channel ID',
  month: 'Month',
  exportAll: 'Export all',
  room: 'Room',
  add: 'Add',
  edit: 'Modify',
  delete: 'Delete',
  action: 'Action',
  number: 'Serial number',
  to: 'To',
  reset: 'Reset',
  export: 'Export',
  userId: 'User id',
  start: 'Start',
  end: 'End',
  changeBind: 'Change Binding',
  unbing: 'Unbinding',
  done: 'Completed',
  unDone: 'Uncompleted',
  pInput: 'Please enter correctly',
  confirmDelete: 'Are you sure you want to delete? ',
  confirm: 'Confirm',
  cancel: 'Cancel',
  tip: 'Tip',
  all: 'All',
  upAndDown: 'Up and Down',
  oneKeyOpen: 'One-key Open',
  oneKeyClose: 'One-key Close',
  stop: 'Stop',
  regTime: 'Registration Time',
  save: 'Save',
  detail: 'Details',
  selectTime: 'Select Time'
}
export default common