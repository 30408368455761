<template>
  <div class="header-tags">
    <div class="tags-container">
      <div class="tags-box" ref="tagBox" @contextmenu.prevent.stop="handleContextmenu">
        <div class="tags-list" ref="tagList" @mousewheel="handleMouseWheel1">
          <!-- <div
            class="tag-item"
            :class="{'is-active':nowTagValue==item.value}"
            :name="item.value"
            v-for="(item,index) in tagList"
            :key="index"
            @click="openUrl(item)"
            ref="tagsPageOpened">
            <span class="icon-yuan tag-item-icon"></span>
            <span class="tag-text">{{item.label}}</span>
            <i class="el-icon-close tag-close" @click.stop="closeTag(item)" v-if="index>0"></i>
          </div> -->
          <el-tabs 
            v-model="nowTagValue" 
            type="card" 
            class="tag-wrap"
            @tab-click="openUrl"  
            @tab-remove="closeTag">
            <el-tab-pane 
              v-for="(item) in tagList" 
              :key="item.value" 
              :name="item.value"
              :label="item.label" 
              :closable="item.label == $t('menu.index') ? false : true">
            </el-tab-pane>
          </el-tabs>
        </div>
        <el-dropdown class="tags-menu pull-right">
          <el-button type="primary" size="small">{{ $t('common.more') }}<i class="el-icon-arrow-down el-icon--right"></i></el-button>
          <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="closeOtherN">{{ $t('common.closeOther') }}</el-dropdown-item>
              <el-dropdown-item @click.native="closeAll">{{ $t('common.closeAll') }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <el-card
      class="box-card right-menu"
      ref="rightMenu"
      v-show="show">
      <div class="text item" @click.prevent.stop="closeOther">关闭其他</div>
      <!-- <div class="text item" @click.prevent.stop="closeAll">关闭全部</div> -->
    </el-card>
  </div>
</template>
<script>
import { getArrIndex } from '@/util/tool'
import { mapGetters } from 'vuex'
export default {
  name: 'RightTags',
  data () {
    return {
      show: false,
      targetName: ''
    }
  },
  created () {
  },
  mounted () {
    var self = this
    document.onclick = function (event) {
      //  event.preventDefault()
      event.cancelBubble = true
      self.show = false
    }
    // this.handleMouseWheelNew()
  },
  computed: {
    nowTagValue: {
      get: function () {
        return this.$route.path
      },
      set: function (val) {

      } 
    },
    ...mapGetters(['tagList', 'isFullScreen'])
  },
  methods: {
    openUrl (item) {
      var index = parseInt(item.index)
      this.$router.push({
        path: this.tagList[index].value,
        query: this.tagList[index].query
      }, ()=>{})
    },
    closeTag (item) {
      var i = getArrIndex(this.tagList, item)
      this.$store.commit('common/CLOSE_TAG', this.tagList[i], i)
      this.$router.push({
        path: this.tagList[i - 1].value,
        query: this.tagList[i - 1].query
      })
    },
    handleMouseWheel1 () {},
    handleMouseWheel (event) {
      var tagList = this.$refs.tagList
      var tagBox = this.$refs.tagBox
      var direction = event.wheelDelta || -event.detail
      var delta = Math.max(-1, Math.min(1, direction))
      var step = 50
      var leftWidth = isNaN(parseInt(tagList.style.left)) ? 0 : parseInt(tagList.style.left)
      var offsetWidth = tagBox.offsetWidth - tagList.offsetWidth
      if (delta < 0) { // 向下滚
        if (leftWidth - step < offsetWidth) {
          if (offsetWidth > 0) {
            tagList.style.left = 0 + 'px'
          } else {
            tagList.style.left = offsetWidth + 'px'
          }
        } else {
          tagList.style.left = leftWidth - step + 'px'
        }
      } else if (delta > 0) { // 向上滚
        if ((leftWidth + step) > 0) {
          tagList.style.left = 0 + 'px'
        } else {
          tagList.style.left = leftWidth + step + 'px'
        }
      }
    },
    handleMouseWheelNew () {
      this.$nextTick(() => {
        var tagList = this.$refs.tagList.querySelector('.el-tabs__nav-scroll')
        var tagBox = this.$refs.tagBox.querySelector('.el-tabs__nav')
        // console.log(tagList)
        tagList.onmousewheel = (event) => {
          var direction = event.wheelDelta || -event.detail
          var delta = Math.max(-1, Math.min(1, direction))
          var offsetWidth = tagBox.offsetWidth - tagList.offsetWidth
          // console.log(delta, offsetWidth)
          var step = 50
          if (delta < 0) { // 向下滚
            tagBox.style.transform = 'translateX(' + -offsetWidth + 'px)'
            console.log(tagBox.style.transform)
          } else if (delta > 0) { // 向上滚
            tagBox.style.transform = 'translateX(' + 0 + 'px)'
            console.log(tagBox.style.transform)
          }
        }
      })
    },
    handleContextmenu (event) {
      this.show = true
      var rightMenu = this.$refs.rightMenu.$el
      this.targetName = event.target.innerText.trim()
      console.log(this.targetName)
      rightMenu.style.left = event.clientX - 230 + 'px'
    },
    closeOther () {
      if (this.targetName) {
        var item = []
        for (var i = 0; i < this.tagList.length; i++) {
          if (this.targetName == this.tagList[i].label) {
            item[0] = this.tagList[i] 
          }
        }
        this.$router.push({
          path: item[0].value,
          query: item[0].query
        })
        this.$store.commit('common/CLOSE_OTHER_TAG', item)
        this.show = false
      } else {
        this.$message({ message: '未选中当前项', duration: 2000, type: 'error' })
      }
    },
    closeOtherN () {
      var item = []
      for (var i = 0; i < this.tagList.length; i++) {
        if (this.$route.path == this.tagList[i].value) {
          item[0] = this.tagList[i] 
        }
      }
      this.$router.push({
        path: item[0].value,
        query: item[0].query
      })
      this.$store.commit('common/CLOSE_OTHER_TAG', item)
    },
    closeAll () {
      this.$store.commit('common/CLEAR_TAG')
      this.$router.push('/home/index')
      this.show = false
    }
  }
}
</script>
<style lang="scss">
.icon-yuan:before {
  width: 10px;
  height: 10px;
  background: #eee;
  display: inline-block;
  border-radius: 50%;
  content: '';
}
.tags-container .tag-item.is-active .icon-yuan:before {
  background: #409eff;
}
.tags-breadcrumb {
  display: flex;
  align-items: center;
  padding: 0 16px;
  height: 50px;
}

.tags-breadcrumb-list {
  padding: 0 15px;
}

.tag-collapse {
  font-size: 36px !important;
}

.tag-collapse.tag-collapse_right {
  transform: rotate(90deg);
}
.header-tags {
  height: 40px;
  line-height: 40px;
  background: #fff;
  border-top: 1px solid #eee;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  // padding: 0 20px;
  z-index: 100;
  position: relative;
  position: static\0;
}
.tags-container {
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  .tags-box {
    position: relative;
    box-sizing: border-box;
    padding-right: 100px;
    // overflow-x: auto;
    // overflow-y:hidden;
    width: 100%;
    height: 40px;
  }
  .tags-list {
    position: absolute;
    padding: 0px 10px;
    overflow: visible;
    white-space: nowrap;
    transition: left 0.3s ease;
    width: 100%;
    padding-right: 100px;
    box-sizing: border-box;
  }
  .tag-item-icon {
    color: #eee;
    font-size: 11px !important;
  }
  .tag-wrap{
    .el-tabs__header{
      margin: 0px;
      border-bottom: none;
    }
    .el-tabs__nav{
      border: none;
    }
    .el-tabs__item:first-child{
      border-left: 1px solid #eee;
    }
    .el-tabs__item::before{
      content: '';
      position: absolute;
      width: 10px;
      height: 10px;
      background: #eee;
      display: inline-block;
      border-radius: 50%;
      left: 10px;
      top: 10px;
    }
    .is-active.el-tabs__item::before{
      background: #409eff;
    }
    .el-tabs__item{
      padding-left: 30px!important;
    }
  }
  .tag-item, .tag-wrap .el-tabs__item{
    position: relative;
    display: inline-block;
    height: 30px;
    line-height: 30px;
    margin: 2px 4px 2px 0;
    padding: 0 10px;
    border: 1px solid #eee !important;
    border-radius: 3px !important;
    color: #495060 !important;
    font-size: 12px;
    vertical-align: middle;
    opacity: 1;
    overflow: hidden;
    cursor: pointer;
    margin-top: -1px;
    &.is-active {
      .tag-item-icon {
        color: #409eff;
      }
      border-color: #409eff !important;
    }
  }
  .tag-text {
    margin-left: 8px;
  }
  .tag-close {
    margin-left: 6px;
    padding: 2px;
  }
  .tag-close:hover {
    margin-left: 6px;
    background-color: #909399;
    color: #fff;
    border-radius: 50%;
    padding: 2px;
  }
  .tag-item:hover {
    opacity: 0.85;
  }
  .tags-menu {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    padding: 0 10px;
    height: 100%;
    box-sizing: border-box;
    background-color: #fff;
    // box-shadow: -3px 0 15px 3px rgba(0, 0, 0, 0.1);
    box-shadow: none;
    border-top: none;
    border-left-color: #f3f3f3;
    border-bottom-color: #eee;
  }
}
.contextmenu {
  margin: 0;
  top: 64px;
  list-style-type: none;
  padding: 5px 0;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 400;
  color: #333;
  box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.3);
  li {
    margin: 0;
    height: 32px;
    line-height: 32px;
    padding: 0 15px;
    cursor: pointer;
    &:hover {
      background: #eee;
    }
  }
}
.right-menu {
  position: absolute;
  left: 0;
  top: 40px;
  z-index: 8;
  width: 80px;
  .el-card__body{
    padding: 0px;
  }
  .text{
    text-align: center;
    cursor: pointer;
    padding: 5px;
  }
}
</style>
