const activity  = {
  popupImg1: 'Status da atividade',
  popupImg2: 'Tipo salto',
  popupImg3: 'Gerenciamento imagens pop-up',
  popupImgCon1: 'Título',
  popupImgCon2: 'Imagem do celular',
  popupImgCon3: 'imagem do PC',
  popupImgCon4: 'Tipo anúncio',
  popupImgCon5: 'comprimento',
  popupImgCon6: 'largura',
  popupImgCon7: 'Tipo salto',
  popupImgCon8: 'Endereço salto',
  popupImgCon9: 'Classificar',
  popupImgCon10: 'status',
  ad1: 'status',
  ad2: 'Gerenciamento diagrama atividades',
  ad3: 'Suporte .webp, tamanho não superior a 1,5M',
  adCol1: 'Título',
  adCol2: 'Foto do celular',
  adCol3: 'Tipo link',
  adCol4: 'Link salto',
  adCol5: 'Classificar',
  adCol6: 'status',
  suspension1: 'Gerenciamento do gráfico suspensão',
  suspensionCol1: 'Título',
  suspensionCol2: 'Imagem do celular',
  suspensionCol3: 'imagem do pc',
  suspensionCol4: 'Link salto',
  suspensionCol5: 'status',
  suspensionCol6: 'Classificar',
  suspensionCol7: 'modo exibição',
  advertisingEdit1: 'Título',
  advertisingEdit2: 'Tipo link',
  advertisingEdit3: 'Link salto',
  advertisingEdit4: 'status',
  advertisingEdit5: 'Classificar',
  advertisingEdit6: 'imagem h5',
  advertisingEdit7: 'Por favor, insira um título',
  advertisingEdit8: 'Comprimento entre 1 e 100 caracteres',
  advertisingEdit9: 'Selecione o tipo link',
  advertisingEdit10: 'O link salto não pode estar vazio',
  advertisingEdit11: 'Perda semanal',
  advertisingEdit12: 'Recompensa por convite',
  advertisingEdit13: 'Agente',
  advertisingEdit14: 'Depósitos acumulados',
  popupImgEdit1: 'Tipo anúncio',
  popupImgEdit2: 'Título',
  popupImgEdit3: 'Tipo salto',
  popupImgEdit4: 'Endereço salto',
  popupImgEdit5: 'status',
  popupImgEdit6: 'Classificar',
  popupImgEdit7: 'Foto do celular',
  popupImgEdit8: 'Imagem do PC',
  popupImgEdit9: 'comprimento',
  popupImgEdit10: 'largura',
  popupImgEdit11: 'Texto',
  popupImgEdit12: 'O valor está entre 0-10000',
  popupImgEdit13: 'Selecione o tipo anúncio',
  popupImgEdit14: 'Por favor, insira um título',
  popupImgEdit15: 'Comprimento entre 1 e 100 caracteres',
  popupImgEdit16: 'Por favor, selecione o tipo salto',
  popupImgEdit17: 'Por favor, insira o texto',
  suspensionEdit1: 'Título',
  suspensionEdit2: 'Tipo salto',
  suspensionEdit3: 'Link salto',
  suspensionEdit4: 'Classificar',
  suspensionEdit5: 'status',
  suspensionEdit6: 'Modo exibição',
  suspensionEdit7: 'Foto do celular',
  suspensionEdit8: 'imagem do PC',
  suspensionEdit9: 'comprimento',
  suspensionEdit10: 'largura',
  suspensionEdit11: 'O valor está entre 0-10000',
  suspensionEdit12: 'Por favor, selecione o tipo anúncio',
  suspensionEdit13: 'Por favor, insira um título',
  suspensionEdit14: 'Comprimento entre 1 e 100 caracteres',
  suspensionEdit15: 'Por favor, selecione o tipo salto',
  suspensionEdit16: 'Por favor, insira o link salto',
  suspensionEdit17: 'Por favor, selecione o método exibição',
  wActivityData1: 'Gradiente convite para primeiro depósito',
  wActivityData2: 'Recarga total',
  wActivityData3: 'Bônus total',
  wActivityData4: 'ID superior',
  wActivityData5: 'id subordinado',
  wActivityData6: 'Compartilhamento recarga',
  wActivityData7: 'Compartilhamento total',
  wActivityData8: 'Nível',
  wActivityData9: 'Comissão semanal',
  wActivityData10: 'ID do jogador',
  wActivityData11: 'Depósito semanal',
  wActivityData12: 'Apostas semanais',
  wActivityData13: 'Perda semanal',
  wActivityData14: 'Apostas Diárias',
  wActivityData15: 'Desconto',
  wActivityData16: 'ID do jogador contribuinte',
  
  wActivityDataCol1: 'ID superior',
  wActivityDataCol2: 'id subordinado',
  wActivityDataCol3: 'Valor do primeiro depósito',
  wActivityDataCol4: 'Bônus',
  wActivityDataCol5: 'tempo',

  wActivityData1Col1: 'ID superior',
  wActivityData1Col2: 'id subordinado',
  wActivityData1Col3: 'Recarregar',
  wActivityData1Col4: 'Nível/Escala',
  wActivityData1Col5: 'Quantidade compartilhamento',
  wActivityData1Col6: 'Tempo',

  wActivityData2Col1: 'ID do jogador',
  wActivityData2Col2: 'Recarga nível inferior esta semana',
  wActivityData2Col3: 'Recarga nível inferior semana passada',
  wActivityData2Col4: 'Desconto semana passada',
  wActivityData2Col5: 'Comissão acumulada',
  wActivityData2Col6: 'Coleta acumulada',

  wActivityData3Col1: 'ID do jogador',
  wActivityData3Col2: 'Recarregue esta semana',
  wActivityData3Col3: 'Recarregue semana passada',
  wActivityData3Col4: 'Desconto semana passada',
  wActivityData3Col5: 'Comissão acumulada',
  wActivityData3Col6: 'Coleta acumulada',

  wActivityData4Col1: 'ID do jogador',
  wActivityData4Col2: 'Apostas esta semana',
  wActivityData4Col3: 'Apostas semana passada',
  wActivityData4Col4: 'Desconto semana passada',
  wActivityData4Col5: 'Comissão acumulada',
  wActivityData4Col6: 'Coleta acumulada',

  wActivityData5Col1: 'ID do jogador',
  wActivityData5Col2: 'Depósito/saque esta semana',
  wActivityData5Col3: 'Depósito/Retirada semana passada',
  wActivityData5Col4: 'Desconto semana passada',
  wActivityData5Col5: 'Comissão acumulada',
  wActivityData5Col6: 'Coleta acumulada',

  wActivityData6Col1: 'ID do jogador',
  wActivityData6Col2: 'Apostas hoje',
  wActivityData6Col3: 'Apostas ontem',
  wActivityData6Col4: 'Comissão ontem',
  wActivityData6Col5: 'Comissão acumulada',
  wActivityData6Col6: 'Coleta acumulada',

  wActivityData7Col1: 'Data',
  wActivityData7Col2: 'Jogadores contribuintes',
  wActivityData7Col3: 'ID superior',
  wActivityData7Col4: 'Nível',
  wActivityData7Col5: 'Desconto contribuição',
  wActivityData7Col6: 'Taxa desconto (mil pontos)',
  wActivityData7Col7: 'Apostas ontem',
}
export default activity