const vip  = {
  vipList1: 'ID do jogador',
  vipList2: 'nome de usuário',
  vipList3: 'Número de celular',
  vipList4: 'Canal de atribuição',
  vipList5: 'Pertencente ao vendedor',
  vipList6: 'Recarga mínima',
  vipList7: 'Recarga máxima',
  vipList8: 'Retirada mínima',
  vipList9: 'Retirada máxima',
  vipList10: 'Registrar IP',
  vipList11: 'Todos os jogadores',
  vipList12: 'Notas do Jogador',
  vipList13: 'Adicionar código',
  vipList14: 'Mais',
  vipList15: 'Limpar codificação',
  vipList16: 'Digite PG',
  vipList17: 'Alterar senha',
  vipList18: 'Alterar senha de saque',
  vipList19: 'Desativar conta',
  vipList20: 'Tem certeza que deseja limpar a codificação?',
  vipList21: 'Prompt',
  vipList22: 'OK',
  vipList23: 'Cancelar',
  vipList24: 'Tem certeza que deseja desativar a conta?',
  vipListD1: 'ID do jogador',
  vipListD2: 'Quantidade de codificação',
  vipListD3: 'Adicionar código',
  vipListD4: 'Conta do jogador',
  vipListD5: 'Notas do Jogador',
  vipListD6: 'Senha',
  vipListD7: 'Alterar senha',
  vipListD8: 'Senha de retirada',
  vipListD9: 'Alterar senha de saque',
  vipListD10: 'Adicionar codificação',
  vipListD11: 'Reduzir codificação',
  vipListcol1: 'ID do jogador',
  vipListcol2: 'Canal de atribuição',
  vipListcol3: 'Pertencente ao vendedor',
  vipListcol4: 'Conta do jogador',
  vipListcol5: 'Número de celular',
  vipListcol6: 'Recarga total',
  vipListcol7: 'Total de retiradas',
  vipListcol8: 'Saldo da conta',
  vipListcol9: 'Dispositivo de login',
  vipListcol10: 'Hora de registro',
  vipBet1: 'Número de jogos',
  vipBet2: 'Apostas totais',
  vipBet3: 'Total de vitórias',
  vipBet4: 'Imposto total',
  vipBet5: 'Total de vitórias e derrotas',
  vipBet6: 'ID do jogador',
  vipBet7: 'Quarto',
  vipBet8: 'ID do canal de atribuição',
  vipBet9: 'ID do vendedor atribuível',
  vipBet10: 'Registro do jogo',
  vipBetcol1: 'Data',
  vipBetcol2: 'ID da sala',
  vipBetcol3: 'Nome da sala',
  vipBetcol4: 'Liu Shui',
  vipBetcol5: 'Lucros e perdas do sistema',
  vipBetcol6: 'Taxa de relatórios de jogos',
  vipBetcol7: 'Imposto',
  vipBetcol8: 'Número de jogos',
  acchange1: 'ID do jogador',
  acchange2: 'ID do canal',
  acchange3: 'ID do vendedor',
  acchange4: 'Tipo de moeda de ouro',
  acchange5: 'Data',
  acchange6: 'Registro de moedas de ouro',
  acchange7: 'Tipo de alteração de conta',
  acchangecol1: 'ID do jogador',
  acchangecol2: 'ID do canal',
  acchangecol3: 'ID do quarto',
  acchangecol4: 'Saldo inicial',
  acchangecol5: 'tipo',
  acchangecol6: 'Valor',
  acchangecol7: 'Saldo atual',
  acchangecol8: 'Imposto',
  acchangecol9: 'tempo',
  acchangecol10: 'Nome da configuração',
  acchangecol11: 'Configurar nome em inglês',
  flowList1: 'Detalhes da codificação',
  flowListcol1: 'ID do jogador',
  flowListcol2: 'Cota de codificação',
  flowListcol3: 'Quantidade de codificação atual',
  flowListcol4: 'Quantidade necessária de codificação',
  flowListcol5: 'Conclusão',
  flowListcol6: 'status',
  flowListcol7: 'tipo',
  flowListcol8: 'Aumentar o tempo de codificação',
  flowListcol9: 'Tempo de conclusão da codificação',
  vert1: 'Número de telefone celular do membro',
  vert2: 'status',
  vert3: 'Hora de emissão',
  vert4: 'Código de verificação do celular',
  vert5: 'Código de verificação de e-mail',
  vertCol1: 'Número de celular',
  vertCol2: 'Código de verificação do celular',
  vertCol3: 'Hora de emissão',
  vertCol4: 'Hora de sucesso',
  vertCol5: 'IP',
  vertCol6: 'status',
  vipListDetailEdit1: 'método',
  vipListDetailEdit2: 'Padrão',
  vipListDetailEdit3: 'Fechar',
  vipListDetailEdit4: 'Abrir',
  vipListDetailEdit5: 'Definir pessoal',
  vipListDetailEdit6: 'Configurar equipe',
  vipListDetailEdit7: 'Cancelar',
  vipListDetailEdit8: 'Novo',
  vipListDetailEdit9: 'Controle de página falso',
  vipListEdit1: 'Número de pessoas convidadas',
  vipListEdit2: 'Taxa de vinculação (%)',
  vipListEdit3: 'Número limitado cumulativo de pessoas',
  vipListEdit4: 'Número de pessoas convidadas',
  vipListEdit5: 'Tempo',
  vipListEdit6: 'Observações',
  vipListEdit7: 'Operador',
  vipListEdit8: 'Registro de operação',
  vipListEdit9: 'Nível',
  vipListEdit10: 'Proporção',
  vipListEdit11: 'Tipo de recarga',
  vipListEdit12: 'Codificação',
  vipListEdit13: 'Compartilhamento de recarga',
  vipListDetail1: 'Informações básicas',
  vipListDetail2: 'Status da conta',
  vipListDetail3: 'Conta do jogador',
  vipListDetail4: 'ID do jogador',
  vipListDetail5: 'Número de celular',
  vipListDetail6: 'Observações',
  vipListDetail7: 'Registro de operação',
  vipListDetail8: 'Status da conta',
  vipListDetail9: 'ID superior',
  vipListDetail10: 'Pertencente ao vendedor',
  vipListDetail11: 'Canal de atribuição',
  vipListDetail12: 'Informações da conta',
  vipListDetail13: 'Saldo (pode ser retirado/congelado)',
  vipListDetail14: 'Valor total de recarga',
  vipListDetail15: 'Valor total da retirada',
  vipListDetail16: 'Número de recargas',
  vipListDetail17: 'Número de retiradas',
  vipListDetail18: 'Total de apostas',
  vipListDetail19: 'Lucros e perdas totais',
  vipListDetail20: 'Comissão histórica',
  vipListDetail21: 'Pontos de back-end',
  vipListDetail22: 'Volume de codificação concluído',
  vipListDetail23: 'Quantidade de codificação inacabada',
  vipListDetail24: 'Codificação total',
  vipListDetail25: 'Registrar informações de login',
  vipListDetail26: 'Registrar IP',
  vipListDetail27: 'Hora de registro',
  vipListDetail28: 'Último horário de login',
  vipListDetail29: 'IP do último login',
  vipListDetail30: 'Dispositivo de login',
  vipListDetail31: 'Interruptor de controle',
  vipListDetail32: 'Troca de jogo',
  vipListDetail33: 'Interruptor de retirada',
  vipListDetail34: 'Mudança de imposto',
  vipListDetail35: 'Troca de retirada automática',
  vipListDetail36: 'Troca de bônus vencedora',
  vipListDetail37: 'É um blogueiro',
  vipListDetail38: 'Mudança de comissão',
  vipListDetail39: 'Controle de página falso',
  vipListDetail40: 'Desamarrar controle',
  vipListDetail41: 'Compartilhamento de recarga',
  vipListDetail42: 'Registro de recarga',
  vipListDetail43: 'Registro de retirada',
  vipListDetail44: 'Resumo subordinado',
  vipListDetail45: 'Registro do jogo',
  vipListDetail46: 'Registro de moedas de ouro',
  vipListDetail47: 'Mesma consulta IP',
  vipListDetail48: 'Log de login',
  vipListDetail49: 'Registro da promoção',
  vipListDetail50: 'Por favor, selecione uma data',
  vipListDetailCol1: 'Status do pedido',
  vipListDetailCol2: 'Número do pedido',
  vipListDetailCol3: 'Hora do pedido',
  vipListDetailCol4: 'Prazo de pagamento',
  vipListDetailCol5: 'Valor de recarga',
  vipListDetailCol6: 'Valor recebido',
  vipListDetailCol7: 'Nome do canal',
  vipListDetail1Col1: 'Número do pedido',
  vipListDetail1Col2: 'Status do pedido',
  vipListDetail1Col3: 'Valor da retirada',
  vipListDetail1Col4: 'Valor recebido',
  vipListDetail1Col5: 'taxa de manuseio',
  vipListDetail1Col6: 'Operador',
  vipListDetail1Col7: 'Tempo de aplicação',
  vipListDetail1Col8: 'Tempo de auditoria',
  vipListDetail3Col1: 'Data',
  vipListDetail3Col2: 'ID da sala',
  vipListDetail3Col3: 'Nome da sala',
  vipListDetail3Col4: 'Liu Shui',
  vipListDetail3Col5: 'Lucros e perdas',
  vipListDetail3Col6: 'Taxa de retorno do jogo',
  vipListDetail3Col7: 'Imposto',
  vipListDetail3Col8: 'Número de jogos',
  vipListDetail5Col1: 'ID do jogador',
  vipListDetail5Col2: 'Conta do jogador',
  vipListDetail5Col3: 'Registrar IP',
  vipListDetail5Col4: 'Hora de registro',
  vipListDetail5Col5: 'IP de login',
  vipListDetail5Col6: 'Hora de login',
  vipListDetail6Col1: 'Dispositivo de login',
  vipListDetail6Col2: 'IP de login',
  vipListDetail6Col3: 'Hora de login',
  vipListDetail7Col1: 'ID do destinatário',
  vipListDetail7Col2: 'ID do convidado',
  vipListDetail7Col3: 'Recarga total',
  vipListDetail7Col4: 'Total de retiradas',
  vipListDetail7Col5: 'Valor do primeiro depósito',
  vipListDetail7Col6: 'Tempo da primeira recarga',
  vipListDetail7Col7: 'Lucros e perdas totais',
  vipListDetail7Col8: 'Registrar IP',
  vipListDetail7Col9: 'Hora de registro',
}
export default vip