<template>
  <div class="header-top" style="height: 50px;">
    <i class="fa fa-navicon icon-bar" @click="toggleCollapse"></i>
    <div class="header-left" style="display: none;">
      <el-menu :default-active="activeIndex" mode="horizontal" text-color="#606266">
        <template v-for="(item,index) in routesArrLess">
          <el-menu-item :index="item.path" :key="index" v-if="item.show" :path="item.path" @click.native="openMenu(item)">
            <i :class="item.icons"></i>
            <span style="margin-left:5px;">{{item.label}}</span>
          </el-menu-item>
        </template>
        <el-submenu index="more" v-if="routesArrMore.length > 0">
          <template slot="title">更多选项</template>
          <el-menu-item :index="item.path" 
            v-for="(item, index) in routesArrMore" 
            :key="index"
            :path="item.path"  
            @click.native="openMenu(item)">
            <i :class="item.icons"></i>
            <span style="margin-left:5px;">{{item.label}}</span>
          </el-menu-item>
        </el-submenu>
      </el-menu>
    </div>
    <div class="header-right user-name">
      <!-- <el-button class="tags-menu" @click="handleFullScreen">
        <i class="fa fa fa-arrows-alt" v-if="isFullScreen==false"></i>
        <i class="fa fa-compress" v-if="isFullScreen==true"></i>
      </el-button> -->
      <i class="el-icon-refresh refresh-page" @click="handleRefresh"></i>
      <span style="margin: 0 10px;color:#606266;">{{ $t('common.serverTime') }}：{{ dateTime }}</span>
      <!-- <span style="margin: 0 10px;color:#606266;">{{ $t('common.syncData') }}</span> -->
      <el-dropdown trigger="click"  @command="handleCommand">
        <span class="el-dropdown-link">
          {{ $t('common.language') }}
          <i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <!-- 修改密码 -->
          <el-dropdown-item command="en">{{ $t('common.english') }}</el-dropdown-item>
          <el-dropdown-item command="zh">{{ $t('common.chinese') }}</el-dropdown-item>
          <el-dropdown-item command="pt">{{ $t('common.portuguese') }}</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <el-tooltip class="" effect="dark" content="头像" placement="bottom">
        <img class="top-userImg" :src="headImgUrl" @error="loadError" />
      </el-tooltip>
      <el-dropdown trigger="click">
        <span class="el-dropdown-link">
          {{userInfo.name}}
          <i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <!-- 修改密码 -->
          <!-- <el-dropdown-item @click.native="handleInfo">{{ $t('common.personalInfo') }}</el-dropdown-item>
          <el-dropdown-item @click.native="handleReset">{{ $t('common.resetPass') }}</el-dropdown-item> -->
          <el-dropdown-item @click.native="logout( $t('common.confirmLogout'))">{{ $t('common.logout') }}</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <!-- 个人信息弹窗 -->
    <personal-info
      @closeDialog="closeDialog('info')"
      @confirmDialog="confirmDialog('info')"
      @handleClose="closeDialog('info')"
      v-if="dialogInfo"
      :dialogVisible="dialogInfo">
    </personal-info>
    <!-- 修改密码 -->
    <reset-password
      @closeDialog="closeDialog('reset')"
      @confirmDialog="confirmDialog('reset')"
      @handleClose="closeDialog('reset')"
      v-if="dialogReset"
      :dialogVisible="dialogReset">
    </reset-password>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import personalInfo from '@/themes/cvue/views/home/personalInfo'
import resetPassword from '@/themes/cvue/views/home/resetPassword'
export default {
  name: 'RightTop',
  components: {
    personalInfo,
    resetPassword
  },
  data () {
    return {
      msg: 'hello',
      oldPwd: '',
      newPwd: '',
      checkNewPwd: '',
      checked: false,
      dialogInfo: false,
      dialogReset: false,
      assetsPhoto: './static/img/main/photo.png',
      // activeIndex: '/home',
      routesArr: [],
      routesArrLess: [],
      routesArrMore: [],
      len: 0,
      dateTime: '',
      timer: null,
      diffTime: 0
    }
  },
  watch: {
    activeIndex (n, o) {
      this.routesArr.forEach((item, index) => {
        if (item.path === n) {
          this.openMenu(item)
        }
      })
    }
  },
  beforeMount () {
    window.addEventListener('resize', this.setMenuWidth)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.setMenuWidth)
    if(this.timer){
      clearInterval(this.timer)
      this.timer = null
    }
  },
  computed: {
    ...mapGetters(['isFullScreen', 'userInfo', 'menu', 'activeMenu']),
    headImgUrl () {
      var headImgUrl = this.userInfo.headImgUrl ? this.userInfo.headImgUrl : this.assetsPhoto
      return headImgUrl
    },
    activeIndex: {
      get: function () {
        return this.$route.matched[0].path
      },
      set: function (val) {
      } 
    }
  },
  mounted () {
    this.initRoutes()
    this.setMenuWidth()
    this.getServerTime()
  },
  methods: {
    handleCommand (command) {
      // console.log(command)
      this.$store.commit('common/CLEAR_TAG', command)
      localStorage.setItem('locale', command)
      location.reload()
    },
    handleRefresh () {
      location.reload()
    },
    getServerTime () {
      this.$store.dispatch('manage/ServiceTimeGet', '').then(res => {
        if (res.code === 0) {
          var serverTime = new Date(res.data).getTime()
          // console.log(serverTime)
          this.diffTime = new Date().getTime() - serverTime
          this.setDateTime()
        }
      })
    },
    getDateTime () {
      function setStr (num) {
        if (num < 10) {
          return '0' + num
        } else {
          return num
        }
      }
      var currentTime = new Date().getTime()
      var now = new Date(currentTime - this.diffTime)
      var y = now.getFullYear()
      var m = setStr(now.getMonth() + 1)
      var d = setStr(now.getDate())
      var h = setStr(now.getHours())
      var mi = setStr(now.getMinutes())
      var mi = setStr(now.getMinutes())
      var s = setStr(now.getSeconds())
      return y + '-' + m + '-' + d + ' ' + h + ':' + mi + ':' + s
    },
    setDateTime () {
      if (this.timer) {
        clearInterval(this.timer)
        this.timer = null
      }
      this.timer = setInterval(() => {
        var dateTime = this.getDateTime()
        this.dateTime = dateTime
      }, 1000)
    },
    handleFullScreen () {
      this.$store.commit('common/SET_FULL_SCREEN')
    },
    // 根据路由初始化菜单
    initRoutes () {
      // console.log(this.menu)
      this.routesArr = this.menu.filter((item) => item.show)
      this.len = this.routesArr.length
      this.activeIndex = this.$route.matched[0].path
      this.routesArr.forEach((item, index) => {
        if (item.path === this.activeIndex) {
          this.openMenu(item)
        }
      })
    }, 
    // 切换一级菜单
    openMenu (item) {
      // console.log(item)
      if (item.children && item.children.length > 0) {
        this.$store.commit('common/SET_CHILD_MENU', item.children)
      } else {
        var child = [{
          href: item.href,
          path: item.path,
          icons: item.icons,
          id: item.id,
          label: item.label,
          permission: item.permission,
          show: item.show,
          children: []
        }]
        this.$store.commit('common/SET_CHILD_MENU', child)
      }
      this.$store.commit('common/SET_ACTIVE_MENU', item.href)
    },
    // 适配头部菜单比较多的时候显示更多选项
    setMenuWidth () {
      this.$nextTick(() => {
        let leftPanelWidth = parseInt(window.getComputedStyle(document.querySelector('.header-left')).width)
        let num = Math.floor(leftPanelWidth / 118)
        this.routesArrLess = []
        this.routesArrMore = []
        // console.log(num, this.len, leftPanelWidth)
        if (num < this.len && leftPanelWidth > 300) {
          for (let i = 0; i < num; i++) {
            this.routesArrLess.push(this.routesArr[i])
          }
          for (let j = 0; j < this.len - num; j++) {
            this.routesArrMore.unshift(this.routesArr[this.len - j - 1])
          }
        } else {
          this.routesArrLess = this.menu.filter((item) => item.show)
        }
      })
    },
    handleInfo () {
      this.dialogInfo = true
    },
    handleReset () {
      this.dialogReset = true
    },
    // 关闭弹窗
    closeDialog (params) {
      if (params == 'info') {
        this.dialogInfo = false
      }
      if (params == 'reset') {
        this.dialogReset = false
      }
    },
    // 确认
    confirmDialog (params) {
      if (params == 'info') {
        this.dialogInfo = false
        this.logout('修改完个人信息，请重新登录！')
      }
      if (params == 'reset') {
        this.dialogReset = false
        this.logout('修改完密码，请重新登录！')
      }
    },
    loadError () {
      this.userInfo.headImgUrl = this.assetsPhoto
    },
    toggleCollapse () {
      this.$store.commit('common/SET_COLLAPSE')
    },
    logout (msg) {
      this.handleConfirm(msg, () => {
        this.$store.dispatch('user/Logout').then(res => {
          this.$router.push('/login')
          this.$store.commit('common/CLEAR_TAG')
        }).catch((err) => {
          this.tip(err.resp_msg, 'error')
        })
      })
    }
  }
}
</script>
<style lang="scss">
.header-top {
  height: 60px;
  overflow: hidden;
  padding: 0 20px 0 50px;
  position: relative;
  box-sizing: border-box;
  .icon-bar{
    position: absolute;
    left: 18px;
    top: 20px;
    font-size: 18px;
  }
}
.header-left {
  line-height: 60px;
  width: 55%;
  float: left;
}

.header-right {
  padding: 13px 0px;
  line-height: 20px;
  width: 45%;
  float: right;
  text-align: right;
}
.top-userImg {
  margin: 0 8px 0 10px;
  padding: 2px;
  width: 30px;
  height: 30px;
  border-radius: 100% !important;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid #eee;
  vertical-align: middle;
}
.user-name {
  cursor: default;
}
.refresh-page{
  font-size: 20px;
  vertical-align: middle !important;
  position: absolute;
  left: 60px;
  top: 18px;
}
</style>
