const agent  = {
  aRelation1: 'Total deposit',
  aRelation2: 'Total withdrawal',
  aRelation3: 'Total profit and loss',
  aRelation4: 'ID of the invitee',
  aRelation5: 'ID of the inviter',
  aRelation6: 'Recharge or not',
  aRelation7: 'Registration time',
  aRelation8: 'First deposit time',
  aRelationcol1: 'ID of the invitee',
  aRelationcol2: 'ID of the inviter',
  aRelationcol3: 'Total deposit',
  aRelationcol4: 'Total withdrawal',
  aRelationcol5: 'Contribution commission',
  aRelationcol6: 'First deposit amount',
  aRelationcol7: 'Total coding',
  aRelationcol8: 'First deposit time',
  aRelationcol9: 'Total profit and loss',
  aRelationcol10: 'Registration IP',
  aRelationcol11: 'Registration time',
  changeBind: 'Change Binding',
  unbing: 'Unbinding',
  ivReport1: 'Invitation Reward',
  ivReport2: 'Total Turnover',
  ivReport3: 'Player ID',
  ivReport4: 'Agent Daily Report',
  ivReportCol1: 'Date',
  ivReportCol2: 'Player ID',
  ivReportCol3: 'Invitation Reward',
  ivReportCol4: 'Personal Recharge',
  ivReportCol5: 'Personal withdrawal',
  ivReportCol6: 'Personal turnover',
  ivReportCol7: 'New subordinates',
  ivReportCol8: 'New effective number of people',
  ivReportCol9: 'New first charge number',
  ivReportCol10: 'New recharge',
  ivReportCol11: 'New turnover',
  rewordCount1: 'Invitation reward',
  rewordCount2: 'Number of platform effective users',
  rewordCount3: 'Average invitation reward',
  rewordCount4: 'Agent daily report',
  rewordCol1: 'Date',
  rewordCol2: 'Invitation reward',
  rewordCol3: 'Number of platform effective users',
  rewordCol4: 'Average invitation reward',
  unbDetail1: 'Player ID',
  unbDetail2: 'Inviter ID',
  unbDetail3: 'Channel ID',
  unbDetail4: 'Salesman ID',
  unbDetail5: 'Registration time',
  unbDetail6: 'Unbinding details',
  unbDetailCol1: 'Player ID',
  unbDetailCol2: 'Inviter ID',
  unbDetailCol3: 'Channel',
  unbDetailCol4: 'Salesman',
  unbDetailCol5: 'Registration time',
  unbDetailCol6: 'Total recharge',
  unbDetailCol7: 'Total withdrawal',
  blogData1: 'Blogger ID',
  blogData2: 'Channel ID',
  blogData3: 'Blogger data monitoring',
  blogDataCol1: 'Creation time',
  blogDataCol2: 'Channel',
  blogDataCol3: 'Salesman',
  blogDataCol4: 'Blogger ID',
  blogDataCol5: 'Alias',
  blogDataCol6: 'Score',
  blogDataCol7: 'Total withdrawal amount',
  blogDataCol8: 'First level number',
  blogDataCol9: 'First level recharge number',
  blogDataCol10: 'First level recharge amount',
  blogDataCol11: 'First level withdrawal amount',
  blogDataCol12: 'Contribution ratio',
  blogDataCol13: 'Profit',
  agentsum1: 'Agent id',
  agentsum2: 'Agent summary',
  agentsum3: 'Score',
  agentsum4: 'Banned',
  agentsumCol1: 'Agent Id',
  agentsumCol2: 'Number of Level 1 agents yesterday',
  agentsumCol3: 'Level 1 recharge yesterday',
  agentsumCol4: 'Number of team members',
  agentsumCol5: 'Number of team recharge members',
  agentsumCol6: 'Team recharge amount',
  agentsumCol7: 'Total withdrawal amount',
  agentsumCol8: 'Number of Level 1 agents',
  agentsumCol9: 'Number of Level 1 recharge members',
  agentsumCol10: 'Level 1 recharge amount',
  agentsumCol11: 'Number of Level 2 agents',
  agentsumCol12: 'Number of second-level rechargers',
  agentsumCol13: 'Amount of second-level recharges',
  agentsumCol14: 'Number of third-level rechargers',
  agentsumCol15: 'Number of third-level rechargers',
  agentsumCol16: 'Amount of third-level recharges',
  changeBindRecord1: '',
  changeBindRecord2: '',
  changeBindRecordCol1: 'Player id',
  changeBindRecordCol2: 'Channel before changing binding',
  changeBindRecordCol3: 'Salesman before changing binding',
  changeBindRecordCol4: 'Superior before changing binding',
  changeBindRecordCol5: 'Channel after changing binding',
  changeBindRecordCol6: 'Salesman after changing binding',
  changeBindRecordCol7: 'Superior after changing binding',
  changeBindRecordCol8: 'Operator',
  changeBindRecordCol9: 'Time',
}
export default agent