import Vue from 'vue'
import VueI18n from 'vue-i18n'
import zh from './config/zh'
import en from './config/en'
import pt from './config/pt'
//elementui 语言配置 start
import el_zh from 'element-ui/lib/locale/lang/zh-CN'
import el_en from 'element-ui/lib/locale/lang/en'
import el_pt from 'element-ui/lib/locale/lang/pt'
import locale from 'element-ui/lib/locale'
var lang = localStorage.getItem('locale')
if(lang && lang == 'zh'){
  locale.use(el_zh)
}else if(lang && lang == 'en'){
  locale.use(el_en)
}else if(lang && lang == 'pt'){
  locale.use(el_pt)
}
//elementui 语言配置 end
Vue.use(VueI18n)
const i18n = new VueI18n({
  locale: localStorage.getItem('locale') || 'zh', // 语言标识
  messages: {
    zh,
    en,
    pt
  }
})
export default i18n
