const vip  = {
  vipList1: '玩家ID',
  vipList2: '用户名',
  vipList3: '手机号',
  vipList4: '归属渠道',
  vipList5: '归属业务员',
  vipList6: '最小充值',
  vipList7: '最大充值',
  vipList8: '最小提款',
  vipList9: '最大提款',
  vipList10: '注册IP',
  vipList11: '所有玩家',
  vipList12: '玩家备注',
  vipList13: '加码',
  vipList14: '更多',
  vipList15: '清除打码',
  vipList16: '进入PG',
  vipList17: '修改密码',
  vipList18: '修改提款密码',
  vipList19: '禁用账户',
  vipList20: '确定要清除打码吗?',
  vipList21: '提示',
  vipList22: '确定',
  vipList23: '取消',
  vipList24: '确定要禁用账户吗?',
  vipListD1: '玩家ID',
  vipListD2: '打码量',
  vipListD3: '加码',
  vipListD4: '玩家账号',
  vipListD5: '玩家备注',
  vipListD6: '密码',
  vipListD7: '修改密码',
  vipListD8: '提现密码',
  vipListD9: '修改提现密码',
  vipListD10: '增加打码',
  vipListD11: '减少打码',
  vipListcol1: '玩家ID',
  vipListcol2: '归属渠道',
  vipListcol3: '归属业务员',
  vipListcol4: '玩家账号',
  vipListcol5: '手机号',
  vipListcol6: '总充值',
  vipListcol7: '总提现',
  vipListcol8: '账户余额',
  vipListcol9: '登录设备',
  vipListcol10: '注册时间',
  vipBet1: '游戏局数',
  vipBet2: '总投注',
  vipBet3: '总中奖',
  vipBet4: '总税收',
  vipBet5: '总输赢',
  vipBet6: '玩家ID',
  vipBet7: '房间',
  vipBet8: '归属渠道ID',
  vipBet9: '归属业务员ID',
  vipBet10: '游戏日志',
  vipBetcol1: '日期',
  vipBetcol2: '房间ID',
  vipBetcol3: '房间名称',
  vipBetcol4: '流水',
  vipBetcol5: '系统盈亏',
  vipBetcol6: '游戏汇报率',
  vipBetcol7: '税收',
  vipBetcol8: '游戏局数',
  acchange1: '玩家ID',
  acchange2: '渠道ID',
  acchange3: '业务员ID',
  acchange4: '金币类型',
  acchange5: '日期',
  acchange6: '金币日志',
  acchange7: '账变类型',
  acchangecol1: '玩家ID',
  acchangecol2: '渠道ID',
  acchangecol3: '房间ID',
  acchangecol4: '初始余额',
  acchangecol5: '类型',
  acchangecol6: '金额',
  acchangecol7: '当前余额',
  acchangecol8: '税收',
  acchangecol9: '时间',
  acchangecol10: '配置名称',
  acchangecol11: '配置英文名称',
  flowList1: '打码明细',
  flowListcol1: '玩家ID',
  flowListcol2: '打码额度',
  flowListcol3: '当前打码量',
  flowListcol4: '需要的打码量',
  flowListcol5: '完成度',
  flowListcol6: '状态',
  flowListcol7: '类型',
  flowListcol8: '打码增加时间',
  flowListcol9: '打码完成时间',
  verc1: '会员手机号',
  verc2: '状态',
  verc3: '发放时间',
  verc4: '手机验证码',
  verc5: '邮箱验证码',
  vercCol1: '手机号',
  vercCol2: '手机验证码',
  vercCol3: '发放时间',
  vercCol4: '成功时间',
  vercCol5: 'IP',
  vercCol6: '状态',
  vipListDetailEdit1: '方式',
  vipListDetailEdit2: '默认',
  vipListDetailEdit3: '关闭',
  vipListDetailEdit4: '开启',
  vipListDetailEdit5: '设置个人',
  vipListDetailEdit6: '设置团队',
  vipListDetailEdit7: '取消',
  vipListDetailEdit8: '新增',
  vipListDetailEdit9: '假pg控制',
  vipListEdit1: '邀请人数',
  vipListEdit2: '掉绑比例(%)',
  vipListEdit3: '累计封顶人数',
  vipListEdit4: '邀请人数',
  vipListEdit5: '时间',
  vipListEdit6: '备注',
  vipListEdit7: '操作人',
  vipListEdit8: '操作记录',
  vipListEdit9: '层级',
  vipListEdit10: '比例',
  vipListEdit11: '充值类型',
  vipListEdit12: '打码',
  vipListEdit13: '充值分成',
  vipListDetail1: '基本信息',
  vipListDetail2: '账号状态',
  vipListDetail3: '玩家账号',
  vipListDetail4: '玩家ID',
  vipListDetail5: '手机号',
  vipListDetail6: '备注',
  vipListDetail7: '操作记录',
  vipListDetail8: '账号状态',
  vipListDetail9: '上级ID',
  vipListDetail10: '归属业务员',
  vipListDetail11: '归属渠道',
  vipListDetail12: '账户信息',
  vipListDetail13: '余额(可提/冻结)',
  vipListDetail14: '总充值金额',
  vipListDetail15: '总提款金额',
  vipListDetail16: '充值次数',
  vipListDetail17: '提现次数',
  vipListDetail18: '总投注',
  vipListDetail19: '总盈亏',
  vipListDetail20: '历史佣金',
  vipListDetail21: '后台上分',
  vipListDetail22: '已完成打码量',
  vipListDetail23: '未完成打码量',
  vipListDetail24: '总打码',
  vipListDetail25: '注册登录信息',
  vipListDetail26: '注册IP',
  vipListDetail27: '注册时间',
  vipListDetail28: '最后登录时间',
  vipListDetail29: '最后登录IP',
  vipListDetail30: '登录设备',
  vipListDetail31: '控制开关',
  vipListDetail32: '游戏开关',
  vipListDetail33: '提现开关',
  vipListDetail34: '抽税开关',
  vipListDetail35: '自动出款开关',
  vipListDetail36: '中奖加码开关',
  vipListDetail37: '是否博主',
  vipListDetail38: '佣金开关',
  vipListDetail39: '假pg控制',
  vipListDetail40: '掉绑控制',
  vipListDetail41: '充值分成',
  vipListDetail42: '充值记录',
  vipListDetail43: '提现记录',
  vipListDetail44: '下级汇总',
  vipListDetail45: '游戏日志',
  vipListDetail46: '金币日志',
  vipListDetail47: '同IP查询',
  vipListDetail48: '登录日志',
  vipListDetail49: '推广记录',
  vipListDetail50: '请选择日期',
  vipListDetailCol1: '订单状态',
  vipListDetailCol2: '订单号',
  vipListDetailCol3: '订单时间',
  vipListDetailCol4: '支付时间',
  vipListDetailCol5: '充值金额',
  vipListDetailCol6: '到账金额',
  vipListDetailCol7: '通道名称',
  vipListDetail1Col1: '订单号',
  vipListDetail1Col2: '订单状态',
  vipListDetail1Col3: '提款金额',
  vipListDetail1Col4: '到账金额',
  vipListDetail1Col5: '手续费',
  vipListDetail1Col6: '操作人员',
  vipListDetail1Col7: '申请时间',
  vipListDetail1Col8: '审核时间',
  vipListDetail3Col1: '日期',
  vipListDetail3Col2: '房间ID',
  vipListDetail3Col3: '房间名',
  vipListDetail3Col4: '流水',
  vipListDetail3Col5: '盈亏',
  vipListDetail3Col6: '游戏回报率',
  vipListDetail3Col7: '税收',
  vipListDetail3Col8: '游戏局数',
  vipListDetail5Col1: '玩家ID',
  vipListDetail5Col2: '玩家账号',
  vipListDetail5Col3: '注册IP',
  vipListDetail5Col4: '注册时间',
  vipListDetail5Col5: '登录IP',
  vipListDetail5Col6: '登录时间',
  vipListDetail6Col1: '登录设备',
  vipListDetail6Col2: '登录IP',
  vipListDetail6Col3: '登录时间',
  vipListDetail7Col1: '受请人ID',
  vipListDetail7Col2: '邀请人ID',
  vipListDetail7Col3: '总充值',
  vipListDetail7Col4: '总提现',
  vipListDetail7Col5: '首充金额',
  vipListDetail7Col6: '首充时间',
  vipListDetail7Col7: '总盈亏',
  vipListDetail7Col8: '注册IP',
  vipListDetail7Col9: '注册时间',
}
export default vip