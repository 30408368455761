const payment  = {
  payment1: 'canal de payment',
  payment2: 'Pedido adicional',
  payment3: 'Por favor, insira a senha para verificação',
  payment4: 'Verificação de senha',
  payment5: 'OK',
  payment6: 'Cancelar',
  payment7: 'A senha não pode ficar vazia',
  paymentCol1: 'Nome do canal',
  paymentCol2: 'Codificação do canal',
  paymentCol3: 'Valor mínimo',
  paymentCol4: 'Valor máximo',
  paymentCol5: 'valor rápido',
  paymentCol6: 'nome de exibição da recepção',
  paymentCol7: 'status',
  paymentCol8: 'taxa de câmbio',
  order1: 'Pedido total',
  order2: 'Pedido pago',
  order3: 'Valor total do pedido',
  order4: 'Valor total pago',
  order5: 'taxa de sucesso',
  order6: 'Número total de jogadores',
  order7: 'Número de pessoas pagando',
  order8: 'ID do jogador',
  order9: 'ID do convidado',
  order10: 'Número do pedido',
  order11: 'Status do pedido',
  order12: 'Valor do pedido',
  order13: 'Tempo de aplicação',
  order14: 'Registro do jogo',
  orderD1: 'nome do canal',
  orderD2: 'classificar',
  orderD3: 'Valor máximo',
  orderD4: 'Valor mínimo',
  orderD5: 'quantia rápida',
  orderD6: 'Nome de exibição da recepção',
  orderD7: 'Taxa de câmbio',
  orderD8: 'status',
  orderD9: 'chave',
  orderD10: 'app_key',
  orderD11: 'endereço da API',
  orderD12: 'Endereço de retorno de chamada (notify_url)',
  orderD13: 'Número do comerciante (mchid)',
  orderD14: 'Número da organização (app_id)',
  orderD15: 'Codificação de canal (pay_code)',
  orderD16: 'Código do país (código_país)',
  orderD17: 'Código da moeda (currency_code)',
  orderD18: 'Tipo de transação (tipo)',
  orderCol1: 'Status do pedido',
  orderCol2: 'Número do pedido',
  orderCol3: 'Hora do pedido',
  orderCol4: 'Prazo de pagamento',
  orderCol5: 'ID do jogador',
  orderCol6: 'Quantidade de recarga',
  orderCol7: 'Valor recebido',
  orderCol8: 'Nome do canal',
  paymenthistoryEdit1: 'Título',
  paymenthistoryEdit2: 'Conteúdo',
  paymenthistoryEdit3: 'Se ativar',
  paymenthistoryEdit4: 'Por favor, insira um título',
  paymenthistoryEdit5: 'Por favor, insira o conteúdo',
}
export default payment