import Vue from 'vue'
import Router from 'vue-router'
import Layout from '@/themes/cvue/layout/index'
import i18n from '@/i18n'

Vue.use(Router)
// 路由实例
const router = new Router({
  scrollBehavior (to, from, savedPosition) { // 路由滚动行为
    if (savedPosition) {
      return savedPosition
    } else {
      const position = {}
      if (to.hash) {
        position.selector = to.hash
      }
      if (to.matched.some(m => m.meta.scrollToTop)) {
        position.x = 0
        position.y = 0
      }
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(position)
        }, 500)
      })
    }
  },
  routes: [
    {
      path: '/home',
      name: 'Home',
      component: () => import(/* webpackChunkName: "Home" */'../themes/cvue/layout/index'),
      children: [
        {
          path: 'index',
          name: i18n.t('menu.index'),
          component: () => import(/* webpackChunkName: "Home" */'../themes/cvue/views/home/index')
        },
        {
          path: 'statisticsDailyList',
          name: i18n.t('menu.statisticsDailyList'),
          component: () => import(/* webpackChunkName: "Home" */'../themes/cvue/views/report/statisticsDailyList')
        },
        {
          path: 'statisticsGameSettle',
          name: i18n.t('menu.statisticsGameSettle'),
          component: () => import(/* webpackChunkName: "Home" */'../themes/cvue/views/report/statisticsGameSettle')
        },
        {
          path: 'statisticsMerchantSettle',
          name: i18n.t('menu.statisticsMerchantSettle'),
          component: () => import(/* webpackChunkName: "Home" */'../themes/cvue/views/report/statisticsMerchantSettle')
        },
        {
          path: 'agentRewardStatistics',
          name: i18n.t('menu.agentRewardStatistics'),
          component: () => import(/* webpackChunkName: "Home" */'../themes/cvue/views/report/agentRewardStatistics')
        },
        {
          path: 'statisticsRemainList',
          name: i18n.t('menu.statisticsRemainList'),
          component: () => import(/* webpackChunkName: "Home" */'../themes/cvue/views/report/statisticsRemainList')
        },
        {
          path: 'gameEcosystemMonitoring',
          name: i18n.t('menu.gameEcosystemMonitoring'),
          component: () => import(/* webpackChunkName: "Home" */'../themes/cvue/views/report/gameEcosystemMonitoring')
        },
        {
          path: 'vipList',
          name: i18n.t('menu.vipList'),
          component: () => import(/* webpackChunkName: "Home" */'../themes/cvue/views/vip/vipList')
        },
        {
          path: 'vipBet',
          name: i18n.t('menu.vipBet'),
          component: () => import(/* webpackChunkName: "Home" */'../themes/cvue/views/vip/vipBet')
        },
        {
          path: 'accountChange',
          name: i18n.t('menu.accountChange'),
          component: () => import(/* webpackChunkName: "Home" */'../themes/cvue/views/vip/accountChange')
        },
        {
          path: 'flowList',
          name: i18n.t('menu.flowList'),
          component: () => import(/* webpackChunkName: "Home" */'../themes/cvue/views/vip/flowList')
        },
        {
          path: 'verc',
          name: i18n.t('menu.verc'),
          component: () => import(/* webpackChunkName: "Home" */'../themes/cvue/views/vip/verc')
        },
        {
          path: 'paymentChannelManage',
          name: i18n.t('menu.paymentChannelManage'),
          component: () => import(/* webpackChunkName: "Home" */'../themes/cvue/views/paymentManage/paymentChannelManage')
        },
        {
          path: 'paymenthistory',
          name: i18n.t('menu.paymenthistory'),
          component: () => import(/* webpackChunkName: "Home" */'../themes/cvue/views/paymentManage/paymenthistory')
        },
        {
          path: 'spendChannelManage',
          name: i18n.t('menu.spendChannelManage'),
          component: () => import(/* webpackChunkName: "Home" */'../themes/cvue/views/spendManage/spendChannelManage')
        },
        {
          path: 'paymentReview',
          name: i18n.t('menu.paymentReview'),
          component: () => import(/* webpackChunkName: "Home" */'../themes/cvue/views/spendManage/paymentReview')
        },
        {
          path: 'pendingList',
          name: i18n.t('menu.pendingList'),
          component: () => import(/* webpackChunkName: "Home" */'../themes/cvue/views/spendManage/pendingList')
        },
        {
          path: 'withdrawalManage',
          name: i18n.t('menu.withdrawalManage'),
          component: () => import(/* webpackChunkName: "Home" */'../themes/cvue/views/spendManage/withdrawalManage')
        },
        {
          path: 'bankManage',
          name: i18n.t('menu.bankManage'),
          component: () => import(/* webpackChunkName: "Home" */'../themes/cvue/views/spendManage/bankManage')
        },
        {
          path: 'popupImg',
          name: i18n.t('menu.popupImg'),
          component: () => import(/* webpackChunkName: "Home" */'../themes/cvue/views/activity/popupImg')
        },
        {
          path: 'advertising',
          name: i18n.t('menu.advertising'),
          component: () => import(/* webpackChunkName: "Home" */'../themes/cvue/views/activity/advertising')
        },
        {
          path: 'suspension',
          name: i18n.t('menu.suspension'),
          component: () => import(/* webpackChunkName: "Home" */'../themes/cvue/views/activity/suspension')
        },
        {
          path: 'weeklyActivityData',
          name: i18n.t('menu.weeklyActivityData'),
          component: () => import(/* webpackChunkName: "Home" */'../themes/cvue/views/activity/weeklyActivityData')
        },
        {
          path: 'agentRelation',
          name: i18n.t('menu.agentRelation'),
          component: () => import(/* webpackChunkName: "Home" */'../themes/cvue/views/agent/agentRelation')
        },
        {
          path: 'invitationReport',
          name: i18n.t('menu.invitationReport'),
          component: () => import(/* webpackChunkName: "Home" */'../themes/cvue/views/agent/invitationReport')
        },
        {
          path: 'agentRewardCount',
          name: i18n.t('menu.agentRewardCount'),
          component: () => import(/* webpackChunkName: "Home" */'../themes/cvue/views/agent/agentRewardCount')
        },
        {
          path: 'unbindingDetails',
          name: i18n.t('menu.unbindingDetails'),
          component: () => import(/* webpackChunkName: "Home" */'../themes/cvue/views/agent/unbindingDetails')
        },
        {
          path: 'bloggerDataMonitoring',
          name: i18n.t('menu.bloggerDataMonitoring'),
          component: () => import(/* webpackChunkName: "Home" */'../themes/cvue/views/agent/bloggerDataMonitoring')
        },
        {
          path: 'agentSummary',
          name: i18n.t('menu.agentSummary'),
          component: () => import(/* webpackChunkName: "Home" */'../themes/cvue/views/agent/agentSummary')
        },
        {
          path: 'changeBindRecord',
          name: i18n.t('menu.changeBindRecord'),
          component: () => import(/* webpackChunkName: "Home" */'../themes/cvue/views/agent/changeBindRecord')
        },
        {
          path: 'activityConfig',
          name: i18n.t('menu.activityConfig'),
          component: () => import(/* webpackChunkName: "Home" */'../themes/cvue/views/site/activityConfig')
        },
        {
          path: 'systemConfig',
          name: i18n.t('menu.systemConfig'),
          component: () => import(/* webpackChunkName: "Home" */'../themes/cvue/views/site/systemConfig')
        },
        {
          path: 'vipConfig',
          name: i18n.t('menu.vipConfig'),
          component: () => import(/* webpackChunkName: "Home" */'../themes/cvue/views/site/vipConfig')
        },
        {
          path: 'gameConfig',
          name: i18n.t('menu.gameConfig'),
          component: () => import(/* webpackChunkName: "Home" */'../themes/cvue/views/site/gameConfig')
        },
        {
          path: 'pgConfigList',
          name: i18n.t('menu.pgConfigList'),
          component: () => import(/* webpackChunkName: "Home" */'../themes/cvue/views/site/pgConfigList')
        },
        {
          path: 'buyInAmountConfig',
          name: i18n.t('menu.buyInAmountConfig'),
          component: () => import(/* webpackChunkName: "Home" */'../themes/cvue/views/site/buyInAmountConfig')
        },
        {
          path: 'venueManage',
          name: i18n.t('menu.venueManage'),
          component: () => import(/* webpackChunkName: "Home" */'../themes/cvue/views/site/venueManage')
        },
        {
          path: 'channelList',
          name: i18n.t('menu.channelList'),
          component: () => import(/* webpackChunkName: "Home" */'../themes/cvue/views/channel/channelList')
        },
        {
          path: 'quoteManage',
          name: i18n.t('menu.quoteManage'),
          component: () => import(/* webpackChunkName: "Home" */'../themes/cvue/views/channel/quoteManage')
        },
        {
          path: 'salesmanReportBusiness',
          name: i18n.t('menu.salesmanReportBusiness'),
          component: () => import(/* webpackChunkName: "Home" */'../themes/cvue/views/channel/salesmanReportBusiness')
        },
        {
          path: 'systemAccount',
          name: i18n.t('menu.systemAccount'),
          component: () => import(/* webpackChunkName: "Home" */'../themes/cvue/views/permission/systemAccount')
        },
        {
          path: 'roleManage',
          name: i18n.t('menu.roleManage'),
          component: () => import(/* webpackChunkName: "Home" */'../themes/cvue/views/permission/roleManage')
        },
        {
          path: 'whitelist',
          name: i18n.t('menu.whitelist'),
          component: () => import(/* webpackChunkName: "Home" */'../themes/cvue/views/permission/whitelist')
        },
        {
          path: 'backendLogs',
          name: i18n.t('menu.backendLogs'),
          component: () => import(/* webpackChunkName: "Home" */'../themes/cvue/views/permission/backendLogs')
        },
        {
          path: 'upDownRecord',
          name: i18n.t('menu.upDownRecord'),
          component: () => import(/* webpackChunkName: "Home" */'../themes/cvue/views/service/upDownRecord')
        },
        {
          path: 'upDownAudit',
          name: i18n.t('menu.upDownAudit'),
          component: () => import(/* webpackChunkName: "Home" */'../themes/cvue/views/service/upDownAudit')
        },
        {
          path: 'upDownRecordBlog',
          name: '博主上分',
          component: () => import(/* webpackChunkName: "Home" */'../themes/cvue/views/service/upDownRecordBlog')
        },
        {
          path: 'upAudit',
          name: '上分审核',
          component: () => import(/* webpackChunkName: "Home" */'../themes/cvue/views/service/upAudit')
        },
        {
          path: 'testManagement',
          name: '测试号管理',
          component: () => import(/* webpackChunkName: "Home" */'../themes/cvue/views/service/testManagement')
        },
        {
          path: 'upDownRecordChannel',
          name: i18n.t('menu.upDownRecordChannel'),
          component: () => import(/* webpackChunkName: "Home" */'../themes/cvue/views/service/upDownRecordChannel')
        },
        {
          path: 'content',
          name: i18n.t('menu.content'),
          component: () => import(/* webpackChunkName: "Home" */'../themes/cvue/views/service/content')
        },
        {
          path: 'linkConfig',
          name: i18n.t('menu.linkConfig'),
          component: () => import(/* webpackChunkName: "Home" */'../themes/cvue/views/service/linkConfig')
        },
        {
          path: 'serviceConfig',
          name: i18n.t('menu.serviceConfig'),
          component: () => import(/* webpackChunkName: "Home" */'../themes/cvue/views/service/serviceConfig')
        },
        {
          path: 'scrollMessage',
          name: i18n.t('menu.scrollMessage'),
          component: () => import(/* webpackChunkName: "Home" */'../themes/cvue/views/service/scrollMessage')
        },
        {
          path: 'unbindControl',
          name: i18n.t('menu.unbindControl'),
          component: () => import(/* webpackChunkName: "Home" */'../themes/cvue/views/service/unbindControl')
        },
        {
          path: 'orgList',
          name: i18n.t('menu.orgList'),
          component: () => import(/* webpackChunkName: "Home" */'../themes/cvue/views/org/orgList')
        },
        {
          path: 'salesmanList',
          name: '业务员列表',
          component: () => import(/* webpackChunkName: "Home" */'../themes/cvue/views/salesman/salesmanList')
        },
        {
          path: 'salesmanDailyReport',
          name: '业务员报表',
          component: () => import(/* webpackChunkName: "Home" */'../themes/cvue/views/salesman/salesmanDailyReport')
        },
        {
          path: 'salesmanReport',
          name: '业务员对账报表',
          component: () => import(/* webpackChunkName: "Home" */'../themes/cvue/views/salesman/salesmanReport')
        }
      ]
    },
    {
      path: '/',
      redirect: '/login'
    },
    {
      path: '/login',
      name: 'Login',
      component: () => import(/* webpackChunkName: "public" */'../themes/login/login')
    },
    {
      path: '*',
      redirect: '/error/404'
    },
    {
      path: '/error/403',
      name: 'Error403',
      component: () => import(/* webpackChunkName: "public" */'../themes/error/403')
    },
    {
      path: '/error/404',
      name: 'Error404',
      component: () => import(/* webpackChunkName: "public" */'../themes/error/404')
    }
  ]
})
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
export default router
