// console.log(process.env, process.env.NODE_ENV)
// export const baseServer = 'http://60.204.203.157:9091'
let key = 'ZyMERS0sVx4C' // ZyMERS0sVx4C
let baseUrl = ''
if(process.env.NODE_ENV == 'production'){
    baseUrl = ''
} else if (process.env.NODE_ENV == 'development'){
    baseUrl = 'http://60.204.203.157:9091'
    // baseUrl = 'https://admin.rzzy.vip'
} else if (process.env.NODE_ENV == 'test'){
    baseUrl = 'http://60.204.203.157:9091'
}
// if(baseUrl == ''){
//     let dArr =  document.domain.split('.')
//     var sliLen = dArr.length-1 >= 2 ? dArr.length-1 : 2
//     baseUrl = location.protocol + '//client.' + dArr.slice(-sliLen).join('.')
// }
// console.log(baseUrl)
export { baseUrl, key }