import i18n from '@/i18n'
let menu = {
  admin: [
    {
      id: 1,
      label: i18n.t('menu.index'),
      path: '/home',
      href: '/home',
      icons: 'fa fa-home',
      permission: ['view', 'add', 'delete', 'edit'],
      show: true,
      children: [
        {
          id: 21,
          label: i18n.t('menu.index'),
          href: '/home/index',
          path: '/home/index',
          icons: 'fa fa-home',
          permission: ['view', 'add', 'delete', 'edit', 'resetPassword', 'history'],
          show: true,
          children: []
        },
        {
          id: 21,
          label: i18n.t('menu.report'),
          href: '/home/report',
          path: '/home/report',
          icons: 'fa fa-bar-chart',
          permission: ['view', 'add', 'delete', 'edit', 'resetPassword'],
          show: true,
          children: [
            {
              id: 21,
              label: i18n.t('menu.statisticsDailyList'),
              href: '/home/statisticsDailyList',
              path: '/home/statisticsDailyList',
              icons: 'fa fa-file-text',
              permission: ['view', 'add', 'delete', 'edit', 'resetPassword'],
              show: true,
              children: []
            },
            {
              id: 21,
              label: i18n.t('menu.statisticsGameSettle'),
              href: '/home/statisticsGameSettle',
              path: '/home/statisticsGameSettle',
              icons: 'fa fa-file-text',
              permission: ['view', 'add', 'delete', 'edit', 'resetPassword'],
              show: true,
              children: []
            },
            {
              id: 21,
              label: i18n.t('menu.statisticsMerchantSettle'),
              href: '/home/statisticsMerchantSettle',
              path: '/home/statisticsMerchantSettle',
              icons: 'fa fa-file-text',
              permission: ['view', 'add', 'delete', 'edit', 'resetPassword'],
              show: true,
              children: []
            },
            {
              id: 21,
              label: i18n.t('menu.agentRewardStatistics'),
              href: '/home/agentRewardStatistics',
              path: '/home/agentRewardStatistics',
              icons: 'fa fa-file-text',
              permission: ['view', 'add', 'delete', 'edit', 'resetPassword'],
              show: true,
              children: []
            },
            {
              id: 21,
              label: i18n.t('menu.statisticsRemainList'),
              href: '/home/statisticsRemainList',
              path: '/home/statisticsRemainList',
              icons: 'fa fa-file-text',
              permission: ['view', 'add', 'delete', 'edit', 'resetPassword'],
              show: true,
              children: []
            },
            {
              id: 21,
              label: i18n.t('menu.gameEcosystemMonitoring'),
              href: '/home/gameEcosystemMonitoring',
              path: '/home/gameEcosystemMonitoring',
              icons: 'fa fa-file-text',
              permission: ['view', 'add', 'delete', 'edit', 'resetPassword'],
              show: true,
              children: []
            }
          ]
        },
        {
          id: 21,
          label: i18n.t('menu.vip'),
          href: '/home/vip',
          path: '/home/vip',
          icons: 'fa fa-user',
          permission: ['view', 'add', 'delete', 'edit', 'resetPassword'],
          show: true,
          children: [
            {
              id: 21,
              label: i18n.t('menu.vipList'),
              href: '/home/vipList',
              path: '/home/vipList',
              icons: 'fa fa-file-text',
              permission: ['view', 'add', 'delete', 'edit', 'resetPassword'],
              show: true,
              children: []
            },
            {
              id: 21,
              label: i18n.t('menu.vipBet'),
              href: '/home/vipBet',
              path: '/home/vipBet',
              icons: 'fa fa-file-text',
              permission: ['view', 'add', 'delete', 'edit', 'resetPassword'],
              show: true,
              children: []
            },
            {
              id: 21,
              label: i18n.t('menu.accountChange'),
              href: '/home/accountChange',
              path: '/home/accountChange',
              icons: 'fa fa-file-text',
              permission: ['view', 'add', 'delete', 'edit', 'resetPassword'],
              show: true,
              children: []
            },
            {
              id: 21,
              label: i18n.t('menu.flowList'),
              href: '/home/flowList',
              path: '/home/flowList',
              icons: 'fa fa-file-text',
              permission: ['view', 'add', 'delete', 'edit', 'resetPassword'],
              show: true,
              children: []
            },
            // {
            //   id: 21,
            //   label: i18n.t('menu.verc'),
            //   href: '/home/verc',
            //   path: '/home/verc',
            //   icons: 'fa fa-file-text',
            //   permission: ['view', 'add', 'delete', 'edit', 'resetPassword'],
            //   show: true,
            //   children: []
            // }
          ]
        },
        {
          id: 21,
          label: i18n.t('menu.paymentManage'),
          href: '/home/paymentManage',
          path: '/home/paymentManage',
          icons: 'fa fa-paypal',
          permission: ['view', 'add', 'delete', 'edit', 'resetPassword'],
          show: true,
          children: [
            {
              id: 21,
              label: i18n.t('menu.paymentChannelManage'),
              href: '/home/paymentChannelManage',
              path: '/home/paymentChannelManage',
              icons: 'fa fa-file-text',
              permission: ['view', 'add', 'delete', 'edit', 'resetPassword'],
              show: true,
              children: []
            },
            {
              id: 21,
              label: i18n.t('menu.paymenthistory'),
              href: '/home/paymenthistory',
              path: '/home/paymenthistory',
              icons: 'fa fa-file-text',
              permission: ['view', 'add', 'delete', 'edit', 'resetPassword'],
              show: true,
              children: []
            }
          ]
        },
        {
          id: 21,
          label: i18n.t('menu.spendManage'),
          href: '/home/spendManage',
          path: '/home/spendManage',
          icons: 'fa fa-gratipay',
          permission: ['view', 'add', 'delete', 'edit', 'resetPassword'],
          show: true,
          children: [
            {
              id: 21,
              label: i18n.t('menu.spendChannelManage'),
              href: '/home/spendChannelManage',
              path: '/home/spendChannelManage',
              icons: 'fa fa-file-text',
              permission: ['view', 'add', 'delete', 'edit', 'resetPassword'],
              show: true,
              children: []
            },
            {
              id: 21,
              label: i18n.t('menu.paymentReview'),
              href: '/home/paymentReview',
              path: '/home/paymentReview',
              icons: 'fa fa-file-text',
              permission: ['view', 'add', 'delete', 'edit', 'resetPassword'],
              show: true,
              children: []
            },
            {
              id: 21,
              label: i18n.t('menu.pendingList'),
              href: '/home/pendingList',
              path: '/home/pendingList',
              icons: 'fa fa-file-text',
              permission: ['view', 'add', 'delete', 'edit', 'resetPassword'],
              show: true,
              children: []
            },
            {
              id: 21,
              label: i18n.t('menu.withdrawalManage'),
              href: '/home/withdrawalManage',
              path: '/home/withdrawalManage',
              icons: 'fa fa-file-text',
              permission: ['view', 'add', 'delete', 'edit', 'resetPassword'],
              show: true,
              children: []
            },
            {
              id: 21,
              label: i18n.t('menu.bankManage'),
              href: '/home/bankManage',
              path: '/home/bankManage',
              icons: 'fa fa-file-text',
              permission: ['view', 'add', 'delete', 'edit', 'resetPassword'],
              show: true,
              children: []
            }
          ]
        },
        {
          id: 21,
          label: i18n.t('menu.activity'),
          href: '/home/activity',
          path: '/home/activity',
          icons: 'fa fa-archive',
          permission: ['view', 'add', 'delete', 'edit', 'resetPassword'],
          show: true,
          children: [
            {
              id: 21,
              label: i18n.t('menu.popupImg'),
              href: '/home/popupImg',
              path: '/home/popupImg',
              icons: 'fa fa-file-text',
              permission: ['view', 'add', 'delete', 'edit', 'resetPassword'],
              show: true,
              children: []
            },
            {
              id: 21,
              label: i18n.t('menu.advertising'),
              href: '/home/advertising',
              path: '/home/advertising',
              icons: 'fa fa-file-text',
              permission: ['view', 'add', 'delete', 'edit', 'resetPassword'],
              show: true,
              children: []
            },
            {
              id: 21,
              label: i18n.t('menu.suspension'),
              href: '/home/suspension',
              path: '/home/suspension',
              icons: 'fa fa-file-text',
              permission: ['view', 'add', 'delete', 'edit', 'resetPassword'],
              show: true,
              children: []
            },
            {
              id: 21,
              label: i18n.t('menu.weeklyActivityData'),
              href: '/home/weeklyActivityData',
              path: '/home/weeklyActivityData',
              icons: 'fa fa-file-text',
              permission: ['view', 'add', 'delete', 'edit', 'resetPassword'],
              show: true,
              children: []
            }
          ]
        },
        {
          id: 21,
          label: i18n.t('menu.agent'),
          href: '/home/agent',
          path: '/home/agent',
          icons: 'fa fa-window-restore',
          permission: ['view', 'add', 'delete', 'edit', 'resetPassword'],
          show: true,
          children: [
            {
              id: 21,
              label: i18n.t('menu.agentRelation'),
              href: '/home/agentRelation',
              path: '/home/agentRelation',
              icons: 'fa fa-file-text',
              permission: ['view', 'add', 'delete', 'edit', 'resetPassword'],
              show: true,
              children: []
            },
            {
              id: 21,
              label: i18n.t('menu.invitationReport'),
              href: '/home/invitationReport',
              path: '/home/invitationReport',
              icons: 'fa fa-file-text',
              permission: ['view', 'add', 'delete', 'edit', 'resetPassword'],
              show: true,
              children: []
            },
            {
              id: 21,
              label: i18n.t('menu.agentRewardCount'),
              href: '/home/agentRewardCount',
              path: '/home/agentRewardCount',
              icons: 'fa fa-file-text',
              permission: ['view', 'add', 'delete', 'edit', 'resetPassword'],
              show: true,
              children: []
            },
            {
              id: 21,
              label: i18n.t('menu.unbindingDetails'),
              href: '/home/unbindingDetails',
              path: '/home/unbindingDetails',
              icons: 'fa fa-file-text',
              permission: ['view', 'add', 'delete', 'edit', 'resetPassword'],
              show: true,
              children: []
            },
            {
              id: 21,
              label: i18n.t('menu.bloggerDataMonitoring'),
              href: '/home/bloggerDataMonitoring',
              path: '/home/bloggerDataMonitoring',
              icons: 'fa fa-file-text',
              permission: ['view', 'add', 'delete', 'edit', 'resetPassword'],
              show: true,
              children: []
            },
            {
              id: 21,
              label: i18n.t('menu.agentSummary'),
              href: '/home/agentSummary',
              path: '/home/agentSummary',
              icons: 'fa fa-file-text',
              permission: ['view', 'add', 'delete', 'edit', 'resetPassword'],
              show: true,
              children: []
            },
            {
              id: 21,
              label: i18n.t('menu.changeBindRecord'),
              href: '/home/changeBindRecord',
              path: '/home/changeBindRecord',
              icons: 'fa fa-file-text',
              permission: ['view', 'add', 'delete', 'edit', 'resetPassword'],
              show: true,
              children: []
            }
          ]
        },
        {
          id: 21,
          label: i18n.t('menu.site'),
          href: '/home/site',
          path: '/home/site',
          icons: 'fa fa-gear',
          permission: ['view', 'add', 'delete', 'edit', 'resetPassword'],
          show: true,
          children: [
            {
              id: 21,
              label: i18n.t('menu.activityConfig'),
              href: '/home/activityConfig',
              path: '/home/activityConfig',
              icons: 'fa fa-file-text',
              permission: ['view', 'add', 'delete', 'edit', 'resetPassword'],
              show: true,
              children: []
            },
            {
              id: 21,
              label: i18n.t('menu.systemConfig'),
              href: '/home/systemConfig',
              path: '/home/systemConfig',
              icons: 'fa fa-file-text',
              permission: ['view', 'add', 'delete', 'edit', 'resetPassword'],
              show: true,
              children: []
            },
            {
              id: 21,
              label: i18n.t('menu.vipConfig'),
              href: '/home/vipConfig',
              path: '/home/vipConfig',
              icons: 'fa fa-file-text',
              permission: ['view', 'add', 'delete', 'edit', 'resetPassword'],
              show: true,
              children: []
            },
            {
              id: 21,
              label:  i18n.t('menu.gameConfig'),
              href: '/home/gameConfig',
              path: '/home/gameConfig',
              icons: 'fa fa-file-text',
              permission: ['view', 'add', 'delete', 'edit', 'resetPassword'],
              show: true,
              children: []
            },
            // {
            //   id: 21,
            //   label: i18n.t('menu.pgConfigList'),
            //   href: '/home/pgConfigList',
            //   path: '/home/pgConfigList',
            //   icons: 'fa fa-file-text',
            //   permission: ['view', 'add', 'delete', 'edit', 'resetPassword'],
            //   show: true,
            //   children: []
            // },
            {
              id: 21,
              label: i18n.t('menu.buyInAmountConfig'),
              href: '/home/buyInAmountConfig',
              path: '/home/buyInAmountConfig',
              icons: 'fa fa-file-text',
              permission: ['view', 'add', 'delete', 'edit', 'resetPassword'],
              show: true,
              children: []
            },
            {
              id: 21,
              label: i18n.t('menu.venueManage'),
              href: '/home/venueManage',
              path: '/home/venueManage',
              icons: 'fa fa-file-text',
              permission: ['view', 'add', 'delete', 'edit', 'resetPassword'],
              show: true,
              children: []
            }
          ]
        },
        {
          id: 21,
          label: i18n.t('menu.channel'),
          href: '/home/channel',
          path: '/home/channel',
          icons: 'fa fa-snowflake-o',
          permission: ['view', 'add', 'delete', 'edit', 'resetPassword'],
          show: true,
          children: [
            {
              id: 21,
              label: i18n.t('menu.channelList'),
              href: '/home/channelList',
              path: '/home/channelList',
              icons: 'fa fa-file-text',
              permission: ['view', 'add', 'delete', 'edit', 'resetPassword'],
              show: true,
              children: []
            },
            {
              id: 21,
              label: i18n.t('menu.quoteManage'),
              href: '/home/quoteManage',
              path: '/home/quoteManage',
              icons: 'fa fa-file-text',
              permission: ['view', 'add', 'delete', 'edit', 'resetPassword'],
              show: true,
              children: []
            },
            {
              id: 21,
              label: i18n.t('menu.salesmanReportBusiness'),
              href: '/home/salesmanReportBusiness',
              path: '/home/salesmanReportBusiness',
              icons: 'fa fa-file-text',
              permission: ['view', 'add', 'delete', 'edit', 'resetPassword'],
              show: true,
              children: []
            }
          ]
        },
        {
          id: 21,
          label: i18n.t('menu.permission'),
          href: '/home/permission',
          path: '/home/permission',
          icons: 'fa fa-id-card-o',
          permission: ['view', 'add', 'delete', 'edit', 'resetPassword'],
          show: true,
          children: [
            {
              id: 21,
              label: i18n.t('menu.systemAccount'),
              href: '/home/systemAccount',
              path: '/home/systemAccount',
              icons: 'fa fa-file-text',
              permission: ['view', 'add', 'delete', 'edit', 'resetPassword'],
              show: true,
              children: []
            },
            {
              id: 21,
              label: i18n.t('menu.roleManage'),
              href: '/home/roleManage',
              path: '/home/roleManage',
              icons: 'fa fa-file-text',
              permission: ['view', 'add', 'delete', 'edit', 'resetPassword'],
              show: true,
              children: []
            },
            {
              id: 21,
              label: i18n.t('menu.whitelist'),
              href: '/home/whitelist',
              path: '/home/whitelist',
              icons: 'fa fa-file-text',
              permission: ['view', 'add', 'delete', 'edit', 'resetPassword'],
              show: true,
              children: []
            },
            {
              id: 21,
              label: i18n.t('menu.backendLogs'),
              href: '/home/backendLogs',
              path: '/home/backendLogs',
              icons: 'fa fa-file-text',
              permission: ['view', 'add', 'delete', 'edit', 'resetPassword'],
              show: true,
              children: []
            }
          ]
        },
        {
          id: 21,
          label: i18n.t('menu.service'),
          href: '/home/service',
          path: '/home/service',
          icons: 'fa fa-users',
          permission: ['view', 'add', 'delete', 'edit', 'resetPassword'],
          show: true,
          children: [
            {
              id: 21,
              label: i18n.t('menu.upDownRecord'),
              href: '/home/upDownRecord',
              path: '/home/upDownRecord',
              icons: 'fa fa-file-text',
              permission: ['view', 'add', 'delete', 'edit', 'resetPassword'],
              show: true,
              children: []
            },
            {
              id: 21,
              label: i18n.t('menu.upDownAudit'),
              href: '/home/upDownAudit',
              path: '/home/upDownAudit',
              icons: 'fa fa-file-text',
              permission: ['view', 'add', 'delete', 'edit', 'resetPassword'],
              show: true,
              children: []
            },
            {
              id: 21,
              label: i18n.t('menu.upDownRecordChannel'),
              href: '/home/upDownRecordChannel',
              path: '/home/upDownRecordChannel',
              icons: 'fa fa-file-text',
              permission: ['view', 'add', 'delete', 'edit', 'resetPassword'],
              show: true,
              children: []
            },
            {
              id: 21,
              label: '博主上分',
              href: '/home/upDownRecordBlog',
              path: '/home/upDownRecordBlog',
              icons: 'fa fa-file-text',
              permission: ['view', 'add', 'delete', 'edit', 'resetPassword'],
              show: true,
              children: []
            },
            {
              id: 21,
              label: '上分审核',
              href: '/home/upAudit',
              path: '/home/upAudit',
              icons: 'fa fa-file-text',
              permission: ['view', 'add', 'delete', 'edit', 'resetPassword'],
              show: true,
              children: []
            },
            {
              id: 21,
              label: '测试号管理',
              href: '/home/testManagement',
              path: '/home/testManagement',
              icons: 'fa fa-file-text',
              permission: ['view', 'add', 'delete', 'edit', 'resetPassword'],
              show: true,
              children: []
            },
            {
              id: 21,
              label: i18n.t('menu.content'),
              href: '/home/content',
              path: '/home/content',
              icons: 'fa fa-file-text',
              permission: ['view', 'add', 'delete', 'edit', 'resetPassword'],
              show: true,
              children: []
            },
            {
              id: 21,
              label: i18n.t('menu.linkConfig'),
              href: '/home/linkConfig',
              path: '/home/linkConfig',
              icons: 'fa fa-file-text',
              permission: ['view', 'add', 'delete', 'edit', 'resetPassword'],
              show: true,
              children: []
            },
            {
              id: 21,
              label: i18n.t('menu.serviceConfig'),
              href: '/home/serviceConfig',
              path: '/home/serviceConfig',
              icons: 'fa fa-file-text',
              permission: ['view', 'add', 'delete', 'edit', 'resetPassword'],
              show: true,
              children: []
            },
            {
              id: 21,
              label: i18n.t('menu.pushMessage'),
              href: '/home/pushMessage',
              path: '/home/pushMessage',
              icons: 'fa fa-file-text',
              permission: ['view', 'add', 'delete', 'edit', 'resetPassword'],
              show: true,
              children: []
            },
            {
              id: 21,
              label: i18n.t('menu.unbindControl'),
              href: '/home/unbindControl',
              path: '/home/unbindControl',
              icons: 'fa fa-file-text',
              permission: ['view', 'add', 'delete', 'edit', 'resetPassword'],
              show: true,
              children: []
            }
          ]
        },
        {
          id: 21,
          label: i18n.t('menu.orgList'),
          href: '/home/orgList',
          path: '/home/orgList',
          icons: 'fa fa-file-text',
          permission: ['view', 'add', 'delete', 'edit', 'resetPassword'],
          show: true,
          children: []
        },
        {
          id: 21,
          label: '业务员管理',
          href: '/home/service',
          path: '/home/service',
          icons: 'fa fa-users',
          permission: ['view', 'add', 'delete', 'edit', 'resetPassword'],
          show: true,
          children: [
            {
              id: 21,
              label: '业务员列表',
              href: '/home/salesmanList',
              path: '/home/salesmanList',
              icons: 'fa fa-file-text',
              permission: ['view', 'add', 'delete', 'edit', 'resetPassword'],
              show: true,
              children: []
            },
            {
              id: 21,
              label: '业务员报表',
              href: '/home/salesmanDailyReport',
              path: '/home/salesmanDailyReport',
              icons: 'fa fa-file-text',
              permission: ['view', 'add', 'delete', 'edit', 'resetPassword'],
              show: true,
              children: []
            },
            {
              id: 21,
              label: '业务员对账表',
              href: '/home/salesmanReport',
              path: '/home/salesmanReport',
              icons: 'fa fa-file-text',
              permission: ['view', 'add', 'delete', 'edit', 'resetPassword'],
              show: true,
              children: []
            },
          ]
        }
      ]
    }
  ]
}
export default menu
