const permission  = {
  sysAccount1: '账号',
  sysAccount2: '角色',
  sysAccount3: '用户列表',
  sysAccountCol1: '账号',
  sysAccountCol2: '角色',
  sysAccountCol3: '创建时间',
  sysAccountCol4: '最后登录时间',
  sysAccountCol5: '最后登录IP',
  sysAccountCol6: '创建人',
  sysAccountCol7: '最后编辑人',
  sysAccountCol8: '状态',
  sysAccountCol9: '重要信息',
  sysAccountCol10: '游戏配置菜单',
  whitelist1: '后台系统',
  whitelist2: 'IP地址',
  whitelist3: '添加人',
  whitelist4: '操作时间',
  whitelistCol1: 'IP地址',
  whitelistCol2: '备注',
  whitelistCol3: '操作时间',
  whitelistCol4: '添加人',
  whitelistCol5: '地区',
  bkLogs1: '业务编号',
  bkLogs2: '业务描述',
  bkLogs3: '创建者ID',
  bkLogs4: '日志级别',
  bkLogsCol1: '业务编号',
  bkLogsCol2: '业务描述',
  bkLogsCol3: '业务名称',
  bkLogsCol4: '创建者',
  bkLogsCol5: '日志级别',
  bkLogsCol6: '日志时间',
  roleManage1: '角色名称',
  roleManage2: '上级角色',
  roleManage3: '角色权限',
  roleManage4: '角色注释',
  roleManage5: '角色状态',
  roleManage6: '取消',
  roleManage7: '确定',
  roleManage8: '未配置',
  roleManage9: '已配置',
  roleManage10: '配置',
  roleManage11: '菜单权限',
  roleManage12: '解绑',
  roleManage13: '确定要解绑吗',
  systemAccountEdit1: '账号',
  systemAccountEdit2: '角色',
  systemAccountEdit3: '登录密码',
  systemAccountEdit4: '密码校验',
  systemAccountEdit5: '角色状态',
  systemAccountEdit6: '谷歌验证码绑定',
  systemAccountEdit7: '请输入新密码',
  systemAccountEdit8: '密码至少包含3种字符以上',
  systemAccountEdit9: '请再次输入密码',
  systemAccountEdit10: '两次输入密码不一致!',
  systemAccountEdit11: '请输入登录密码',
  systemAccountEdit12: '请输入校验密码',
  systemAccountEdit13: '请输入账号',
  systemAccountEdit14: '长度在 4 到 50 个字符',
  systemAccountEdit15: '请选择角色',
  whitelistEdit1: 'IP地址',
  whitelistEdit2: '备注',
  whitelistEdit3: '请输入IP地址'
}
export default permission