import axios from './axios'
import qs from 'qs'
import { baseUrl } from '@/config/config'
// 文件
// 大文件上传
export const bigFilePost = (params) => {
  return new Promise((resolve, reject) => {
    axios({
      url: baseUrl + '',
      method: 'POST',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: params,
      timeout: 600000
    }).then(res => {
      resolve(res.data)
    }).catch(error => {
      reject(error.data)
    })
  })
}
// 文件合并
export const mergeFilePost = (params) => {
  return new Promise((resolve, reject) => {
    axios({
      url: baseUrl + '',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8'
        // 'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8'
      },
      data: JSON.stringify(params),
      timeout: 600000
    }).then(res => {
      resolve(res.data)
    }).catch(error => {
      reject(error.data)
    })
  })
}
// 文件上传
export const awsUploadPost = (params) => {
  return new Promise((resolve, reject) => {
    axios({
      url: baseUrl + '/ga/Upload/awsUpload',
      method: 'POST',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: params,
      timeout: 600000
    }).then(res => {
      resolve(res.data)
    }).catch(error => {
      reject(error.data)
    })
  })
}