const spend  = {
  spend1: 'Payment channel',
  spend2: 'Open',
  spend3: 'Close',
  spendCol1: 'Channel name',
  spendCol2: 'Channel code (pay_code)',
  spendCol3: 'Minimum amount',
  spendCol4: 'Maximum amount',
  spendCol5: 'Quick amount',
  spendCol6: 'Front display name',
  spendCol7: 'Status',
  spendCol8: 'Exchange rate',
  pr1: 'Player ID',
  pr2: 'Order number',
  pr3: 'Order amount',
  pr4: 'Application time',
  pr5: 'Is it a blogger',
  pr6: 'One-click review passed',
  pr7: 'One-click review rejected',
  pr8: 'Order number',
  pr9: 'Application time',
  pr10: 'Channel ID',
  pr11: 'Channel name',
  pr12: 'Salesman name',
  pr13: 'Withdrawal amount',
  pr14: 'Handling fee',
  pr15: 'Payment',
  pr16: 'Review',
  pr17: 'Reject',
  pr18: 'Confiscate',
  pr19: 'Suspend',
  pr20: 'All',
  pr21: 'Yes',
  pr22: 'No',
  prCon1: 'Order number',
  prCon2: 'Player ID',
  prCon3: 'Attribution',
  prCon4: 'Balance',
  prCon5: 'Withdrawal',
  prCon6: 'Remarks',
  prCon7: 'Total deposit',
  prCon8: 'Total withdrawal',
  prCon9: 'Upgrade',
  prCon10: 'Order status',
  prCon11: 'Account details',
  wdmanage1: 'Handling fee',
  wdmanage2: 'Total orders',
  wdmanage3: 'Withdrawn orders',
  wdmanage4: 'Total order amount',
  wdmanage5: 'Total withdrawal amount',
  wdmanage6: 'Player ID',
  wdmanage7: 'Order number',
  wdmanage8: 'Order status',
  wdmanage9: 'Operator',
  wdmanage10: 'Order amount',
  wdmanage11: 'Application time',
  wdmanage12: 'Recharge or not',
  wdmanage13: 'Withdrawal account',
  wdmanage14: 'Withdrawal record',
  wdmanageCol1: 'Order number',
  wdmanageCol2: 'Player ID',
  wdmanageCol3: 'Withdrawal amount',
  wdmanageCol4: 'Handling fee',
  wdmanageCol5: 'Amount received',
  wdmanageCol6: 'Order status',
  wdmanageCol7: 'Account details',
  wdmanageCol8: 'Operator',
  wdmanageCol9: 'Application time',
  wdmanageCol10: 'Review time',
  bank1: 'Player ID',
  bank2: 'Card number query',
  bank3: 'Type query',
  bank4: 'Bank card list',
  bank5: 'Bank card type',
  bankCol1: 'Bank code',
  bankCol2: 'Bank name',
  bankCol3: 'User id',
  bankCol4: 'User name',
  bankCol5: 'Card number',
  bankCol6: 'Type',
  bankCol7: 'Bank CPF',
  bankCol8: 'User name',
  spendD1: 'Please enter the card number',
  spendD2: 'Please select the type',
  spendD3: 'Bank code',
  spendD4: 'Bank name',
  spendD5: 'Please enter the bank code',
  spendD6: 'Please enter the bank name',
}
export default spend