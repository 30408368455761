import {
  bigFilePost,
  mergeFilePost
} from '@/api/fileApi'
const fileFun = {
  namespaced: true,
  state: {
  },
  getters: {},
  mutations: {
  },
  actions: {
    BigFilePost ({ state, commit, dispatch }, params) {
      return new Promise((resolve, reject) => {
        bigFilePost(params).then(res => {
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    },
    MergeFilePost ({ state, commit, dispatch }, params) {
      return new Promise((resolve, reject) => {
        mergeFilePost(params).then(res => {
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    }
  }
}
export default fileFun
