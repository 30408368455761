<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App',
  watch: {
    '$route' (to, from) {
      // this.handleLoading(2000)
    }
  }
}
</script>

<style lang="scss">
@import url('./style/reset.css');
html,body{
  width: 100%;
  height: 100%;
}
#app{
  width: 100%;
  height: 100%;
}
:root #app{
  overflow: hidden;
}
body .el-table th.gutter {
  display: table-cell !important;
}
.home-form{
  .el-form-item{
    margin-bottom: 10px !important;
  }
  .search-btn{
    margin-top: 3px;
  }
}
.mt-15{
  margin-bottom: 10px;
}
.mb-5{
  margin-bottom: 5px;
}
.el-tooltip__popper.is-dark{
  background-color: rgba(50, 50, 50, 0.7) !important;
}
.el-tooltip__popper[x-placement^="top"] .popper__arrow{
  border-top-color: rgba(50, 50, 50, 0.7) !important;
}
.el-tooltip__popper[x-placement^="top"] .popper__arrow::after{
  border-top-color: rgba(50, 50, 50, 0.7) !important;
  opacity: 0 !important;
}
.el-card__body .home-body {
  margin-top: -15px;
}
.msg-body{
  height: 168px;
  overflow: auto;
  word-break: break-all;
  margin-bottom: 15px!important;
}
.msg-body p{
  margin-bottom: 10px !important;
  font-size: 13px;
}
.msg-i:before{
  margin-right: 5px;
}
.el-tabs--card > .el-tabs__header .el-tabs__nav{
  border:none !important;
}
.el-tabs--card > .el-tabs__header .el-tabs__item:first-child {
  border-left: 1px solid #eee !important;
}
.btn-list {
  // float: right;
  margin-right: 10px;
  font-size: 12px;
  color: #409EFF;
  cursor: pointer;
}
.box-card{
  border: none !important;
  box-shadow: none !important;
  .el-card__header {
    border-bottom: none !important;
  }
  .el-card__header, .el-card__body {
    padding: 10px 15px!important;
  }
}
.box-card-form{
  margin-bottom: -12px !important;
  border: none !important;
  box-shadow: none !important;
  .el-card__header {
    border-bottom: none !important;
  }
  .el-card__header, .el-card__body {
    padding: 15px 15px 5px !important;
  }
}
.fix-lh {
  line-height: 32px;
}
.el-input.is-disabled .el-input__inner {
  color: #606266;
}
.total-img{
  width: 100px;
  height: 50px;
  border: none;
}
.kq{
  color: #67c23a;
}
.gb{
  color: #f56c6c;
}
.member-id{
  width: 118px;
  height: 24px;
  line-height: 24px;
  background-color: #409eff;
  color: #fff;
  cursor: pointer;
  display: inline-block;
}
.member-id1{
  width: 80px;
  height: 24px;
  line-height: 24px;
  background-color: #409eff;
  color: #fff;
  cursor: pointer;
  display: inline-block;
}
</style>
