const spend  = {
  spend1: '支付通道',
  spend2: '开启',
  spend3: '关闭',
  spendCol1: '通道名称',
  spendCol2: '通道编码(pay_code)',
  spendCol3: '最小金额',
  spendCol4: '最大金额',
  spendCol5: '快捷金额',
  spendCol6: '前台展示名称',
  spendCol7: '状态',
  spendCol8: '汇率',
  pr1: '玩家ID',
  pr2: '订单号',
  pr3: '订单金额',
  pr4: '申请时间',
  pr5: '是否博主',
  pr6: '一键审核通过',
  pr7: '一键审核拒绝',
  pr8: '订单号',
  pr9: '申请时间',
  pr10: '渠道ID',
  pr11: '通道名',
  pr12: '业务员名',
  pr13: '提款金额',
  pr14: '手续费',
  pr15: '打款',
  pr16: '审核',
  pr17: '拒绝',
  pr18: '没收',
  pr19: '挂起',
  pr20: '全部',
  pr21: '是',
  pr22: '否',
  prCon1: '订单号',
  prCon2: '玩家ID',
  prCon3: '归属',
  prCon4: '余额',
  prCon5: '提款',
  prCon6: '备注',
  prCon7: '总充值',
  prCon8: '总提现',
  prCon9: '上分',
  prCon10: '订单状态',
  prCon11: '账号详情',
  wdmanage1: '手续费',
  wdmanage2: '总订单',
  wdmanage3: '已提现订单',
  wdmanage4: '订单总额',
  wdmanage5: '已提现总额',
  wdmanage6: '玩家ID',
  wdmanage7: '订单号',
  wdmanage8: '订单状态',
  wdmanage9: '操作人员',
  wdmanage10: '订单金额',
  wdmanage11: '申请时间',
  wdmanage12: '是否充值',
  wdmanage13: '提款账号',
  wdmanage14: '出款记录',
  wdmanageCol1: '订单号',
  wdmanageCol2: '玩家ID',
  wdmanageCol3: '提款金额',
  wdmanageCol4: '手续费',
  wdmanageCol5: '到账金额',
  wdmanageCol6: '订单状态',
  wdmanageCol7: '账号详情',
  wdmanageCol8: '操作人员',
  wdmanageCol9: '申请时间',
  wdmanageCol10: '审核时间',
  bank1: '玩家ID',
  bank2: '卡号查询',
  bank3: '类型查询',
  bank4: '银行卡列表',
  bank5: '银行卡类型',
  bankCol1: '银行编码',
  bankCol2: '银行名称',
  bankCol3: '用户id',
  bankCol4: '用户名',
  bankCol5: '卡号',
  bankCol6: '类型',
  bankCol7: '银行CPF',
  bankCol8: '用户名',
  spendD1: '请输入卡号',
  spendD2: '请选择类型',
  spendD3: '银行编码',
  spendD4: '银行名称',
  spendD5: '请输入银行编码',
  spendD6: '请输入银行名称',
}
export default spend