<template>
  <div 
    element-loading-text="加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.6)"
    v-loading="pageLoading"
    class="index">
    <el-container>
      <left></left>
      <el-container>
        <el-header style="height:auto;">
          <right-top></right-top>
          <right-tags></right-tags>
        </el-header>
        <el-main class="main">
          <transition name="fade" mode="out-in">
            <keep-alive :include="keepAlive">
              <router-view></router-view>
            </keep-alive>
          </transition>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>
<script>
import {mapState, mapGetters} from 'vuex'
import left from './left'
import rightTop from './rightTop'
import rightTags from './rightTags'
var timer = null
export default {
  name: 'index',
  components: {
    left,
    rightTop,
    rightTags
  },
  data () {
    return {
      transitionName: '',
      notice: null,
      msgData: [],
      pageLoading: false
    }
  },
  created () {
  },
  mounted () {
    // this.refreshToken()
    // this.showTips()
  },
  watch: {
    '$route' (to, from) {
      const toDepth = to.path.split('/').length
      const fromDepth = from.path.split('/').length
      this.transitionName = toDepth < fromDepth ? 'slide-right' : 'slide-left'
      // this.handleLoading(1000)
      this.pageLoading = false
      setTimeout(() => {
        this.pageLoading = false
      }, 2000)
    }
  },
  computed: {
    ...mapGetters(['token', 'role', 'userInfo', 'tagList']),
    keepAlive () {
      var keepAliveList = []
      this.tagList.forEach((item, index) => {
        var path = item.value.split('/')
        if (keepAliveList.indexOf(path[path.length - 1]) < 0) {
          keepAliveList.push(path[path.length - 1])
        }
      })
      return keepAliveList
    }
  },
  methods: {
    // 显示消息框
    showTips () {
      const h = this.$createElement
      let str = this.msgData.join('')
      let strArr = []
      for (var i = 0; i < this.msgData.length; i++) {
        strArr.push(h('p', null, this.msgData[i].Content))
      }
      let html = h('div', null, [
        h('div', {class: 'msg-body'}, strArr),
        h('p', {class: 'msg-p'}, [
          h('el-button', {on: {click: this.handleView}, class: 'primary'}, [h('i', {class: 'el-icon-view msg-i'}, '查看')]),
          h('el-button', {on: {click: this.handleView}, class: 'success'}, [h('i', {class: 'el-icon-circle-check  msg-i'}, '已读')]),
          h('el-button', {on: {click: this.handleView}, class: 'danger'}, [h('i', {class: 'el-icon-circle-close  msg-i'}, '忽略')])
        ])
      ])
      if (this.notice) {
        this.notice.close()
      }
      this.notice = this.$notify({
        title: '消息提醒',
        dangerouslyUseHTMLString: true,
        message: html,
        position: 'bottom-right',
        // type: 'info',
        showClose: false,
        offset: 0,
        duration: 0,
        customClass: 'custom-notify'
      })
    },
    handleView () {},
    autoSetToken () {
      if (timer) {
        clearInterval(timer)
        timer = null
      }
      timer = setInterval(() => {
        if (this.token == '') {
          clearInterval(timer)
        } else {
          this.refreshToken()
        }
      }, 60000 * 60 * 6)
    },
    refreshToken () {
      this.$store.dispatch('user/RefreshToken')
    }
  },
  beforeRouteUpdate (to, from, next) {
    next()
  },
  beforeRouteEnter (to, from, next) {
    next((vm) => {
      // 页面缓存配置
      // console.log(vm)
    })
  },
  beforeRouteLeave (to, from, next) {
    next()
  }
}
</script>

<style lang="scss" scoped>
.index{
  height: 100%;
  width: 100%;
  .el-container{
    height: 100%;
    width: 100%;
    background: #f0f2f5;
  }
  .el-aside{
    background: #00142a;
    color: rgba(255, 255, 255, 0.95);
    float: left\0;
  }
  .el-header{
    background: #fff;
    color: #333;
    padding: 0px;
  }
  .el-main{
    color: #333;
    height: 100%;
    padding: 0px;
    border-radius: 4px;
  }
  .el-main>div{
    margin: 10px;
    border-radius: 4px !important;
    padding: 0px;
    // background: #fff;
    height:calc(100% - 20px);
    // -webkit-box-shadow: 1px 1px 1px 1px #ccc !important;
    // box-shadow: 1px 1px 1px 1px #ccc!important;
    box-sizing: border-box;
    // min-width: 1330px;
  }
  .fade-enter{
    opacity: 0;
    // transform: translateX(-100%);
  }
  .fade-enter-active{
    transition: all 0.5s ease;
  }
  .fade-leave{
    transition: all 0.5s ease;
  }
  .fade-leave-active{
    opacity: 0;
    // transform: translateX(-100%);
  }
}

</style>
