const common  = {
  date: '日期',
  time: '时间',
  startDate: '开始日期',
  endDate: '结束日期',
  search: '查询',
  serverTime: '服务器时间',
  syncData: '同步数据',
  language: '选择语言',
  more: '更多',
  closeOther: '关闭其他',
  closeAll: '关闭全部',
  english: 'English',
  chinese: '中文',
  portuguese: 'Portuguese',
  personalInfo: '个人信息',
  resetPass: '修改密码',
  logout: '退出登录',
  confirmLogout: '确定要退出吗？',
  today: '今天',
  yestoday: '昨天',
  beforeYestoday: '前天',
  lastSeven: '最近7天',
  lastFourteen: '最近14天',
  lastThirty: '最近30天',
  lastMonth: '上个月',
  currentMonth: '本月',
  userType: '用户类型',
  select: '请选择',
  channelId: '渠道ID',
  month: '月份',
  exportAll: '全部导出',
  room: '房间',
  add: '新增',
  edit: '修改',
  delete: '删除',
  action: '操作',
  number: '序号',
  to: '至',
  reset: '重置',
  export: '导出',
  userId: '用户id',
  start: '起',
  end: '止',
  changeBind: '换绑',
  unbing: '解绑',
  done: '已完成',
  unDone: '未完成',
  pInput: '请输入正确',
  confirmDelete: '确定要删除吗？',
  confirm: '确定',
  cancel: '取消',
  tip: '提示',
  all: '全部',
  upAndDown: '上下分',
  oneKeyOpen: '一键开启',
  oneKeyClose: '一键关闭',
  stop: '停用',
  regTime: '注册时间',
  save: '保存',
  detail: '详情',
  selectTime: '选择时间'
}
export default common