const menu  = {
  index: '首页',
  report: '统计分析',
  statisticsDailyList: '日况统计概要',
  statisticsGameSettle: '游戏盈亏结算',
  statisticsMerchantSettle: '渠道对账表',
  agentRewardStatistics: '奖励支出汇总',
  statisticsRemainList: '留存报表',
  gameEcosystemMonitoring: '游戏生态监控',
  vip: '玩家管理',
  vipList: '所有玩家',
  vipBet: '游戏日志',
  accountChange: '金币日志',
  flowList: '打码明细',
  verc: '短信记录',
  paymentManage: '支付管理',
  paymentChannelManage: '支付通道',
  paymenthistory: '订单管理',
  spendManage: '支出管理',
  spendChannelManage: '支出通道',
  paymentReview: '出款审核',
  pendingList: '挂起列表',
  withdrawalManage: '出款记录',
  bankManage: '卡号管理',
  activity: '活动管理',
  popupImg: '弹窗图管理',
  advertising: '活动图管理',
  suspension: '悬浮图管理',
  weeklyActivityData: '活动数据',
  agent: '代理管理',
  agentRelation: '代理关系',
  invitationReport: '代理日报',
  agentRewardCount: '代理奖励统计',
  unbindingDetails: '掉绑明细',
  bloggerDataMonitoring: '博主数据监控',
  agentSummary: '代理汇总',
  site: '游戏配置',
  activityConfig: '活动配置',
  systemConfig: '系统配置',
  vipConfig: 'Vip配置',
  gameConfig: '控制配置',
  pgConfigList: 'PGGAME配置',
  buyInAmountConfig: '打码量配置',
  venueManage: '场馆配置',
  channel: '渠道管理',
  channelList: '渠道列表',
  quoteManage: '额度管理',
  salesmanReportBusiness: '业务员对账表',
  permission: '账号管理',
  systemAccount: '用户列表',
  roleManage: '角色列表',
  whitelist: 'IP白名单',
  backendLogs: '后台日志',
  service: '客服系统',
  upDownRecord: 'GM上下分',
  upDownAudit: '上下分审核',
  upDownRecordChannel: '渠道上分',
  content: '系统公告',
  linkConfig: '官方频道配置',
  serviceConfig: '客服配置',
  scrollMessage: '滚屏消息',
  unbindControl: '掉绑控制名单',
  org: '组织机构',
  orgList: '组织管理',
  changeBindRecord: '换绑记录'
}
export default menu