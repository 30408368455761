import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'
import user from './modules/user'
import common from './modules/common'
import manage from './modules/manage'
import fileFun from './modules/fileFun'

Vue.use(Vuex)
const store = new Vuex.Store({
  modules: {
    user,
    common,
    fileFun,
    manage
  },
  getters
})
export default store
