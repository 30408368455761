const permission  = {
  sysAccount1: 'conta',
  sysAccount2: 'função',
  sysAccount3: 'Lista de usuários',
  sysAccountCol1: 'Conta',
  sysAccountCol2: 'função',
  sysAccountCol3: 'Hora de criação',
  sysAccountCol4: 'Último horário de login',
  sysAccountCol5: 'IP do último login',
  sysAccountCol6: 'Criador',
  sysAccountCol7: 'Última edição por',
  sysAccountCol8: 'status',
  sysAccountCol9: 'Informações importantes',
  sysAccountCol10: 'Menu de configuração do jogo',
  whitelist1: 'Sistema de back-end',
  whitelist2: 'Endereço IP',
  whitelist3: 'Adicionar pessoas',
  whitelist4: 'Tempo de operação',
  whitelistCol1: 'Endereço IP',
  whitelistCol2: 'Observações',
  whitelistCol3: 'Tempo de operação',
  whitelistCol4: 'Adicionar pessoas',
  whitelistCol5: 'Região',
  bkLogs1: 'Número comercial',
  bkLogs2: 'Descrição do negócio',
  bkLogs3: 'ID do Criador',
  bkLogs4: 'Nível de registro',
  bkLogsCol1: 'Número comercial',
  bkLogsCol2: 'Descrição do negócio',
  bkLogsCol3: 'Nome da empresa',
  bkLogsCol4: 'Criador',
  bkLogsCol5: 'Nível de registro',
  bkLogsCol6: 'Tempo de registro',
  roleManage1: 'nome da função',
  roleManage2: 'Função superior',
  roleManage3: 'permissões de função',
  roleManage4: 'Comentário da função',
  roleManage5: 'status da função',
  roleManage6: 'Cancelar',
  roleManage7: 'OK',
  roleManage8: 'Não configurado',
  roleManage9: 'Configurado',
  roleManage10: 'Configuração',
  roleManage11: 'Permissões de menu',
  roleManage12: 'Desvincular',
  roleManage13: 'Tem certeza que deseja desvincular',
  systemAccountEdit1: 'Conta',
  systemAccountEdit2: 'função',
  systemAccountEdit3: 'Senha de login',
  systemAccountEdit4: 'Verificação de senha',
  systemAccountEdit5: 'Status da função',
  systemAccountEdit6: 'Vinculação do código de verificação do Google',
  systemAccountEdit7: 'Por favor, insira uma nova senha',
  systemAccountEdit8: 'A senha contém pelo menos 3 caracteres',
  systemAccountEdit9: 'Por favor, digite sua senha novamente',
  systemAccountEdit10: 'As senhas inseridas duas vezes são inconsistentes!',
  systemAccountEdit11: 'Por favor, digite sua senha de login',
  systemAccountEdit12: 'Por favor, insira a senha de verificação',
  systemAccountEdit13: 'Por favor, insira o número da conta',
  systemAccountEdit14: 'Comprimento entre 4 e 50 caracteres',
  systemAccountEdit15: 'Por favor, selecione uma função',
  whitelistEdit1: 'Endereço IP',
  whitelistEdit2: 'Observações',
  whitelistEdit3: 'Por favor, insira o endereço IP'
}
export default permission