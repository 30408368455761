const site  = {
  acConfig1: 'Configuração ativa',
  acConfigCol1: 'Nome da atividade',
  acConfigCol2: 'switch',
  sysConfig1: 'Configuração do sistema',
  sysConfigCol1: 'Descrição da função',
  vipConfig1: 'Configuração VIP',
  vipConfigCol1: 'Nível VIP',
  vipConfigCol2: 'Fluxo de atualização',
  vipConfigCol3: 'Recompensa de atualização',
  vipConfigCol4: 'Recompensa semanal',
  vipConfigCol5: 'Recompensa mensal',
  pgConfig1: 'ID real do jogo PG',
  pgConfig2: 'Configuração PGGAME',
  pgConfig3: 'ID falso do jogo pg',
  pgConfig4: 'ID falso do jogo pp',
  pgConfigCol1: 'Nome do jogo',
  pgConfigCol2: 'Status da chave',
  biaConfig1: 'Configuração do volume de código',
  biaConfigCol1: 'Nome da configuração',
  biaConfigCol2: 'rollover múltiplo',
  biaConfigCol3: 'tipo',
  acConfigDetail1: 'Condições de convite válidas',
  acConfigDetail2: 'codificação',
  acConfigDetail3: 'Valor de recompensa limitado',
  acConfigDetail4: 'Tipo de recarga',
  acConfigDetail5: 'Primeiro depósito',
  acConfigDetail6: 'Cobrança acumulada',
  acConfigDetail7: 'Primeiro depósito',
  acConfigDetail8: 'Recarregar',
  acConfigDetail9: 'Gradiente de convite de número de pessoas (recompensa da caixa do tesouro)',
  acConfigDetail10: 'Gradiente de convite para primeiro depósito',
  acConfigDetail11: 'Recarga acumulada',
  acConfigDetail12: 'Apostas Diárias',
  acConfigDetail13: 'Apostas semanais',
  acConfigDetail14: 'Perda semanal',
  acConfigDetail15: 'Desconto semanal',
  acConfigDetail16: 'Depósito semanal',
  acConfigDetail17: 'Desconto de vendas de nível inferior para agente',
  acConfigDetail18: 'Compartilhamento de recarga subordinado',
  acConfigDetailCol1: 'Classificar',
  acConfigDetailCol2: 'Tipo de recarga',
  acConfigDetailCol3: 'Valor inicial',
  acConfigDetailCol4: 'Proporção de presentes (%)',
  acConfigDetailCol5: 'Presente limitado',
  acConfigDetailCol6: 'Número de convidados',
  acConfigDetailCol7: 'Recompensa',
  acConfigDetailCol8: 'Valor do primeiro depósito',
  acConfigDetailCol9: 'Recompensa',
  acConfigDetailCol10: 'Valor acumulado',
  acConfigDetailCol11: 'Valor do presente',
  acConfigDetailCol12: 'Apostas',
  acConfigDetailCol13: 'Taxa de retorno (por mil)',
  acConfigDetailCol14: 'Recarregar',
  acConfigDetailCol15: 'Taxa de retorno (por mil)',
  acConfigDetailCol16: 'Nível',
  acConfigDetailCol17: 'Taxa de retorno (por mil)',
  acConfigDetailCol18: 'Nível',
  acConfigDetailCol19: 'Proporção',
  ActivityConfigEdit1: 'Por favor, insira o número de série',
  ActivityConfigEdit2: 'Por favor, insira o valor',
  ActivityConfigEdit3: 'Por favor, insira a proporção (%)',
  ActivityConfigEdit4: 'Por favor, insira o limite para presente',
  ActivityConfigEdit5: 'Por favor, insira o número de convidados',
  ActivityConfigEdit6: 'Por favor, insira a recompensa',
  ActivityConfigEdit7: 'Por favor, insira o valor do primeiro depósito',
  ActivityConfigEdit8: 'Por favor, insira sua aposta',
  ActivityConfigEdit9: 'Por favor, insira recarga',
  buyInAmountConfigEdit1: 'Nome da configuração',
  buyInAmountConfigEdit2: 'Volume de negócios múltiplo',
  vipConfigEdit1: 'nível VIP',
  vipConfigEdit2: 'Fluxo de atualização',
  vipConfigEdit3: 'Recompensa de atualização',
  vipConfigEdit4: 'Recompensa semanal',
  vipConfigEdit5: 'Recompensa mensal',
  vipConfigEdit6: 'Por favor, insira o nível VIP',
  vipConfigEdit7: 'Por favor, insira o comprovante de atualização',
  vipConfigEdit8: 'Por favor, insira a recompensa do upgrade',
  vipConfigEdit9: 'Por favor, insira a recompensa semanal',
  vipConfigEdit10: 'Por favor, insira a recompensa mensal',
  venueManageTagEdit1: 'Tipo de jogo',
  venueManageTagEdit2: 'Marca do jogo',
  venueManageTagEdit3: 'Nome da tag',
  venueManageTagEdit4: 'status',
  venueManageTagEdit5: 'Por favor, selecione uma função',
  venueManageTagEdit6: 'Selecione uma marca de jogo',
  venueManageTagEdit7: 'Por favor, insira o nome da tag',
  venueManageTagEdit8: 'Comprimento entre 1 e 100 caracteres',
  gameConfigEdit1: 'Nome do jogo',
  gameConfigEdit2: 'Status da mudança',
  gameConfigEdit3: 'Configuração do jogo',
  gameConfigEdit4: 'quantidade',
  gameConfigEdit5: 'Valor da aposta',
  gameConfigEdit6: 'proporção efetiva',
  gameConfigEdit7: 'Valor do lucro',
  gameConfigEdit8: 'Adicionar código (%)',
  gameConfigEdit9: 'Nome do jogo',
  gameConfigEdit10: 'Status da mudança',
  gameConfigEdit11: 'Lista de jogos de três participantes',
  gameConfigEdit12: 'Número de convidados',
  gameConfigEdit13: 'Taxa de vinculação',
  gameConfigEdit14: 'Número limitado cumulativo de pessoas',
  gameConfigEdit15: 'Valor do lucro',
  gameConfigEdit16: 'Taxa de imposto (%)',
  gameConfigEdit17: 'Descrição da função',
  gameConfigEdit18: 'Por favor, insira CfgType',
  gameConfigEdit19: 'Por favor, insira CfgValue',
  venueManageEdit1: 'Nome do jogo',
  venueManageEdit2: 'Tipo de jogo',
  venueManageEdit3: 'Código do jogo',
  venueManageEdit4: 'Nome do jogo em inglês',
  venueManageEdit5: 'Nome chinês do jogo',
  venueManageEdit6: 'Nome do jogo Brasil',
  venueManageEdit7: 'Imagem do jogo',
  venueManageEdit8: 'Popular',
  venueManageEdit9: 'Classificar',
  venueManageEdit10: 'Mais recente',
  venueManageEdit11: 'Recomendado',
  venueManageEdit12: 'Status de manutenção',
  venueManageEdit13: 'Normal',
  venueManageEdit14: 'Manutenção',
  venueManageEdit15: 'Sim',
  venueManageEdit16: 'Não',
  venueManageEdit17: 'Suporta .webp, tamanho não excede 1,5M',
  venueManage1: 'Lista de jogos',
  venueManage2: 'Código do jogo',
  venueManage3: 'Nome do jogo',
  venueManage4: 'Marca do jogo',
  venueManage5: 'Linha',
  venueManage6: 'Tipo de jogo',
  venueManage7: 'tipo de propriedade',
  venueManage8: 'status',
  venueManage9: 'Status de manutenção',
  venueManage10: 'Definir popular',
  venueManage11: 'Cancelar popular',
  venueManage12: 'Definir mais recente',
  venueManage13: 'Cancelar último',
  venueManage14: 'Definir recomendações',
  venueManage15: 'Cancelar recomendação',
  venueManage16: 'Classificar',
  venueManage17: 'Lista de locais',
  venueManage18: 'Todos',
  venueManage19: 'tag do jogo',
  venueManage20: 'Nome da etiqueta',
  venueManage21: 'Tipo de jogo',
  venueManage22: 'status',
  venueManage23: 'Popular',
  venueManage24: 'Mais recente',
  venueManage25: 'recomendado',
  venueManage26: 'Selecione pelo menos um',
  venueManage27: 'desativado',
  venueManage28: 'Off-line',
  venueManage29: 'normal',
  venueManage1Col1: 'Código do jogo',
  venueManage1Col2: 'Nome do jogo em inglês',
  venueManage1Col3: 'Nome chinês do jogo',
  venueManage1Col4: 'Nome do jogo Brasil',
  venueManage1Col5: 'Marca do jogo',
  venueManage1Col6: 'Tipo de jogo',
  venueManage1Col7: 'Popular',
  venueManage1Col8: 'Mais recente',
  venueManage1Col9: 'recomendado',
  venueManage1Col10: 'Status',
  venueManage1Col11: 'Status de manutenção',
  venueManage2Col1: 'número de série',
  venueManage2Col2: 'ID do local',
  venueManage2Col3: 'Nome do local',
  venueManage2Col4: 'Tipo de jogo',
  venueManage2Col5: 'Hora da última atualização',
  venueManage2Col6: 'Status de manutenção',
  venueManage2Col7: 'Status',
  venueManage3Col1: 'nome do rótulo',
  venueManage3Col2: 'status',
  venueManage3Col3: 'Tipo de jogo',
  venueManage3Col4: 'Marca do jogo',
  venueManage3Col5: 'Hora de criação',
  gameConfig1: 'Controle de pg falso',
  gameConfig2: 'Canal',
  gameConfig3: 'RTP inicial',
  gameConfig4: 'Controlar quantidade múltipla',
  gameConfig5: 'Controle RTP',
  gameConfig6: 'Probabilidade de inserir página falsa',
  gameConfig7: 'Sincronize a configuração oficial com um clique',
  gameConfig8: 'Configuração do jogo',
  gameConfig9: 'Controle pp falso',
  gameConfig10: 'Probabilidade de entrar em pp falso',
  gameConfig11: 'Desvinculando configurações',
  gameConfig12: 'Lista de permissões',
  gameConfig13: 'Lista negra',
  gameConfig14: 'Canal',
  gameConfig15: 'Configuração de imposto',
  gameConfig16: 'Saldo do jogador',
  gameConfig17: 'Saldo de Lucro',
  gameConfig18: 'Se o saldo do lucro é decimal',
  gameConfig19: 'Lista de jogos de três partes',
  gameConfig20: 'Mudança de imposto',
  gameConfig21: 'Ganhe mais dinheiro',
  gameConfig22: 'Troca de bônus vencedora',
  gameConfig23: 'Taxa de codificação efetiva',
  gameConfig24: 'Codificação cumulativa',
  gameConfig25: 'Mudança de proporção efetiva de codificação',
  gameConfigCol1: 'Quantidade',
  gameConfig2Col1: 'Número de convidados',
  gameConfig2Col2: 'Taxa de vinculação (%)',
  gameConfig2Col3: 'Número limitado cumulativo de pessoas',
  gameConfig3Col1: 'Valor do lucro',
  gameConfig3Col2: 'Taxa de imposto (%)',
  gameConfig4Col1: 'Valor do lucro',
  gameConfig4Col2: 'Adicionar código (%)',
  gameConfig5Col1: 'Valor da aposta',
  gameConfig5Col2: 'Proporção efetiva (%)',
}
export default site