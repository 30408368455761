import axios from './axios'
import qs from 'qs'
import { baseUrl } from '@/config/config'
// 新增机构
export const orgPost = (params) => {
  return new Promise((resolve, reject) => {
    axios({
      url: baseUrl + '/ga/org',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8' 
      },
      data: JSON.stringify(params)
    }).then(res => {
      resolve(res.data)
    }).catch(error => {
      reject(error.data)
    })
  })
}
// 获取时间戳
export const serviceTimestampGet = (params) => {
  return new Promise((resolve, reject) => {
    axios({
      url: baseUrl + '/auth/serviceTimestamp',
      method: 'GET',
      params
    }).then(res => {
      resolve(res.data)
    }).catch(error => {
      reject(error.data)
    })
  })
}
// 获取时间
export const serviceTimeGet = (params) => {
  return new Promise((resolve, reject) => {
    axios({
      url: baseUrl + '/auth/serviceTime',
      method: 'GET',
      params
    }).then(res => {
      resolve(res.data)
    }).catch(error => {
      reject(error.data)
    })
  })
}
//组织机构列表
export const orgPageGet = (params) => {
  return new Promise((resolve, reject) => {
    axios({
      url: baseUrl + '/ga/org/page',
      method: 'GET',
      params
    }).then(res => {
      resolve(res.data)
    }).catch(error => {
      reject(error.data)
    })
  })
}
// 删除
export const orgDelete = (params) => {
  return new Promise((resolve, reject) => {
    axios({
      url: baseUrl + '/ga/org/' + params.id,
      method: 'Delete',
      headers: {
        'Content-Type': 'application/json; charset=utf-8' 
      },
      data: JSON.stringify(params)
    }).then(res => {
      resolve(res.data)
    }).catch(error => {
      reject(error.data)
    })
  })
}
//获取字典数据
export const dictGet = (params) => {
  return new Promise((resolve, reject) => {
    axios({
      url: baseUrl + '/sys/dict/dictType/' + params.dictType,
      method: 'GET',
      params
    }).then(res => {
      resolve(res.data)
    }).catch(error => {
      reject(error.data)
    })
  })
}

//获取枚举数据
export const enumGet = (params) => {
  return new Promise((resolve, reject) => {
    axios({
      url: baseUrl + '/ga/enum/' + params.key,
      method: 'GET'
    }).then(res => {
      if(res.data.data){
        resolve(res.data)
      }else{
        resolve({code: 0, data: [], message: "处理成功", success: true})
      }
    }).catch(error => {
      reject(error.data)
    })
  })
}
// 获取网站名称
export const getSiteName = (params) => {
  return new Promise((resolve, reject) => {
    axios({
      url: baseUrl + '/auth/getSiteName',
      method: 'GET',
      params
    }).then(res => {
      resolve(res.data)
    }).catch(error => {
      reject(error.data)
    })
  })
}