const channel  = {
  smReportBusi1: 'Channel ID',
  smReportBusi2: 'Salesperson ID',
  smReportBusi3: 'Month',
  smReportBusi4: 'Salesperson reconciliation table',
  smReportBusiCol1: 'Channel ID',
  smReportBusiCol2: 'Channel name',
  smReportBusiCol3: 'Salesperson ID',
  smReportBusiCol4: 'Salesperson name',
  smReportBusiCol5: 'Total recharge',
  smReportBusiCol6: 'Recharge fee',
  smReportBusiCol7: 'Withdrawal quantity',
  smReportBusiCol8: 'Withdrawal fee',
  quoteManage1: 'Quota management',
  quoteManageCol1: 'Channel ID',
  quoteManageCol2: 'Channel name',
  quoteManageCol3: 'Player points/used amount',
  quoteManageCol4: 'Number of test accounts/used amount',
  quoteManageCol5: 'Channel withdrawal limit',
  channelList1: 'Channel ID',
  channelList2: 'Channel name',
  channelList3: 'PGGAME configuration',
  channelList4: 'Copy link successfully',
  channelList5: 'Are you sure you want to unbind from Google?',
  channelList6: 'Prompt',
  channelList7: 'Confirm',
  channelList8: 'Cancel',
  channelListCol1: 'Channel ID',
  channelListCol2: 'Channel name',
  channelListCol3: 'Channel link',
  channelListCol4: 'Recharge fee ratio',
  channelListCol5: 'Withdrawal fee ratio',
  channelListCol6: 'API fee',
  channelListCol7: 'Associated channel',
  channelListCol8: 'Withdrawal authority',
  channelListAction1: 'Copy link',
  channelListAction2: 'Edit',
  channelListAction3: 'API fee edit',
  channelListAction4: 'Google unbinding',
  channelListEdit1: 'Channel name',
  channelListEdit2: 'Channel account',
  channelListEdit3: 'Login password',
  channelListEdit4: 'Recharge fee ratio',
  channelListEdit5: 'Withdrawal fee ratio',
  channelListEdit6: 'API fee',
  channelListEdit7: 'Associated channel',
  channelListEdit8: 'Please enter the channel name',
  channelListEdit9: 'Length between 1 and 100 characters',
  channelListEdit10: 'Please enter your login password',
  channelListEdit11: 'Please enter the recharge fee ratio',
  channelListEdit12: 'Please enter the withdrawal fee ratio',
  channelListEdit13: 'Please select the associated channel',
  quoteManageEdit1: 'Player score quota',
  quoteManageEdit2: 'Test account score quota',
  quoteManageEdit3: 'Test account quantity quota',
  quoteManageEdit4: 'Channel withdrawal limit',
}
export default channel