const channel  = {
  smReportBusi1: 'ID do canal',
  smReportBusi2: 'ID do vendedor',
  smReportBusi3: 'mês',
  smReportBusi4: 'Folha reconciliação do vendedor',
  smReportBusiCol1: 'ID do canal',
  smReportBusiCol2: 'Nome do canal',
  smReportBusiCol3: 'id do vendedor',
  smReportBusiCol4: 'nome do vendedor',
  smReportBusiCol5: 'Recarga total',
  smReportBusiCol6: 'Taxa recarga',
  smReportBusiCol7: 'Valor da retirada',
  smReportBusiCol8: 'Taxa retirada',
  quoteManage1: 'Gerenciamento cotas',
  quoteManageCol1: 'ID do canal',
  quoteManageCol2: 'Nome do canal',
  quoteManageCol3: 'Aumento pontos jogador/cota usada',
  quoteManageCol4: 'Número números teste/cota usada',
  quoteManageCol5: 'Limite retirada do canal',
  channelList1: 'ID do canal',
  channelList2: 'nome do canal',
  channelList3: 'Configuração PGGAME',
  channelList4: 'Copiar link com sucesso',
  channelList5: 'Tem certeza que deseja que o Google desvincule isso?',
  channelList6: 'prompt',
  channelList7: 'OK',
  channelList8: 'Cancelar',
  channelListCol1: 'ID do canal',
  channelListCol2: 'nome do canal',
  channelListCol3: 'Link do canal',
  channelListCol4: 'Taxa recarga',
  channelListCol5: 'Taxa taxa retirada',
  channelListCol6: 'taxa API',
  channelListCol7: 'Canal associado',
  channelListCol8: 'Permissão retirada',
  channelListAction1: 'Copiar link',
  channelListAction2: 'Editar',
  channelListAction3: 'Edição taxa API',
  channelListAction4: 'Desvincular Google',
  channelListEdit1: 'Nome do canal',
  channelListEdit2: 'conta do canal',
  channelListEdit3: 'Senha login',
  channelListEdit4: 'Taxa taxa recarga',
  channelListEdit5: 'Taxa taxa retirada',
  channelListEdit6: 'taxa API',
  channelListEdit7: 'Canal associado',
  channelListEdit8: 'Por favor, insira o nome do canal',
  channelListEdit9: 'Comprimento entre 1 e 100 caracteres',
  channelListEdit10: 'Por favor, digite sua senha login',
  channelListEdit11: 'Insira a taxa recarga',
  channelListEdit12: 'Por favor, insira a proporção da taxa retirada',
  channelListEdit13: 'Selecione o canal associado',
  quoteManageEdit1: 'Aumento pontos do jogador',
  quoteManageEdit2: 'Cota aumento número teste',
  quoteManageEdit3: 'Cota número teste',
  quoteManageEdit4: 'Limite retirada do canal',
}
export default channel