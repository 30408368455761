const activity  = {
  popupImg1: 'Activity status',
  popupImg2: 'Jump type',
  popupImg3: 'Pop-up image management',
  popupImgCon1: 'Title',
  popupImgCon2: 'Mobile phone picture',
  popupImgCon3: 'PC picture',
  popupImgCon4: 'Announcement type',
  popupImgCon5: 'length',
  popupImgCon6: 'width',
  popupImgCon7: 'Jump type',
  popupImgCon8: 'Jump address',
  popupImgCon9: 'Sort',
  popupImgCon10: 'status',
  ad1: 'status',
  ad2: 'Activity diagram management',
  ad3: 'Support .webp, size no more than 1.5M',
  adCol1: 'Title',
  adCol2: 'Mobile phone picture',
  adCol3: 'Link type',
  adCol4: 'Jump link',
  adCol5: 'Sort',
  adCol6: 'status',
  suspension1: 'Suspension chart management',
  suspensionCol1: 'Title',
  suspensionCol2: 'Mobile phone picture',
  suspensionCol3: 'pc picture',
  suspensionCol4: 'Jump link',
  suspensionCol5: 'status',
  suspensionCol6: 'Sort',
  suspensionCol7: 'display mode',
  advertisingEdit1: 'Title',
  advertisingEdit2: 'Link type',
  advertisingEdit3: 'Jump link',
  advertisingEdit4: 'status',
  advertisingEdit5: 'Sort',
  advertisingEdit6: 'h5 picture',
  advertisingEdit7: 'Please enter a title',
  advertisingEdit8: 'Length between 1 and 100 characters',
  advertisingEdit9: 'Please select the link type',
  advertisingEdit10: 'Jump link cannot be empty',
  advertisingEdit11: 'Weekly loss',
  advertisingEdit12: 'Invitation reward',
  advertisingEdit13: 'Agent',
  advertisingEdit14: 'Accumulated deposits',
  popupImgEdit1: 'Announcement type',
  popupImgEdit2: 'Title',
  popupImgEdit3: 'Jump type',
  popupImgEdit4: 'Jump address',
  popupImgEdit5: 'status',
  popupImgEdit6: 'Sort',
  popupImgEdit7: 'Mobile phone picture',
  popupImgEdit8: 'PC picture',
  popupImgEdit9: 'length',
  popupImgEdit10: 'width',
  popupImgEdit11: 'Text',
  popupImgEdit12: 'The value is between 0-10000',
  popupImgEdit13: 'Please select the announcement type',
  popupImgEdit14: 'Please enter a title',
  popupImgEdit15: 'Length between 1 and 100 characters',
  popupImgEdit16: 'Please select the jump type',
  popupImgEdit17: 'Please enter text',
  suspensionEdit1: 'Title',
  suspensionEdit2: 'Jump type',
  suspensionEdit3: 'Jump link',
  suspensionEdit4: 'Sort',
  suspensionEdit5: 'status',
  suspensionEdit6: 'Display mode',
  suspensionEdit7: 'Mobile phone picture',
  suspensionEdit8: 'PC picture',
  suspensionEdit9: 'length',
  suspensionEdit10: 'width',
  suspensionEdit11: 'The value is between 0-10000',
  suspensionEdit12: 'Please select the announcement type',
  suspensionEdit13: 'Please enter a title',
  suspensionEdit14: 'Length between 1 and 100 characters',
  suspensionEdit15: 'Please select the jump type',
  suspensionEdit16: 'Please enter the jump link',
  suspensionEdit17: 'Please select the display method',
  wActivityData1: 'First deposit invitation gradient',
  wActivityData2: 'Total recharge',
  wActivityData3: 'Total bonus',
  wActivityData4: 'Superior id',
  wActivityData5: 'subordinate id',
  wActivityData6: 'Recharge share',
  wActivityData7: 'Total share',
  wActivityData8: 'Level',
  wActivityData9: 'Weekly commission',
  wActivityData10: 'Player ID',
  wActivityData11: 'Weekly deposit',
  wActivityData12: 'Weekly Betting',
  wActivityData13: 'Weekly loss',
  wActivityData14: 'Daily Betting',
  wActivityData15: 'Rebate',
  wActivityData16: 'Contributing player ID',
  wActivityDataCol1: 'Superior id',
  wActivityDataCol2: 'subordinate id',
  wActivityDataCol3: 'First deposit amount',
  wActivityDataCol4: 'Bonus',
  wActivityDataCol5: 'time',
  wActivityData1Col1: 'Superior id',
  wActivityData1Col2: 'subordinate id',
  wActivityData1Col3: 'Recharge',
  wActivityData1Col4: 'Level/Scale',
  wActivityData1Col5: 'Amount of share',
  wActivityData1Col6: 'Time',
  wActivityData2Col1: 'Player ID',
  wActivityData2Col2: 'Lower level recharge this week',
  wActivityData2Col3: 'Lower level recharge last week',
  wActivityData2Col4: 'Rebate last week',
  wActivityData2Col5: 'Accumulated commission',
  wActivityData2Col6: 'Accumulated collection',
  wActivityData3Col1: 'Player ID',
  wActivityData3Col2: 'Recharge this week',
  wActivityData3Col3: 'Recharge last week',
  wActivityData3Col4: 'Rebate last week',
  wActivityData3Col5: 'Accumulated commission',
  wActivityData3Col6: 'Accumulated collection',
  wActivityData4Col1: 'Player ID',
  wActivityData4Col2: 'Betting this week',
  wActivityData4Col3: 'Betting last week',
  wActivityData4Col4: 'Rebate last week',
  wActivityData4Col5: 'Accumulated commission',
  wActivityData4Col6: 'Accumulated collection',
  wActivityData5Col1: 'Player ID',
  wActivityData5Col2: 'Deposit/withdrawal this week',
  wActivityData5Col3: 'Deposit/Withdrawal last week',
  wActivityData5Col4: 'Rebate last week',
  wActivityData5Col5: 'Accumulated commission',
  wActivityData5Col6: 'Accumulated collection',
  wActivityData6Col1: 'Player ID',
  wActivityData6Col2: 'Today Betting',
  wActivityData6Col3: 'Yesterday Betting',
  wActivityData6Col4: 'Yesterday commission',
  wActivityData6Col5: 'Accumulated commission',
  wActivityData6Col6: 'Accumulated collection',
  wActivityData7Col1: 'Date',
  wActivityData7Col2: 'Contributing players',
  wActivityData7Col3: 'Superior id',
  wActivityData7Col4: 'Level',
  wActivityData7Col5: 'Contribution rebate',
  wActivityData7Col6: 'Rebate ratio (thousand points)',
  wActivityData7Col7: 'Yesterday Betting',
}
export default activity