<template>
  <el-aside :style="{width:isCollapse? '64px':'230px'}" :class="{isCollapse:isCollapse}">
    <div class="logo">
      <transition name="fade">
        <div class="" v-show="isCollapse">B</div>
      </transition>
      <transition name="fade">
        <div class="" v-show="!isCollapse" style="font-size: 16px;">{{ siteName }}</div>
      </transition>
    </div>
    <div class="menu">
      <el-menu
        class="el-menu-vertical-demo"
        background-color="#00142a"
        text-color="rgba(255, 255, 255, 0.95)"
        :collapse="isCollapse"
        :default-active="$route.path"
        :unique-opened="true">
        <template v-for="(item, index) in childMenus">
          <!-- 单一层级 -->
          <menu-row :key="index" :item="item"></menu-row>
        </template>
      </el-menu>
    </div>
  </el-aside>
</template>
<script>
import {mapGetters} from 'vuex'
import menuRow from './menu-row'
export default {
  name: 'Left',
  components: {
    menuRow
  },
  data () {
    return {
      menuList: [],
      activeMenu: '',
      siteName: ''
    }
  },
  computed: {
    ...mapGetters(['isCollapse', 'menu', 'childMenus']),
    activeChildIndex () {
      return this.$route.path
    }
  },
  mounted () {
    this.getSiteName()
  },
  methods: {
    goUrl (path) {
      this.$router.push(path)
    },
    getSiteName () {
      this.$store.dispatch('manage/GetSiteName', '').then(res => {
        // console.log(res)
        if (res.code === 0) {
          this.siteName = res.data.backendName
        }
      })
    },
    getMenuData () {
      this.$store.dispatch('user/GetMenu').then(res => {
        if (res.success) {
          this.menu = res.data
          // this.filterMenu()
        } else {
          console.log(res.message)
        }
      })
    },
    filterMenu () {
      var menuList = this.menu
      var result = []
      var obj = {}
      for (let i = 0; i < menuList.length; i++) {
        obj = menuList[i]
        var arr = []
        for (let j = 0; j < menuList[i].children.length; j++) {
          if (menuList[i].children[j].show) {
            arr.push(menuList[i].children[j])
          }
        }
        obj.children = arr
        result.push(obj)
      }
      this.menuList = result
    }
  }
}
</script>
<style lang="scss">
  .logo{
    width: 100%;
    height: 64px;
    line-height: 64px;
    background: #002140;
    text-align: center;
  }
  .el-menu{
    border-right: none!important;
  }
  .isCollapse .el-menu-item span, .isCollapse .el-submenu span{
    opacity: 0;
    transition:all 0.3s ease;
  }
</style>
