const org  = {
  url: 'endereço do cliente',
  name: 'Nome da organização',
  createTime: 'Hora criação',
  username: 'nome usuário',
  password: 'Senha login',
  confirmPassword: 'Confirmar senha',
  org1: 'Por favor, insira a nova senha',
  org2: 'A senha deve conter pelo menos 3 caracteres',
  org3: 'Por favor, digite a senha novamente',
  org4: 'As senhas inseridas duas vezes são inconsistentes!',
  org5: 'Por favor, digite sua senha login',
  org6: 'Por favor, insira a senha confirmação',
  org7: 'Por favor, insira o endereço',
  org8: 'Por favor, insira o nome da organização',
  org9: 'Por favor, insira o nome usuário'
}
export default org