const agent  = {
  aRelation1: '总充值',
  aRelation2: '总提现',
  aRelation3: '总盈亏',
  aRelation4: '受请人ID',
  aRelation5: '邀请人ID',
  aRelation6: '是否充值',
  aRelation7: '注册时间',
  aRelation8: '首充时间',
  aRelationcol1: '受请人ID',
  aRelationcol2: '邀请人ID',
  aRelationcol3: '总充值',
  aRelationcol4: '总提现',
  aRelationcol5: '贡献佣金',
  aRelationcol6: '首充金额',
  aRelationcol7: '总打码',
  aRelationcol8: '首充时间',
  aRelationcol9: '总盈亏',
  aRelationcol10: '注册IP',
  aRelationcol11: '注册时间',
  changeBind: '换绑',
  unbing: '解绑',
  ivReport1: '邀请奖励',
  ivReport2: '总流水',
  ivReport3: '玩家ID',
  ivReport4: '代理日报',
  ivReportCol1: '日期',
  ivReportCol2: '玩家ID',
  ivReportCol3: '邀请奖励',
  ivReportCol4: '个人充值',
  ivReportCol5: '个人提款',
  ivReportCol6: '个人流水',
  ivReportCol7: '新增下级',
  ivReportCol8: '新增有效人数',
  ivReportCol9: '新增首充人数',
  ivReportCol10: '新增充值',
  ivReportCol11: '新增流水',
  rewordCount1: '邀请奖励',
  rewordCount2: '全平台有效用户数',
  rewordCount3: '平均邀请奖励',
  rewordCount4: '代理日报',
  rewordCol1: '日期',
  rewordCol2: '邀请奖励',
  rewordCol3: '全平台有效用户数',
  rewordCol4: '平均邀请奖励',
  unbDetail1: '玩家ID',
  unbDetail2: '邀请人ID',
  unbDetail3: '渠道ID',
  unbDetail4: '业务员ID',
  unbDetail5: '注册时间',
  unbDetail6: '掉绑明细',
  unbDetailCol1: '玩家ID',
  unbDetailCol2: '邀请人ID',
  unbDetailCol3: '渠道',
  unbDetailCol4: '业务员',
  unbDetailCol5: '注册时间',
  unbDetailCol6: '总充值',
  unbDetailCol7: '总提现',
  blogData1: '博主ID',
  blogData2: '渠道ID',
  blogData3: '博主数据监控',
  blogDataCol1: '创建时间',
  blogDataCol2: '渠道',
  blogDataCol3: '业务员',
  blogDataCol4: '博主ID',
  blogDataCol5: '别名',
  blogDataCol6: '上分',
  blogDataCol7: '提款总额',
  blogDataCol8: '一级人数',
  blogDataCol9: '一级充值人数',
  blogDataCol10: '一级充值总额',
  blogDataCol11: '一级提现总额',
  blogDataCol12: '贡献比',
  blogDataCol13: '利润',
  agentsum1: '代理id',
  agentsum2: '代理汇总',
  agentsum3: '上分',
  agentsum4: '封禁',
  agentsumCol1: '代理Id',
  agentsumCol2: '昨日一级人数',
  agentsumCol3: '昨日一级充值',
  agentsumCol4: '团队人数',
  agentsumCol5: '团队充值人数',
  agentsumCol6: '团队充值金额',
  agentsumCol7: '提款总额',
  agentsumCol8: '一级人数',
  agentsumCol9: '一级充值人数',
  agentsumCol10: '一级充值金额',
  agentsumCol11: '二级人数',
  agentsumCol12: '二级充值人数',
  agentsumCol13: '二级充值金额',
  agentsumCol14: '三级人数',
  agentsumCol15: '三级充值人数',
  agentsumCol16: '三级充值金额',
  changeBindRecord1: '',
  changeBindRecord2: '',
  changeBindRecordCol1: '玩家id',
  changeBindRecordCol2: '换绑前渠道',
  changeBindRecordCol3: '换绑前业务员',
  changeBindRecordCol4: '换绑前上级',
  changeBindRecordCol5: '换绑后渠道',
  changeBindRecordCol6: '换绑后业务员',
  changeBindRecordCol7: '换绑后上级',
  changeBindRecordCol8: '操作员',
  changeBindRecordCol9: '时间',
}
export default agent