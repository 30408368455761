import { getArrIndex, reqFullScreen, exitFullScreen } from '@/util/tool'
import i18n from '@/i18n'
const common = {
  namespaced: true,
  state: {
    isCollapse: false,
    tagList: JSON.parse(sessionStorage.getItem('tagList')) || [],
    tag: JSON.parse(sessionStorage.getItem('tag')) || {},
    isFullScreen: false,
    // 页面缓存配置
    keepAlive: [],
    childMenus: [],
    activeMenu: '',
    message: ''
  },
  getters: {

  },
  mutations: {
    SET_MESSAGE: (state, params) => {
      state.message = params
    },
    SET_COLLAPSE (state, params) {
      state.isCollapse = !state.isCollapse
    },
    ADD_TAG (state, params) {
      state.tag = params
      sessionStorage.setItem('tag', JSON.stringify(params))
      // console.log(state.tagList)
      state.tagList.forEach((item, index, array) => {
        if (item.value === params.value) {
          state.tagList[index].query = params.query
        }
      })
      var bool = state.tagList.some((item, index, array) => {
        return item.value == params.value
      })
      if (bool) {
        return false
      } else {
        state.tagList.push(params)
        sessionStorage.setItem('tagList', JSON.stringify(state.tagList))
      }
    },
    CLOSE_TAG (state, params) {
      // var i = getArrIndex(state.tagList, params.value)
      // state.tagList.splice(i, 1)
      // sessionStorage.setItem('tagList', JSON.stringify(state.tagList))
      var i = getArrIndex(state.tagList, params.value)
      if (i !== undefined) {
        // console.log(123)
        state.tagList.splice(i, 1)
        sessionStorage.setItem('tagList', JSON.stringify(state.tagList))
      }
    },
    CLEAR_TAG (state, params) {
      let label = '首页'
      if(params === 'en'){
        label = 'Home'
      }
      var home = {
        label: label,
        query: {},
        value: '/home/index'
      }
      state.tagList = [home]
      sessionStorage.setItem('tagList', JSON.stringify(state.tagList))
      state.tag = home
      sessionStorage.setItem('tag', JSON.stringify(state.tag))
    },
    CLOSE_OTHER_TAG (state, params) {
      if(params[0].label != i18n.t('menu.index')){
        var home = {
          label: i18n.t('menu.index'),
          query: {},
          value: '/home/index'
        }
        params.unshift(home)
        state.tagList = params
        sessionStorage.setItem('tagList', JSON.stringify(state.tagList))
        state.tag = state.tagList[1]
        sessionStorage.setItem('tag', JSON.stringify(state.tag))
      }
    },
    KEEP_ALIVE (state, params) {
      state.keepAlive = params
    },
    SET_FULL_SCREEN (state, params) {
      state.isFullScreen = !state.isFullScreen
      if (state.isFullScreen) {
        reqFullScreen()
        state.isCollapse = true
      } else {
        exitFullScreen()
        state.isCollapse = false
      }
    },
    SET_CHILD_MENU (state, params) {
      state.childMenus = params
    },
    SET_ACTIVE_MENU (state, params) {
      state.activeMenu = params
    }
  },
  actions: {

  }
}
export default common
