const channel  = {
  smReportBusi1: '渠道ID',
  smReportBusi2: '业务员ID',
  smReportBusi3: '月份',
  smReportBusi4: '业务员对账表',
  smReportBusiCol1: '所属渠道ID',
  smReportBusiCol2: '所属渠道名称',
  smReportBusiCol3: '业务员id',
  smReportBusiCol4: '业务员名称',
  smReportBusiCol5: '总充值',
  smReportBusiCol6: '充值手续费',
  smReportBusiCol7: '提现数量',
  smReportBusiCol8: '提现手续费',
  quoteManage1: '额度管理',
  quoteManageCol1: '渠道ID',
  quoteManageCol2: '渠道名称',
  quoteManageCol3: '玩家上分/已用额度',
  quoteManageCol4: '测试号数量/已用额度',
  quoteManageCol5: '渠道出款限额',
  channelList1: '渠道ID',
  channelList2: '渠道名称',
  channelList3: 'PGGAME配置',
  channelList4: '复制链接成功',
  channelList5: '确定要谷歌解绑吗?',
  channelList6: '提示',
  channelList7: '确定',
  channelList8: '取消',
  channelListCol1: '渠道ID',
  channelListCol2: '渠道名称',
  channelListCol3: '渠道链接',
  channelListCol4: '充值手续费比例',
  channelListCol5: '提现手续费比例',
  channelListCol6: 'API费用',
  channelListCol7: '关联渠道',
  channelListCol8: '出款权限',
  channelListAction1: '复制链接',
  channelListAction2: '编辑',
  channelListAction3: 'API费用编辑',
  channelListAction4: '谷歌解绑',
  channelListEdit1: '渠道名称',
  channelListEdit2: '渠道账号',
  channelListEdit3: '登录密码',
  channelListEdit4: '充值手续费比例',
  channelListEdit5: '提现手续费比例',
  channelListEdit6: 'API费用',
  channelListEdit7: '关联渠道',
  channelListEdit8: '请输入渠道名称',
  channelListEdit9: '长度在 1 到 100 个字符',
  channelListEdit10: '请输入登录密码',
  channelListEdit11: '请输入充值手续费比例',
  channelListEdit12: '请输入提现手续费比例',
  channelListEdit13: '请选择关联渠道',
  quoteManageEdit1: '玩家上分额度',
  quoteManageEdit2: '测试号上分额度',
  quoteManageEdit3: '测试号数量额度',
  quoteManageEdit4: '渠道出款限额',
}
export default channel