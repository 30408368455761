const permission  = {
  sysAccount1: 'Account',
  sysAccount2: 'Role',
  sysAccount3: 'User List',
  sysAccountCol1: 'Account',
  sysAccountCol2: 'Role',
  sysAccountCol3: 'Creation Time',
  sysAccountCol4: 'Last Login Time',
  sysAccountCol5: 'Last Login IP',
  sysAccountCol6: 'Creator',
  sysAccountCol7: 'Last Editor',
  sysAccountCol8: 'Status',
  sysAccountCol9: 'Important Information',
  sysAccountCol10: 'Game Configuration Menu',
  whitelist1: 'Backend System',
  whitelist2: 'IP Address',
  whitelist3: 'Added User',
  whitelist4: 'Operation Time',
  whitelistCol1: 'IP Address',
  whitelistCol2: 'Remarks',
  whitelistCol3: 'Operation time',
  whitelistCol4: 'Added by',
  whitelistCol5: 'Region',
  bkLogs1: 'Business number',
  bkLogs2: 'Business description',
  bkLogs3: 'Creator ID',
  bkLogs4: 'Log level',
  bkLogsCol1: 'Business number',
  bkLogsCol2: 'Business description',
  bkLogsCol3: 'Business name',
  bkLogsCol4: 'Creator',
  bkLogsCol5: 'Log level',
  bkLogsCol6: 'Log time',
  roleManage1: 'Role name',
  roleManage2: 'Superior role',
  roleManage3: 'Role permissions',
  roleManage4: 'Role comments',
  roleManage5: 'Role status',
  roleManage6: 'Cancel',
  roleManage7: 'Confirm',
  roleManage8: 'Unconfigured',
  roleManage9: 'Configured',
  roleManage10: 'Configure',
  roleManage11: 'Menu permissions',
  roleManage12: 'Unbind',
  roleManage13: 'Are you sure you want to unbind',
  systemAccountEdit1: 'Account',
  systemAccountEdit2: 'Role',
  systemAccountEdit3: 'Login password',
  systemAccountEdit4: 'Password verification',
  systemAccountEdit5: 'Role status',
  systemAccountEdit6: 'Google verification code binding',
  systemAccountEdit7: 'Please enter a new password',
  systemAccountEdit8: 'The password must contain at least 3 characters',
  systemAccountEdit9: 'Please enter the password again',
  systemAccountEdit10: 'The two passwords are inconsistent!',
  systemAccountEdit11: 'Please enter the login password',
  systemAccountEdit12: 'Please enter the verification password',
  systemAccountEdit13: 'Please enter the account number',
  systemAccountEdit14: 'The length is between 4 and 50 characters',
  systemAccountEdit15: 'Please select a role',
  whitelistEdit1: 'IP address',
  whitelistEdit2: 'Remarks',
  whitelistEdit3: 'Please enter the IP address'
}
export default permission