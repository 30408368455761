const vip  = {
  vipList1: 'Player ID',
  vipList2: 'Username',
  vipList3: 'Mobile number',
  vipList4: 'Channel',
  vipList5: 'Salesman',
  vipList6: 'Minimum recharge',
  vipList7: 'Maximum recharge',
  vipList8: 'Minimum withdrawal',
  vipList9: 'Maximum withdrawal',
  vipList10: 'Registered IP',
  vipList11: 'All players',
  vipList12: 'Player notes',
  vipList13: 'Add code',
  vipList14: 'More',
  vipList15: 'Clear code',
  vipList16: 'Enter PG',
  vipList17: 'Change password',
  vipList18: 'Change withdrawal password',
  vipList19: 'Disable account',
  vipList20: 'Are you sure you want to clear the code?',
  vipList21: 'Prompt',
  vipList22: 'Confirm',
  vipList23: 'Cancel',
  vipList24: 'Are you sure you want to disable the account?',
  vipListD1: 'Player ID',
  vipListD2: 'Code amount',
  vipListD3: 'Add code',
  vipListD4: 'Player account',
  vipListD5: 'Player notes',
  vipListD6: 'Password',
  vipListD7: 'Change password',
  vipListD8: 'Withdrawal password',
  vipListD9: 'Change withdrawal password',
  vipListD10: 'Increase code',
  vipListD11: 'Reduce code',
  vipListcol1: 'Player ID',
  vipListcol2: 'Attribution channel',
  vipListcol3: 'Attribution salesperson',
  vipListcol4: 'Player account',
  vipListcol5: 'Mobile phone number',
  vipListcol6: 'Total deposit',
  vipListcol7: 'Total withdrawal',
  vipListcol8: 'Account balance',
  vipListcol9: 'Login device',
  vipListcol10: 'Registration time',
  vipBet1: 'Number of games',
  vipBet2: 'Total bets',
  vipBet3: 'Total winnings',
  vipBet4: 'Total taxes',
  vipBet5: 'Total wins and losses',
  vipBet6: 'Player ID',
  vipBet7: 'Room',
  vipBet8: 'Attribution channel ID',
  vipBet9: 'Attribution salesperson ID',
  vipBet10: 'Game log',
  vipBetcol1: 'Date',
  vipBetcol2: 'Room ID',
  vipBetcol3: 'Room name',
  vipBetcol4: 'Flow',
  vipBetcol5: 'System profit and loss',
  vipBetcol6: 'Game return rate',
  vipBetcol7: 'Tax',
  vipBetcol8: 'Number of games',
  acchange1: 'Player ID',
  acchange2: 'Channel ID',
  acchange3: 'Salesman ID',
  acchange4: 'Coin type',
  acchange5: 'Date',
  acchange6: 'Coin log',
  acchange7: 'Account change type',
  acchangecol1: 'Player ID',
  acchangecol2: 'Channel ID',
  acchangecol3: 'Room ID',
  acchangecol4: 'Initial balance',
  acchangecol5: 'Type',
  acchangecol6: 'Amount',
  acchangecol7: 'Current Balance',
  acchangecol8: 'Tax',
  acchangecol9: 'Time',
  acchangecol10: 'Configuration name',
  acchangecol11: 'Configuration English name',
  flowList1: 'Code details',
  flowListcol1: 'Player ID',
  flowListcol2: 'Code amount',
  flowListcol3: 'Current code amount',
  flowListcol4: 'Required code amount',
  flowListcol5: 'Completion degree',
  flowListcol6: 'Status',
  flowListcol7: 'Type',
  flowListcol8: 'Code increase time',
  flowListcol9: 'Code completion time',
  verc1: 'Member mobile number',
  verc2: 'Status',
  verc3: 'Issuance time',
  verc4: 'Mobile verification code',
  verc5: 'Email verification code',
  vercCol1: 'Mobile number',
  vercCol2: 'Mobile verification code',
  vercCol3: 'Issuance time',
  vercCol4: 'Success time',
  vercCol5: 'IP',
  vercCol6: 'Status',
  vipListDetailEdit1: 'Method',
  vipListDetailEdit2: 'Default',
  vipListDetailEdit3: 'Close',
  vipListDetailEdit4: 'Open',
  vipListDetailEdit5: 'Set individual',
  vipListDetailEdit6: 'Set team',
  vipListDetailEdit7: 'Cancel',
  vipListDetailEdit8: 'Add',
  vipListDetailEdit9: 'Fake pg control',
  vipListEdit1: 'Number of invitations',
  vipListEdit2: 'Binding ratio (%)',
  vipListEdit3: 'Cumulative capped number of people',
  vipListEdit4: 'Number of invitations',
  vipListEdit5: 'Time',
  vipListEdit6: 'Remarks',
  vipListEdit7: 'Operator',
  vipListEdit8: 'Operation Record',
  vipListEdit9: 'Level',
  vipListEdit10: 'Ratio',
  vipListEdit11: 'Recharge Type',
  vipListEdit12: 'Code',
  vipListEdit13: 'Recharge Share',
  vipListDetail1: 'Basic Information',
  vipListDetail2: 'Account Status',
  vipListDetail3: 'Player Account',
  vipListDetail4: 'Player ID',
  vipListDetail5: 'Mobile Number',
  vipListDetail6: 'Remarks',
  vipListDetail7: 'Operation Record',
  vipListDetail8: 'Account Status',
  vipListDetail9: 'Superior ID',
  vipListDetail10: 'Affiliated Salesperson',
  vipListDetail11: 'Affiliated Channel',
  vipListDetail12: 'Account Information',
  vipListDetail13: 'Balance (withdrawable/frozen)',
  vipListDetail14: 'Total deposit amount',
  vipListDetail15: 'Total withdrawal amount',
  vipListDetail16: 'Number of deposits',
  vipListDetail17: 'Number of withdrawals',
  vipListDetail18: 'Total bets',
  vipListDetail19: 'Total profit and loss',
  vipListDetail20: 'Historical commissions',
  vipListDetail21: 'Backstage points',
  vipListDetail22: 'Completed coding amount',
  vipListDetail23: 'Uncompleted coding amount',
  vipListDetail24: 'Total coding',
  vipListDetail25: 'Registration login information',
  vipListDetail26: 'Registration IP',
  vipListDetail27: 'Registration time',
  vipListDetail28: 'Last login time',
  vipListDetail29: 'Last login IP',
  vipListDetail30: 'Login device',
  vipListDetail31: 'Control switch',
  vipListDetail32: 'Game switch',
  vipListDetail33: 'Withdrawal switch',
  vipListDetail34: 'Tax switch',
  vipListDetail35: 'Automatic withdrawal switch',
  vipListDetail36: 'Winning bonus switch',
  vipListDetail37: 'Is it a blogger',
  vipListDetail38: 'Commission switch',
  vipListDetail39: 'Fake PG control',
  vipListDetail40: 'Unbinding control',
  vipListDetail41: 'Recharge share',
  vipListDetail42: 'Recharge record',
  vipListDetail43: 'Withdrawal record',
  vipListDetail44: 'Subordinate summary',
  vipListDetail45: 'Game log',
  vipListDetail46: 'Coin log',
  vipListDetail47: 'Same IP query',
  vipListDetail48: 'Login log',
  vipListDetail49: 'Promotion record',
  vipListDetail50: 'Please select a date',
  vipListDetailCol1: 'Order status',
  vipListDetailCol2: 'Order number',
  vipListDetailCol3: 'Order time',
  vipListDetailCol4: 'Payment time',
  vipListDetailCol5: 'Recharge amount',
  vipListDetailCol6: 'Amount received',
  vipListDetailCol7: 'Channel name',
  vipListDetail1Col1: 'Order number',
  vipListDetail1Col2: 'Order status',
  vipListDetail1Col3: 'Withdrawal amount',
  vipListDetail1Col4: 'Amount received',
  vipListDetail1Col5: 'Handling fee',
  vipListDetail1Col6: 'Operator',
  vipListDetail1Col7: 'Application time',
  vipListDetail1Col8: 'Review time',
  vipListDetail3Col1: 'Date',
  vipListDetail3Col2: 'Room ID',
  vipListDetail3Col3: 'Room name',
  vipListDetail3Col4: 'Flow',
  vipListDetail3Col5: 'Profit and loss',
  vipListDetail3Col6: 'Game return rate',
  vipListDetail3Col7: 'Tax',
  vipListDetail3Col8: 'Number of games',
  vipListDetail5Col1: 'Player ID',
  vipListDetail5Col2: 'Player account',
  vipListDetail5Col3: 'Registration IP',
  vipListDetail5Col4: 'Registration time',
  vipListDetail5Col5: 'Login IP',
  vipListDetail5Col6: 'Login time',
  vipListDetail6Col1: 'Login device',
  vipListDetail6Col2: 'Login IP',
  vipListDetail6Col3: 'Login time',
  vipListDetail7Col1: 'Invitee ID',
  vipListDetail7Col2: 'Inviter ID',
  vipListDetail7Col3: 'Total recharge',
  vipListDetail7Col4: 'Total withdrawal',
  vipListDetail7Col5: 'First recharge amount',
  vipListDetail7Col6: 'First recharge time',
  vipListDetail7Col7: 'Total profit and loss',
  vipListDetail7Col8: 'Registration IP',
  vipListDetail7Col9: 'Registration time'
}
export default vip