const spend  = {
  spend1: 'canal de pagamento',
  spend2: 'Aberto',
  spend3: 'Fechar',
  spendCol1: 'nome do canal',
  spendCol2: 'Código do canal (pay_code)',
  spendCol3: 'Valor mínimo',
  spendCol4: 'Valor máximo',
  spendCol5: 'quantia rápida',
  spendCol6: 'Nome de exibição do front-end',
  spendCol7: 'status',
  spendCol8: 'Taxa de câmbio',
  pr1: 'ID do jogador',
  pr2: 'Número do pedido',
  pr3: 'Valor do pedido',
  pr4: 'Tempo de aplicação',
  pr5: 'Você é um blogueiro',
  pr6: 'Aprovação com um clique',
  pr7: 'Rejeição de revisão com um clique',
  pr8: 'Número do pedido',
  pr9: 'Tempo de aplicação',
  pr10: 'ID do canal',
  pr11: 'nome do canal',
  pr12: 'nome do vendedor',
  pr13: 'Valor da retirada',
  pr14: 'taxa de manuseio',
  pr15: 'Pagamento',
  pr16: 'Auditoria',
  pr17: 'Rejeitar',
  pr18: 'Confiscado',
  pr19: 'Pendente',
  pr20: 'todos',
  pr21: 'Sim',
  pr22: 'Não',
  prCon1: 'Número do pedido',
  prCon2: 'ID do jogador',
  prCon3: 'Atribuição',
  prCon4: 'Equilíbrio',
  prCon5: 'Retirar',
  prCon6: 'Observações',
  prCon7: 'Recarga total',
  prCon8: 'Total de retiradas',
  prCon9: 'Pontuação mais alta',
  prCon10: 'Status do pedido',
  prCon11: 'Detalhes da conta',
  wdmanage1: 'taxa de manuseio',
  wdmanage2: 'Pedido total',
  wdmanage3: 'Ordem de retirada',
  wdmanage4: 'Total do pedido',
  wdmanage5: 'Valor total retirado',
  wdmanage6: 'ID do jogador',
  wdmanage7: 'Número do pedido',
  wdmanage8: 'Status do pedido',
  wdmanage9: 'Operador',
  wdmanage10: 'Valor do pedido',
  wdmanage11: 'Tempo de aplicação',
  wdmanage12: 'Se deseja recarregar',
  wdmanage13: 'Retirada de conta',
  wdmanage14: 'Registro de retirada',
  wdmanageCol1: 'Número do pedido',
  wdmanageCol2: 'ID do jogador',
  wdmanageCol3: 'Valor da retirada',
  wdmanageCol4: 'taxa de manuseio',
  wdmanageCol5: 'Valor recebido',
  wdmanageCol6: 'Status do pedido',
  wdmanageCol7: 'Detalhes da conta',
  wdmanageCol8: 'Operador',
  wdmanageCol9: 'Tempo de aplicação',
  wdmanageCol10: 'Tempo de auditoria',
  bank1: 'ID do jogador',
  bank2: 'Consulta do número do cartão',
  bank3: 'Digite consulta',
  bank4: 'Lista de cartões bancários',
  bank5: 'Tipo de cartão bancário',
  bankCol1: 'Código do banco',
  bankCol2: 'Nome do banco',
  bankCol3: 'ID do usuário',
  bankCol4: 'nome de usuário',
  bankCol5: 'número do cartão',
  bankCol6: 'tipo',
  bankCol7: 'CPF do banco',
  bankCol8: 'nome de usuário',
  spendD1: 'Por favor, insira o número do cartão',
  spendD2: 'Por favor, selecione o tipo',
  spendD3: 'Código do banco',
  spendD4: 'Nome do banco',
  spendD5: 'Por favor, insira o código do banco',
  spendD6: 'Por favor, insira o nome do banco',
}
export default spend