const service  = {
  upDownRecord1: 'ID do jogador',
  upDownRecord2: 'tipo',
  upDownRecord3: 'Tempo de aplicação',
  upDownRecord4: 'status',
  upDownRecord5: 'ID do vendedor atribuível',
  upDownRecord6: 'Revisão com um clique aprovada',
  upDownRecord7: 'Auditoria',
  upDownRecordCol1: 'ID do jogador',
  upDownRecordCol2: 'Valor da aplicação',
  upDownRecordCol3: 'Codificação múltipla',
  upDownRecordCol4: 'tipo',
  upDownRecordCol5: 'status',
  upDownRecordCol6: 'Tempo de aplicação',
  upDownRecordCol7: 'Notas de aplicação',
  upDownRecordCol8: 'Notas de auditoria',
  upDownRecordCol9: 'Revisor',
  upDownRecordCol10: 'Tempo de auditoria',
  udrcCol1: 'ID do jogador',
  udrcCol2: 'Canal de atribuição',
  udrcCol3: 'Vendedor atribuível',
  udrcCol4: 'ID do vendedor atribuível',
  udrcCol5: 'Valor da aplicação',
  udrcCol6: 'Codificação múltipla',
  udrcCol7: 'tipo',
  udrcCol8: 'status',
  udrcCol9: 'Tempo de aplicação',
  udrcCol10: 'Observações sobre o aplicativo',
  udrcCol11: 'Notas de auditoria',
  udrcCol12: 'Candidato',
  udrcCol13: 'Revisor',
  udrcCol14: 'Tempo de auditoria',
  ubControl1: 'Desvincular lista de controle',
  ubControlCol1: 'id do jogador',
  ubControlCol2: 'Canal de atribuição',
  ubControlCol3: 'Pertencente ao vendedor',
  ubControlCol4: 'Conta do jogador',
  pushMsg1: 'tipo',
  pushMsg2: 'Rolar mensagem',
  pushMsgCol1: 'ID',
  pushMsgCol2: 'tipo',
  pushMsgCol3: 'Conteúdo',
  pushMsgCol4: 'Hora da última operação',
  serviceConfig1: 'Configuração do atendimento ao cliente',
  serviceConfigCol1: 'ID',
  serviceConfigCol2: 'Avatar',
  serviceConfigCol3: 'Nome do atendimento ao cliente',
  serviceConfigCol4: 'Título',
  serviceConfigCol5: 'Tipo de atendimento ao cliente',
  serviceConfigCol6: 'Método aberto',
  serviceConfigCol7: 'Link de atendimento ao cliente',
  serviceConfigCol8: 'status',
  linkConfig1: 'Configuração oficial do canal',
  linkConfigCol1: 'Nome do canal',
  linkConfigCol2: 'Link do canal',
  content1: 'Hora de criação',
  content2: 'status',
  content3: 'Operador',
  content4: 'Anúncio do Sistema',
  content5: 'Tem certeza que deseja desativar',
  contentCol1: 'Título',
  contentCol2: 'conteúdo',
  contentCol3: 'Hora de criação',
  contentCol4: 'Operador',
  serviceD1: 'Título',
  serviceD2: 'Conteúdo',
  serviceD3: 'Se ativar',
  serviceD4: 'Por favor, insira um título',
  serviceD5: 'Por favor, insira o conteúdo',
  serviceD6: 'Nome do canal',
  serviceD7: 'Link do canal',
  serviceD8: 'Por favor, insira o nome do canal',
  serviceD9: 'Por favor, insira o link do canal',
  serviceD10: 'tipo',
  serviceD11: 'Número de celular',
  serviceD12: 'quantia',
  serviceD13: 'Conteúdo',
  serviceD14: 'Por favor, selecione o tipo',
  serviceD15: 'Por favor, insira o número do seu celular',
  serviceD16: 'Por favor, insira o conteúdo',
  serviceD17: 'Por favor, insira o valor',
  serviceConfigEdit1: 'Nome do atendimento ao cliente',
  serviceConfigEdit2: 'Título',
  serviceConfigEdit3: 'Link de atendimento ao cliente',
  serviceConfigEdit4: 'Tipo de atendimento ao cliente',
  serviceConfigEdit5: 'Método aberto',
  serviceConfigEdit6: 'Avatar',
  serviceConfigEdit7: 'Suporta .webp, tamanho não excede 1,5M',
  serviceConfigEdit8: 'Por favor, insira o nome do atendimento ao cliente',
  serviceConfigEdit9: 'Por favor, insira um título',
  serviceConfigEdit10: 'Por favor, insira o link de atendimento ao cliente',
  serviceConfigEdit11: 'Selecione o tipo de atendimento ao cliente',
  serviceConfigEdit12: 'Selecione o método de abertura',
  testManage1: 'Gerar número de teste',
  testManageCol1: 'Conta',
  testManageCol2: 'senha',
  testManageCol3: 'Saldo',
  testManageCol4: 'Tempo de registro',
  unbindControlEdit1: 'id do jogador',
  unbindControlEdit2: 'Por favor, insira o ID do jogador',
  upDownAuditEdit1: 'Ordem de auditoria',
  upDownAuditEdit2: 'Conta do jogador',
  upDownAuditEdit3: 'Valor da aplicação',
  upDownAuditEdit4: 'Candidato',
  upDownAuditEdit5: 'Observações sobre o aplicativo',
  upDownAuditEdit6: 'Observações',
  upDownAuditEdit7: 'Aprovado',
  upDownAuditEdit8: 'Rejeitar',
  upDownAuditEdit9: 'Por favor, insira comentários',
  upDownAuditEdit10: 'Auditoria ascendente e descendente',
  upDownRecordEdit1: 'id do jogador',
  upDownRecordEdit2: 'tipo',
  upDownRecordEdit3: 'Valor',
  upDownRecordEdit4: 'Codificação múltipla',
  upDownRecordEdit5: 'Motivo da classificação para cima/para baixo',
  upDownRecordEdit6: 'Por favor, insira o ID do jogador',
  upDownRecordEdit7: 'Por favor, selecione o tipo',
  upDownRecordEdit8: 'Por favor, insira o valor',
  upDownRecordEdit9: 'Por favor, insira o código múltiplo'
}
export default service