const home  = {
  home1: '注册人数',
  home2: '充值人数',
  home3: '提现人数',
  home4: '提冲人数比',
  home5: '总充值',
  home6: '充值营收',
  home7: '上分金额',
  home8: '剩余金币',
  home9: '总提款',
  home10: '玩家提款',
  home11: '未充提款',
  home12: '提充金额比',
  home13: '总流水',
  home14: '流水盈亏',
  home15: '总税收',
  home16: '游戏局数',
  home17: '提充比',
  home18: '首页',
  col1: '日期',
  col2: '充值营收',
  col3: '系统盈亏',
  col4: '当日流水',
  col5: 'RTP回报率',
  col6: '注册人数',
  col7: '活跃人数',
  col8: '首充人数',
  col9: '充值人数',
  col10: '提现人数',
  col11: '充值金额',
  col12: '上分金额',
  col13: '提现金额',
  col14: '未充金额',
  col15: '玩家提冲比',
  col16: '游戏局数'
}
export default home