const org  = {
  url: 'Client Url',
  name: 'Organization',
  createTime: 'Create time',
  username: 'User Name',
  password: 'Password',
  confirmPassword: 'Confirm Password',
  org1: 'Please enter new password',
  org2: 'Password must contain at least 3 characters',
  org3: 'Please enter password again',
  org4: 'The passwords entered twice are inconsistent!',
  org5: 'Please enter your login password',
  org6: 'Please enter the confirmation password',
  org7: 'Please enter address',
  org8: 'Please enter the organization name',
  org9: 'Please enter username'
}
export default org