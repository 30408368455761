const site  = {
  acConfig1: 'Activity Configuration',
  acConfigCol1: 'Activity Name',
  acConfigCol2: 'Switch',
  sysConfig1: 'System Configuration',
  sysConfigCol1: 'Function Description',
  vipConfig1: 'Vip Configuration',
  vipConfigCol1: 'Vip Level',
  vipConfigCol2: 'Upgrade Flow',
  vipConfigCol3: 'Upgrade Reward',
  vipConfigCol4: 'Weekly Reward',
  vipConfigCol5: 'Monthly Reward',
  pgConfig1: 'Real PG Game ID',
  pgConfig2: 'PGGAME Configuration',
  pgConfig3: 'Fake PG Game ID',
  pgConfig4: 'Fake PP Game ID',
  pgConfigCol1: 'Game Name',
  pgConfigCol2: 'Switch Status',
  biaConfig1: 'Code volume configuration',
  biaConfigCol1: 'Configuration name',
  biaConfigCol2: 'Flow multiple',
  biaConfigCol3: 'Type',
  acConfigDetail1: 'Effective invitation conditions',
  acConfigDetail2: 'Code',
  acConfigDetail3: 'Capped reward amount',
  acConfigDetail4: 'Recharge type',
  acConfigDetail5: 'First charge',
  acConfigDetail6: 'Accumulative charge',
  acConfigDetail7: 'First charge',
  acConfigDetail8: 'Recharge',
  acConfigDetail9: 'Invitation gradient (treasure box reward)',
  acConfigDetail10: 'First charge invitation gradient',
  acConfigDetail11: 'Accumulative charge',
  acConfigDetail12: 'Daily bet',
  acConfigDetail13: 'Weekly bet',
  acConfigDetail14: 'Weekly loss',
  acConfigDetail15: 'Weekly rebate',
  acConfigDetail16: 'Weekly deposit',
  acConfigDetail17: 'Agent subordinate turnover rebate',
  acConfigDetail18: 'Subordinate recharge share',
  acConfigDetailCol1: 'Sorting',
  acConfigDetailCol2: 'Recharge type',
  acConfigDetailCol3: 'Starting amount',
  acConfigDetailCol4: 'Gift ratio (%)',
  acConfigDetailCol5: 'Capped gift',
  acConfigDetailCol6: 'Number of invitees',
  acConfigDetailCol7: 'Reward',
  acConfigDetailCol8: 'First charge amount',
  acConfigDetailCol9: 'Reward',
  acConfigDetailCol10: 'Cumulative amount',
  acConfigDetailCol11: 'Gift amount',
  acConfigDetailCol12: 'Betting',
  acConfigDetailCol13: 'Return ratio (per thousandth)',
  acConfigDetailCol14: 'Recharge',
  acConfigDetailCol15: 'Return ratio (per thousandth)',
  acConfigDetailCol16: 'Level',
  acConfigDetailCol17: 'Return ratio (per thousandth)',
  acConfigDetailCol18: 'Tier',
  acConfigDetailCol19: 'Ratio',
  activityConfigEdit1: 'Please enter the serial number',
  activityConfigEdit2: 'Please enter the amount',
  activityConfigEdit3: 'Please enter the ratio (%)',
  activityConfigEdit4: 'Please enter the capped gift',
  activityConfigEdit5: 'Please enter the number of invitees',
  activityConfigEdit6: 'Please enter the reward',
  activityConfigEdit7: 'Please enter the first charge amount',
  activityConfigEdit8: 'Please enter the bet',
  activityConfigEdit9: 'Please enter the top-up',
  buyInAmountConfigEdit1: 'Configuration name',
  buyInAmountConfigEdit2: 'Water turnover multiple',
  vipConfigEdit1: 'VIP level',
  vipConfigEdit2: 'Upgrade flow',
  vipConfigEdit3: 'Upgrade reward',
  vipConfigEdit4: 'Weekly reward',
  vipConfigEdit5: 'Monthly reward',
  vipConfigEdit6: 'Please enter VIP level',
  vipConfigEdit7: 'Please enter upgrade flow',
  vipConfigEdit8: 'Please enter upgrade reward',
  vipConfigEdit9: 'Please enter weekly reward',
  vipConfigEdit10: 'Please enter monthly reward',
  venueManageTagEdit1: 'Game type',
  venueManageTagEdit2: 'Game brand',
  venueManageTagEdit3: 'Tag name',
  venueManageTagEdit4: 'Status',
  venueManageTagEdit5: 'Please select a role',
  venueManageTagEdit6: 'Please select a game brand',
  venueManageTagEdit7: 'Please enter a tag name',
  venueManageTagEdit8: 'Length is between 1 and 100 characters',
  gameConfigEdit1: 'Game name',
  gameConfigEdit2: 'Switch status',
  gameConfigEdit3: 'Game configuration',
  gameConfigEdit4: 'Amount',
  gameConfigEdit5: 'Betting amount',
  gameConfigEdit6: 'Effective ratio',
  gameConfigEdit7: 'Profit amount',
  gameConfigEdit8: 'Additional amount (%)',
  gameConfigEdit9: 'Game name',
  gameConfigEdit10: 'Switch status',
  gameConfigEdit11: 'Three-party game list',
  gameConfigEdit12: 'Number of invited people',
  gameConfigEdit13: 'Binding ratio',
  gameConfigEdit14: 'Cumulative capped number of people',
  gameConfigEdit15: 'Profit amount',
  gameConfigEdit16: 'Tax ratio (%)',
  gameConfigEdit17: 'Function description',
  gameConfigEdit18: 'Please enter CfgType',
  gameConfigEdit19: 'Please enter CfgValue',
  venueManageEdit1: 'Game name',
  venueManageEdit2: 'Game type',
  venueManageEdit3: 'Game code',
  venueManageEdit4: 'Game English name',
  venueManageEdit5: 'Game Chinese name',
  venueManageEdit6: 'Game Brazilian name',
  venueManageEdit7: 'Game picture',
  venueManageEdit8: 'Popular',
  venueManageEdit9: 'Sort',
  venueManageEdit10: 'Latest',
  venueManageEdit11: 'Recommended',
  venueManageEdit12: 'Maintenance Status',
  venueManageEdit13: 'Normal',
  venueManageEdit14: 'Maintenance',
  venueManageEdit15: 'Yes',
  venueManageEdit16: 'No',
  venueManageEdit17: 'Support .webp, size not exceeding 1.5M',
  venueManage1: 'Game list',
  venueManage2: 'Game code',
  venueManage3: 'Game name',
  venueManage4: 'Game brand',
  venueManage5: 'Line',
  venueManage6: 'Game type',
  venueManage7: 'Attribute type',
  venueManage8: 'Status',
  venueManage9: 'Maintenance status',
  venueManage10: 'Set popular',
  venueManage11: 'Cancel popular',
  venueManage12: 'Set latest',
  venueManage13: 'Cancel latest',
  venueManage14: 'Set recommended',
  venueManage15: 'Cancel recommended',
  venueManage16: 'Sort',
  venueManage17: 'Venue List',
  venueManage18: 'All',
  venueManage19: 'Game Tag',
  venueManage20: 'Tag Name',
  venueManage21: 'Game Type',
  venueManage22: 'Status',
  venueManage23: 'Popular',
  venueManage24: 'Latest',
  venueManage25: 'Recommended',
  venueManage26: 'Select at least one',
  venueManage27: 'Disable',
  venueManage28: 'Offline',
  venueManage29: 'Normal',
  venueManage1Col1: 'Game Code',
  venueManage1Col2: 'Game English Name',
  venueManage1Col3: 'Game Chinese Name',
  venueManage1Col4: 'Game Brazilian Name',
  venueManage1Col5: 'Game brand',
  venueManage1Col6: 'Game type',
  venueManage1Col7: 'Popular',
  venueManage1Col8: 'Latest',
  venueManage1Col9: 'Recommended',
  venueManage1Col10: 'Status',
  venueManage1Col11: 'Maintenance Status',
  venueManage2Col1: 'Serial Number',
  venueManage2Col2: 'Venue ID',
  venueManage2Col3: 'Venue Name',
  venueManage2Col4: 'Game Type',
  venueManage2Col5: 'Last Update Time',
  venueManage2Col6: 'Maintenance Status',
  venueManage2Col7: 'Status',
  venueManage3Col1: 'Tag Name',
  venueManage3Col2: 'Status',
  venueManage3Col3: 'Game Type',
  venueManage3Col4: 'Game Brand',
  venueManage3Col5: 'Creation Time',
  gameConfig1: 'Fake pg control',
  gameConfig2: 'Channel',
  gameConfig3: 'Initial RTP',
  gameConfig4: 'Control amount multiple',
  gameConfig5: 'Control RTP',
  gameConfig6: 'Probability of entering fake pg',
  gameConfig7: 'One-click synchronization of official configuration',
  gameConfig8: 'Game configuration',
  gameConfig9: 'Fake pp control',
  gameConfig10: 'Probability of entering fake pp',
  gameConfig11: 'Unbinding settings',
  gameConfig12: 'White list',
  gameConfig13: 'Black list',
  gameConfig14: 'Channel',
  gameConfig15: 'Tax settings',
  gameConfig16: 'Player balance',
  gameConfig17: 'Profit balance',
  gameConfig18: 'Is the profit balance a decimal',
  gameConfig19: 'Three-party game list',
  gameConfig20: 'Tax switch',
  gameConfig21: 'Winning bonus',
  gameConfig22: 'Winning bonus switch',
  gameConfig23: 'Effective coding ratio',
  gameConfig24: 'Cumulative coding',
  gameConfig25: 'Code effective ratio switch',
  gameConfigCol1: 'Amount',
  gameConfig2Col1: 'Number of invitees',
  gameConfig2Col2: 'Binding ratio (%)',
  gameConfig2Col3: 'Cumulative capped number of people',
  gameConfig3Col1: 'Profit amount',
  gameConfig3Col2: 'Tax ratio (%)',
  gameConfig4Col1: 'Profit amount',
  gameConfig4Col2: 'Additional bet (%)',
  gameConfig5Col1: 'Betting amount',
  gameConfig5Col2: 'Effective ratio (%)',
}
export default site