// const en = {
//   login: {
//     login: 'login',
//     username: 'username',
//     password: 'password',
//     rememberMe: 'remember me'
//   }
// }
// export default en
const files = require.context('./en', false, /\.js$/)
const en = {}
files.keys().forEach((item, index) => {
  en[item.replace(/(\.\/|\.js)/g, '')] = files(item).default
})
export default en