const service  = {
  upDownRecord1: 'Player ID',
  upDownRecord2: 'Type',
  upDownRecord3: 'Application time',
  upDownRecord4: 'Status',
  upDownRecord5: 'Salesperson ID',
  upDownRecord6: 'One-click review passed',
  upDownRecord7: 'Review',
  upDownRecordCol1: 'Player ID',
  upDownRecordCol2: 'Application amount',
  upDownRecordCol3: 'Code multiple',
  upDownRecordCol4: 'Type',
  upDownRecordCol5: 'Status',
  upDownRecordCol6: 'Application time',
  upDownRecordCol7: 'Application notes',
  upDownRecordCol8: 'Review notes',
  upDownRecordCol9: 'Reviewer',
  upDownRecordCol10: 'Review time',
  udrcCol1: 'Player ID',
  udrcCol2: 'Attribution channel',
  udrcCol3: 'Attribution salesperson',
  udrcCol4: 'Attribution salesperson ID',
  udrcCol5: 'Application amount',
  udrcCol6: 'Code multiple',
  udrcCol7: 'Type',
  udrcCol8: 'Status',
  udrcCol9: 'Application time',
  udrcCol10: 'Application notes',
  udrcCol11: 'Review notes',
  udrcCol12: 'Applicant',
  udrcCol13: 'Auditor',
  udrcCol14: 'Audit time',
  ubControl1: 'Unbinding control list',
  ubControlCol1: 'Player ID',
  ubControlCol2: 'Attribution channel',
  ubControlCol3: 'Attribution salesperson',
  ubControlCol4: 'Player account',
  pushMsg1: 'Type',
  pushMsg2: 'Scrolling message',
  pushMsgCol1: 'ID',
  pushMsgCol2: 'Type',
  pushMsgCol3: 'Content',
  pushMsgCol4: 'Last operation time',
  serviceConfig1: 'Customer service configuration',
  serviceConfigCol1: 'ID',
  serviceConfigCol2: 'Avatar',
  serviceConfigCol3: 'Customer service name',
  serviceConfigCol4: 'Title',
  serviceConfigCol5: 'Customer service type',
  serviceConfigCol6: 'Opening method',
  serviceConfigCol7: 'Customer service link',
  serviceConfigCol8: 'Status',
  linkConfig1: 'Official channel configuration',
  linkConfigCol1: 'Channel name',
  linkConfigCol2: 'Channel link',
  content1: 'Creation time',
  content2: 'Status',
  content3: 'Operator',
  content4: 'System announcement',
  content5: 'Are you sure you want to deactivate',
  contentCol1: 'Title',
  contentCol2: 'Content',
  contentCol3: 'Creation time',
  contentCol4: 'Operator',
  serviceD1: 'Title',
  serviceD2: 'Content',
  serviceD3: 'Is it enabled',
  serviceD4: 'Please enter the title',
  serviceD5: 'Please enter the content',
  serviceD6: 'Channel name',
  serviceD7: 'Channel link',
  serviceD8: 'Please enter the channel name',
  serviceD9: 'Please enter the channel link',
  serviceD10: 'Type',
  serviceD11: 'Phone number',
  serviceD12: 'Amount',
  serviceD13: 'Content',
  serviceD14: 'Please select the type',
  serviceD15: 'Please enter the phone number',
  serviceD16: 'Please enter the content',
  serviceD17: 'Please enter the amount',
  serviceConfigEdit1: 'Customer service name',
  serviceConfigEdit2: 'Title',
  serviceConfigEdit3: 'Customer service link',
  serviceConfigEdit4: 'Customer service type',
  serviceConfigEdit5: 'Open method',
  serviceConfigEdit6: 'Avatar',
  serviceConfigEdit7: 'Support .webp, size does not exceed 1.5M',
  serviceConfigEdit8: 'Please enter the customer service name',
  serviceConfigEdit9: 'Please enter the title',
  serviceConfigEdit10: 'Please enter the customer service link',
  serviceConfigEdit11: 'Please select the customer service type',
  serviceConfigEdit12: 'Please select the opening method',
  testManage1: 'Generate a test number',
  testManageCol1: 'Account',
  testManageCol2: 'Password',
  testManageCol3: 'Balance',
  testManageCol4: 'Registration time',
  unbindControlEdit1: 'Player id',
  unbindControlEdit2: 'Please enter the player id',
  upDownAuditEdit1: 'Audit the order',
  upDownAuditEdit2: 'Player account',
  upDownAuditEdit3: 'Application amount',
  upDownAuditEdit4: 'Applicant',
  upDownAuditEdit5: 'Application notes',
  upDownAuditEdit6: 'Notes',
  upDownAuditEdit7: 'Passed',
  upDownAuditEdit8: 'Reject',
  upDownAuditEdit9: 'Please enter remarks',
  upDownAuditEdit10: 'Up/Down review',
  upDownRecordEdit1: 'Player ID',
  upDownRecordEdit2: 'Type',
  upDownRecordEdit3: 'Amount',
  upDownRecordEdit4: 'Code multiple',
  upDownRecordEdit5: 'Reason for up/down score',
  upDownRecordEdit6: 'Please enter player ID',
  upDownRecordEdit7: 'Please select type',
  upDownRecordEdit8: 'Please enter amount',
  upDownRecordEdit9: 'Please enter code multiple'
}
export default service